import React, { useState, useRef } from "react";

import { ReactComponent as LogoGruIcon } from "../../static/svg/logo-gru.svg";
import SignIn from "../../components/singIn";
import PasswordReset from "../../components/passwordReset";
import Registration from "../../components/registration";
import BorderContent from "../../components/common/borderContent";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    auth: {
        height: "85%",
        alignContent: "center",
        // height: "80vh"
    },
    auth_content: {
        overflow: "hidden",
    },
    auth_formBlock: {
        flexWrap: "nowrap",
        transform: "translate3d(0%, 0px, 0px)",
        transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    auth_formBlock__resetPassword: {
        transform: "translate3d(0%, 0px, 0px)"
    },
    auth_formBlock__singin: {
        transform: "translate3d(-100%, 0px, 0px)"
    },
    auth_formBlock__singup: {
        transform: "translate3d(-200%, 0px, 0px)"
    },
    auth_title1: {
        fontSize: "2.5rem",
        fontFamily: "Yeseva One, Open Sans",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.9rem",
        }
        // fontWeight: "900",
    },
    auth_title2: {
        fontSize: "1.8rem",
        fontFamily: "Yeseva One, Open Sans",
        cursor: "pointer",
        color: "#0061FF",
        marginBottom: "25px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.2rem",
        }
        // fontWeight: "900",
    },
    authTitles: {
        [theme.breakpoints.up("xs")]: {
            paddingRight: "20px"
        }
    }
}));

const title = { text1: "войти в личный кабинет", text2: "создать аккаунт", text3: "Восстановить пароль" };

export default function Auth({ noRedirect = false }) {
    const classes = useStyles();
    const [formSignIn, setFormSignIn] = useState(true);
    const [formPasswordReset, setFormPasswordReset] = useState(false);
    const handleForm = (e) => {
        if (formPasswordReset) {
            goCreateAccount()
        } else {
            setFormSignIn(prev => !prev)
            setFormPasswordReset(false)
        }
    }
    const goResetPassword = () => {
        setFormSignIn(false)
        setFormPasswordReset(true)
    }
    const goCreateAccount = () => {
        setFormSignIn(false)
        setFormPasswordReset(false)
    }

    const active = formSignIn ? "auth_formBlock__singin" : formPasswordReset ? "auth_formBlock__resetPassword" : "auth_formBlock__singup";
    const titleForm = formSignIn ? toUpperCaseFirstSymbol(title.text1) : formPasswordReset ? toUpperCaseFirstSymbol(title.text3) : toUpperCaseFirstSymbol(title.text2)
    return (
        <Grid container className={classes.auth}>
            <Grid item md={12} lg={5} className={classes.authTitles}>
                <div className={classes.auth_title1}>{titleForm}</div>
                <div onClick={handleForm} data-name={formSignIn ? "registrator" : "signIn"} className={classes.auth_title2}>или  {formSignIn ? title.text2 : formPasswordReset ? title.text2 : title.text1}</div>
            </Grid>
            <BorderContent>
                <div className={classes.auth_content}>
                    <Grid item container wrap="nowrap" xs={12} className={clsx(classes.auth_formBlock, classes[active])}>
                        <PasswordReset />
                        <SignIn goResetPassword={goResetPassword} noRedirect={noRedirect} />
                        <Registration noRedirect={noRedirect} />
                    </Grid>
                </div>
            </BorderContent>
        </Grid>
    )
}

const toUpperCaseFirstSymbol = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}