import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {moduleListData} from '../dataModuleList';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'none',
        boxShadow: '-5px -2px 12px 0 #fff, 5px 7px 17px 0 rgb(0 0 0 / 13%)',
        padding: '12px',
        background: 'white',
        borderRadius: '7px',
        fontFamily: 'Play, Open Sans ',
        position: 'absolute',
        zIndex: '111',
    },
    showModal: {
        display: ' inline-block',
    },
    titleModule: {
        cursor: 'pointer',
        fontSize: "1rem",
        fontFamily: 'Russo One, arial, sans-serif',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
    },
    title__list: {
        fontWeight: 'bold'
    },
    module__list: {
        paddingLeft: '15px'
    },
    module__item: {
        cursor: 'pointer',
        padding: '5px 0px',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}))

const DropDownList = ({onClickItem, isOpen, showModal, children}) => {
    const classes = useStyles();
    return (
        <>
            <div onClick={showModal} className={classes.titleModule}>{children}</div>
            <div onClick={onClickItem} className={clsx(classes.modal, {[classes.showModal]: isOpen})}>
                <span className={classes.title__list}>Выберите модуль:</span>
                <ul className={classes.module__list}>
                    {moduleListData.map((el, id) => {
                        return (
                            <li key={id} className={classes.module__item}>
                                {el.name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default DropDownList;
