import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    notifications: {
        fontSize: "2rem",
        fontFamily: 'Yeseva One, arial, sans-serif',
    },
}))

export default function ProfileNotifications() {
    const classes = useStyles();
    return (
        <div className={classes.notifications}>Уведомлений нет!</div>
    )
}