import React from "react";
import InfoBlock from "../../components/infoBlock";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    tableItem__th: {
        textAlign: 'left',
        borderBottom: "1px solid grey",
        padding: "10px"
    },
    tableItem__td: {
        borderBottom: "1px solid grey",
        padding: "10px",
    }

}))

export default function Policy() {
    const classes = useStyles();
    return (
        <>
            <h1>Политика обработки персональных данных пользователей сайта http://uc-gru.ru</h1>
            <InfoBlock title="1. ОБЩИЕ ПОЛОЖЕНИЯ">
                <p>Мы уважаем вашу конфиденциальность и просим вас ознакомиться с настоящей Политикой &#40;далее - Политика&#41;, чтобы знать, какие сведения и для каких целей мы собираем, раскрываем третьим лицам, а также как вы можете их изменять или удалять.</p>
            </InfoBlock>
            <InfoBlock title="Кто оператор?">
                <p>Межрегиональная общественная организация «Союз ветеранов спецназа ГРУ» имени Героя Д.А. Шектаева&#40;далее – МООО «Союз ветеранов спецназа ГРУ», организация, мы, Оператор&#41;</p>
                <p>ИНН 6686114095</p>
                <p>Адрес: Россия, 620017, Свердловская обл,г. Екатеринбург, ул. Лобкова 2,оф. 30</p>
                <p>Уполномоченный адрес электронной почты: oovspngru@mail.ru</p>
                <p>Председатель Правления Яночкин Юрий Анатольевич</p>
            </InfoBlock>
            <InfoBlock title="Какие термины будем использовать?">
                <table>
                    <tr>
                        <th className={classes.tableItem__th}>Персональные данные</th>
                        <td className={classes.tableItem__td}>Любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу &#40;субъекту персональных данных&#41;.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Персональные данные, разрешенные субъектом персональных данных для распространения</th>
                        <td className={classes.tableItem__td}>Персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Обработка персональных данных</th>
                        <td className={classes.tableItem__td}>Любое действие или совокупность действий, совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение &#40;обновление, изменение&#41;, извлечение, использование, передачу &#40;распространение, предоставление, доступ&#41;, обезличивание, блокирование, удаление, уничтожение персональных данных.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Распространение персональных данных</th>
                        <td className={classes.tableItem__td}>Действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Предоставление персональных данных</th>
                        <td className={classes.tableItem__td}>Действия, направленные на раскрытие персональных данных определенному лицу или определяемому кругу лиц.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Блокирование персональных данных</th>
                        <td className={classes.tableItem__td}>Временное прекращение обработки персональных данных &#40;за исключением случаев, когда обработка необходима для уточнения персональных данных&#41;.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Обезличивание персональных данных</th>
                        <td className={classes.tableItem__td}>Действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Уничтожение персональных данных</th>
                        <td className={classes.tableItem__td}>Действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и &#40;или&#41; в результате которых уничтожаются материальные носители персональных данных.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Онлайн-платформа "Сайт"</th>
                        <td className={classes.tableItem__td}>Программное обеспечение Оператора, представляющее собой набор взаимосвязанных веб-сервисов и модулей, составляющих единое пространство предоставления услуг потребителям в сети Интернет.</td>
                    </tr>
                </table>
            </InfoBlock>
            <InfoBlock title="Какие данные и для каких целей мы используем?">
                <p>Оператор оказывает платные образовательные услуги и другие услуги в онлайн-формате. В таблице ниже мы расскажем о том, какие данные и для каких целей мы обрабатываем.</p>
                <table>
                    <tr>
                        <th className={classes.tableItem__th}>Цель обработки персональных данных</th>
                        <th className={classes.tableItem__th}>Объем обрабатываемых персональных данных</th>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Персональные данные</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя</li>
                                <li>адрес электронной почты</li>
                                <li>номер телефона</li>
                                <li>все данные, которые указаны в аккаунте социальной сети: ВКонтакте, Fасеbоок &#40;если регистрация осуществляется путем авторизации через социальную сеть или другой электронный сервис&#41;</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Идентификация на Сайте</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя</li>
                                <li>адрес электронной почты</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Установление и поддержание связи между Пользователем и Оператором</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя</li>
                                <li>номер телефона</li>
                                <li>адрес электронной почты</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Направление сообщений рекламного характера; таргетирование рекламных материалов</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя</li>
                                <li>номер телефона</li>
                                <li>дата рождения &#40;при указании в разделе «Персональная информация»&#41;</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Улучшение качества обслуживания Пользователей и модернизация Сайта</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>файлы соокiе</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Отображение профиля и достижений Пользователя для иных зарегистрированных Пользователей Сайта</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя</li>
                                <li>изображение</li>
                                <li>информация о достижениях Пользователя, указанных на странице «Мои достижения»</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </InfoBlock>
            <InfoBlock title="Каким образом вы даете согласие на обработку персональных данных?">
                <p>Соглашаясь с условиями Политики, вы даете Оператору свое информированное и осознанное согласие на обработку ваших персональных данных на условиях, предусмотренных Политикой и Федеральным законом от 27 июля 2006 г. N 152-ФЗ "О персональных данных" &#40;далее - Закон&#41;. При этом мы исходим из следующего:</p>
                <ul type="square">
                    <li>вы предоставили полностью достоверную информацию. Мы не проверяем ее подлинность. Все риски предоставления недостоверной или недостаточной информации лежат на вас;</li>
                    <li>вы имеете право давать соответствующее согласие. Если по какой-то причине у вас нет такого права, согласие на обработку персональных данных предоставляется вашим законным представителем;</li>
                </ul>
                <p>Правовым основанием обработки персональных данных являются:</p>
                <ul type="square">
                    <li>федеральные законы и принятые на их основе нормативные правовые акты, регулирующие отношения, связанные с деятельностью организации;</li>
                    <li>уставные документы организации;</li>
                    <li>договоры, заключаемые между организации и вами;</li>
                    <li>согласие на обработку персональных данных &#40;в случаях, прямо не предусмотренных законодательством Российской Федерации, но соответствующих полномочиям организации&#41;.</li>
                </ul>
                <p>Вы соглашаетесь с условиями Политики и даете Оператору согласие на обработку ваших персональных данных, если вы:</p>
                <ul type="square">
                    <li>регистрируйтесь на Сайте, заполняя форму для регистрации на Сайте http://uc-gru.ru/user/registration  и нажимая кнопку «Создать аккаунт». Согласие считается предоставленным в момент нажатия кнопки «Создать аккаунт» или</li>
                    <li>авторизуетесь на Сайте с использованием социальных сетей или иных электронных сервисов, доступных для целей аутентификации на Сайте. Согласие считается предоставленным в момент нажатия кнопки, отображающей выбранную для авторизации социальную сеть или электронный сервис;</li>
                    <li>вносите или изменяете информацию в разделах «Мой профиль и программы» и «Настройки» личного кабинета на Сайте. Согласие на обработку вновь внесенных или измененных персональных данных считается предоставленным в момент завершения их редактирования в соответствующих разделах Сайте. После заполнения любой из граф, указанных в разделе «Личные данные», персональные данные обновляются автоматически;</li>
                    <li>заполняете формы обратной связи, в том числе заявки на обучение, заявки на использование других сервисов Сайта. Согласие на обработку своих персональных данных, внесенных в поля формы обратной связи, считается предоставленным в момент нажатия кнопки, подтверждающей отправку заявки &#40;кнопки могут называться «Отправить», «Оставить заявку» и иным аналогичным образом&#41;</li>
                    <li>оформляете подписку на получение информационных и новостных материалов от Оператора. Согласие считается предоставленным после проставления галочки в поле «Настоящим даю согласие на обработку персональных данных» в момент нажатия кнопки «Подписаться».</li>
                </ul>
            </InfoBlock>
            <InfoBlock title="Персональные данные других людей, которые вы предоставляете нам">
                <p>Возможна ситуация, что вы совершаете регистрацию на сайте не для себя. Тогда вы, скорее всего, предоставляете нам персональные данные другого человека и совершаете регистрацию от его имени.</p>
                <p>При этом вы несете полную ответственность за то, что лицо или лица, чьи персональные данные вы предоставили, осведомлены об этом, понимают и согласны с тем, как организация использует их данные &#40;в соответствии с настоящей Политикой&#41;.</p>
            </InfoBlock>
            <InfoBlock title="Обработка персональных данных и безопасность">
                <p>Для обработки ваших персональных данных мы используем базы данных на территории Российской Федерации.</p>
                <p>Ваши персональные данные обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</p>
                <p>“Оператор обрабатывает ваши персональные данные” - это значит, что мы можем совершать с вашими персональными данными следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение &#40;обновление, изменение&#41;, извлечение, использование, передача, обезличивание, блокирование, удаление, уничтожение.</p>
                <p>Мы храним ваши персональные данные на электронных носителях. Иногда для исполнения наших обязательств перед вами мы можем извлекать их и хранить их на материальных носителях.</p>
                <p>Организация следует надлежащим процедурам безопасности и принимает необходимые меры, чтобы предотвратить несанкционированный доступ к персональным данным и их неправомерное использование.</p>
                <p>Мы применяем соответствующие корпоративные системы и процедуры с целью защиты и охраны предоставленных нам персональных данных. Только обладающие особыми правами сотрудники имеют право доступа к персональным данным в процессе выполнения своих обязанностей.</p>
                <p>Срок хранения обусловлен нашей обязанностью обеспечить вам возможность пользоваться нашими услугам, необходимостью разрешения споров, которые могут возникнуть в связи с оказанием услуг, а также иными целями, позволяющими нам вести уставную деятельность, включая обнаружение и предотвращение мошеннических или иных незаконных действий. Кроме того, срок хранения данных зависит от требований законодательством Российской Федерации об образовании и архивном деле.</p>
                <p>Мы в любом случае прекращаем обработку ваших персональных данных при наступлении любого из следующих событий:</p>
                <ul type="square">
                    <li>вы самостоятельно удалили свои персональные данные в разделе «Мой профиль и программы» и «Личные данные» &#40;страница «Настройки»&#41;</li>
                    <li>вы отозвали данное нам согласие на обработку персональных данных или направили нам требование об их уничтожении</li>
                    <li>истек срок хранения персональных данных</li>
                </ul>
                <p>Вы всегда можете самостоятельно уточнить ваши персональные данные в разделах «Мой профиль и программы» и «Личные данные» &#40;страница «Настройки»&#41;либо обратиться к нам с такой просьбой.</p>
            </InfoBlock>
            <InfoBlock title="Передача данных третьим лицам">
                <p>С организацией разными способами и в различных целях связаны третьи лица.</p>
                <p>Главная цель - обеспечить исполнение наших обязательств перед пользователями. Иногда для этого необходимо привлечение третьих лиц. Объем передаваемых данных определяется условиями договоров, например, о сетевой форме реализации образовательных программ.</p>
                <p>Но есть и другие цели передачи ваших данных &#40;фамилия, имя, отчество; номер телефона; адрес электронной почты&#41; третьим лицам:</p>
                <ul type="square">
                    <li>оптимизация рассылки сообщений информационного и рекламного характера</li>
                    <li>своевременно информировать вас о новых возможностях в сфере образования и развития</li>
                </ul>
                <p>Если в какой-то момент вы понимаете, что наши сообщения стали для вас неактуальны или неинтересны, вы легко можете отказаться от их получения, пройдя по ссылке “Отписаться от рассылки”, которая есть в каждом информационном письме от организации.</p>
                <p>Мы тщательно выбираем партнеров -третьих лиц, которым можем доверить ваши персональные данные &#40;в рамках, предусмотренных настоящей Политикой&#41;.</p>
                <p>Третьи лица-партнеры, связанные с организацией договорными обязательствами, гарантируют:</p>
                <ul type="square">
                    <li>что обработка персональных данных на их стороне осуществляется с использованием баз данных на территории Российской Федерации</li>
                    <li>конфиденциальность персональных данных при их обработке и использовании</li>
                    <li>соблюдение следующих мер по обеспечению безопасности персональных данных при их обработке: использование средств защиты информации; обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению персональных данных; ограничение доступа к персональным данным; регистрация и учет действий с персональными данными; контроль и оценка эффективности применяемых мер по обеспечению безопасности персональных данных.</li>
                </ul>
                <p>Третьи лица не имеют права на передачу и распространение ваших персональных данных.</p>
            </InfoBlock>
            <InfoBlock title="Как ваши данные могут стать доступны для всех?">
                <p>Для обработки персональных данных, разрешенных пользователем для распространения, Закон предусматривает особые условия и порядок обработки.</p>
                <p>В некоторых случаях мы распространяем ваши персональные данные путем размещения их на нашем Сайте, например, при публикации отзывов о вашем опыте обучения в организации. Для названных целей организация может обрабатывать следующий объем ваших персональных данных:</p>
                <table>
                    <tr>
                        <th className={classes.tableItem__th}>Цель распространения персональных данных</th>
                        <th className={classes.tableItem__th}>Объем распространяемых персональных данных</th>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Размещение отзывов</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя и фамилия</li>
                                <li>должность и место работы</li>
                                <li>изображение</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Размещение фото и видеоматериалов, полученных в ходе оказания услуг Оператором</th>
                        <td className={classes.tableItem__td}>
                            <ul type="square">
                                <li>имя и фамилия</li>
                                <li>изображение</li>
                                <li>данные аккаунтов в социальных сетях</li>
                            </ul>
                        </td>
                    </tr>
                </table>
                <p>Мы делаем это исключительно на основании полученного согласия на обработку персональных данных, разрешенных вами для распространения.</p>
                <p>Согласие с настоящей Политикой не включает в себя согласие на распространение персональных данных. Оно оформляется отдельно от согласия с настоящей Политикой.</p>
                <p>Перед тем, как опубликовать ваш отзыв или, или иным способом сделать ваши персональные данные доступными для неограниченного круга лиц, мы обязательно попросим вас подтвердить свое согласие на такое распространение, подробно и понятно объяснив, какие данные, для каких целей и каким способом собираемся использовать. На этом же этапе вы сможете установить запреты Оператору на передачу &#40;кроме предоставления доступа&#41; и/или на обработку или условия обработки &#40;кроме получения доступа&#41; персональных данных, разрешенных Пользователем для распространения неограниченному кругу лиц.</p>
                <p>Без вашего отдельного информированного и осознанного согласия никакие ваши персональные данные распространены не будут.</p>
            </InfoBlock>
            <InfoBlock title="Обращения Пользователей">
                <p>Организация всегда открыта для любых ваших обращений.</p>
                <p>Если у вас есть вопрос, требование или запрос относительно ваших персональных данных, например, вы хотите отозвать согласие на их обработку, или по любому иному вопросу, самый простой способ - написать на asd@uc.gru.ru </p>
                <p>Чтобы мы сразу вас узнали, письмо должно быть отправлено с адреса электронной почты, указанного вами при регистрации на Сайте или в договоре в качестве уполномоченного адреса электронной почты.</p>
                <p>Если по каким-либо причинам вам неудобно использовать электронную почту, направьте ваше письменное обращение по адресу: Россия, 620017, Свердловская обл, г. Екатеринбург, ул. Лобкова 2, оф. 30.</p>
                <p>Мы оперативно рассмотрим ваше обращение и направим ответ в любом случае не позднее 30 &#40;тридцати&#41; календарных дней с даты получения обращения.</p>
                <p>При получении от пользователя сообщения о неправомерной обработке его персональных данных, мы заблокируем такую информацию в момент получения сообщения и до выяснения всех обстоятельств обработки.</p>
                <p>Мы отпишем вас от рассылки в день получения от вас такого требования. На требование об актуализации и/или прекращении обработки персональных данных ответим не позднее 7 &#40;семи&#41; рабочих дней с даты получения обращения.</p>
                <p>В течение 2 &#40;двух&#41; рабочих дней с даты получения обращения с требованием заблокировать неточные персональные данные мы проведем проверку &#40;на период проверки данные блокируются&#41; и запросим у вас точные сведения. Изменения будут внесены в течение 7 &#40;семи&#41; рабочих дней с момента предоставления вами таких сведений.</p>
                <p>Мы прекратим обработку и уничтожим обрабатываемые персональные данные в течение 30 &#40;тридцати&#41; дней с даты получения обращения, содержащего отзыв согласия на обработку персональных данных.</p>
            </InfoBlock>
            <InfoBlock title="Изменение Политики">
                <p>Мы вправе в любой момент вносить изменения в настоящую Политику. Как правило, все эти изменения связаны с приведением Политики в соответствии с изменением Закона и направлены на еще большую защиту прав субъектов персональных данных</p>
                <p>Мы обязуемся не вносить существенных изменений, не налагать дополнительных обременений или ограничений ваших прав, установленных настоящей Политикой без уведомления.</p>
                <p>Организация уведомит вас, когда в Политике произойдут изменения, и попросим ознакомиться с новой версией. Продолжая пользоваться нашим сервисом, вы подтверждаете согласие с обновленной Политикой.</p>
            </InfoBlock>
        </>
    )
}