import React from "react";
import InfoBlock from "../../components/infoBlock";

import {makeStyles} from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    tableItem: {
        border: "1px solid grey"
    },
    tableItem__th: {
        textAlign: 'left',
        borderBottom: "1px solid grey",
        padding: "10px"
    },
    tableItem__td: {
        borderBottom: "1px solid grey",
        padding: "10px",
    }
}))

export default function ContractOffer() {
    const classes = useStyles();
    return (
        <>
            <h1>Договор безвозмездного оказания услуг</h1>
            <InfoBlock title="Преамбула">
                <p>Настоящий документ, постоянно размещенный в сети Интернет по сетевому адресу: http://uc-gru.ru/, является предложением МЕЖРЕГИОНАЛЬНОЙ ОБЩЕСТВЕННОЙ ОРГАНИЗАЦИИ СОЮЗ ВЕТЕРАНОВ СПЕЦНАЗА ГРУ" ИМЕНИ ГЕРОЯ Д.А.ШЕКТАЕВА &#40;далее - Исполнитель&#41;  заключить настоящий Договор &#40;далее - Договор&#41;  с любым заинтересованным физическим лицом &#40;далее - Заказчик&#41; .</p>
                <p>Настоящий документ является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации. Надлежащим полным и безоговорочным принятием &#40;акцептом&#41;  условий настоящего Договора в соответствии со статьей 438 Гражданского кодекса Российской Федерации является совершение Заказчиком в совокупности  следующих действий:</p>
                <ul type="square">
                    <li>нажатия кнопки «Пройти обучение» при подаче заявки / регистрации на сайте http://uc-gru.ru/ или в иных местах на указанном сайте, где размещена соответствующая кнопка;</li>
                    <li>нажатия кнопки «Я принимаю условия политики обработки персональных данных» при подаче заявки на обучение  на сайте http://uc-gru.ru/или в иных местах на указанном сайте, где размещена соответствующая кнопка.</li>
                </ul>
                <p>Датой заключения договора считается дата акцепта, при этом физическое лицо, акцептовавшее Договор становится Заказчиком. Акцепт оферты означает, что Заказчик согласен со всеми положениями настоящего Договора. Исполнитель вправе отозвать оферту в любое время без объяснения причин. В оферту могут быть внесены изменения и дополнения, которые вступают в силу со дня, следующего за днем их размещения в сети Интернет по адресу http://uc-gru.ru/</p>
            </InfoBlock>
            <InfoBlock title="1. ПРЕДМЕТ ДОГОВОРА">
                <p>1.1. Исполнитель принимает на себя обязанность предоставить и провести своими силами или с привлечением третьих лиц услуги по организации мероприятий - онлайн-курсы в рамках образовательных программ, а также по образовательным программам дополнительного профессионального образования – программам профессиональной переподготовки для взрослых в режиме и с периодичностью, а также способом, определяемыми Исполнителем.</p>
                <p>1.2. Местом оказания услуг являются образовательная платформа учебного центра Исполнителя на сайте в сети интернет, по адресу: http://uc-gru.ru/, включающая в себя совокупность программ для ЭВМ; Онлайн- Курсов; совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов &#40;аудиовизуальных произведений&#41;  и иных объектов интеллектуальной собственности, обеспечивающий доступность Онлайн-Курсов Заказчикам в сети Интернет.</p>
                <p>1.3. Образовательная платформа предназначена для предоставления Заказчикам удаленного доступа через сеть Интернет к Онлайн-Курсам с целью организации процесса самообучения Заказчика по выбранной им тематике. Сайт Исполнителя в сети Интернет по сетевому адресу http://uc-gru.ru/</p>
                <p>1.4. При заполнении/регистрации  на сайте Исполнителя анкеты/заявления Заказчик передает Исполнителю свои персональные данные. Заказчик дает согласие Исполнителю на обработку всех его персональных, указанных на сайте Исполнителя, в том числе третьими лицами, а также вышеуказанную обработку иных его персональных данных, полученных в результате их обработки, в целях: создания информационных баз данных Исполнителя; предложения продуктов и услуг Исполнителя и его партнеров; заключения и исполнения договоров, где Заказчик является стороной. Указанное согласие дано на срок пятнадцать лет и может быть отозвано по письменному заявлению Заказчика. В случае отзыва согласия обработка персональных данных Заказчика должна быть прекращена Исполнителем и/или третьими лицами.</p>
                <p>1.5. Информация о содержании, расписании обучающих Онлайн-Курсов &#40;датах и времени проведения Занятий&#41;  доступна Заказчику на странице выбранного Онлайн-Курса на Сайте Исполнителя.</p>
                <p>1.6. Исполнитель предоставляет Заказчику доступ к Онлайн-Курсу после регистрации Заказчика на Сайте http://uc-gru.ru/</p>
                <p>1.7. Услуги считаются оказанными Исполнителем с момента предоставления Заказчику доступа к образовательной Платформе и образовательным курсам на сайте Исполнителя http://uc-gru.ru/</p>
                <p>1.8. Доступ Заказчика к лекции в формате записи возможен в любое время.</p>
            </InfoBlock>
            <InfoBlock title="2. ПРАВА И ОБЯЗАННОСТИ СТОРОН">
                <p>2.1. Исполнитель обязуется:</p>
                <p>2.1.1. Предоставить Заказчику доступ к выбранной  образовательной программе посредством направления ссылки на электронную почту Заказчика, указанную им при регистрации на Сайте.</p>
                <p>2.1.2. Осуществлять информационную поддержку Заказчика по вопросам оказания Услуг.</p>
                <p>2.1.3. Организовать и провести мероприятия по обучению &#40;онлайн-курсам&#41;  по общеразвивающим программам, а также по образовательным программам дополнительного профессионального образования – программам профессиональной переподготовки для взрослых; </p>
                <p>2.1.4. Осуществлять развитие познавательных способностей Заказчика с использованием авторских методик, материалов, программного обеспечения, компьютерной техники, с участием привлеченных специалистов.</p>
                <p>2.1.5. Вести обучающие мероприятия &#40;онлайн-курс&#41;  согласно графику. При изменении графика Исполнитель не позднее чем за 1 рабочий день уведомляет Заказчика о его изменении. </p>
                <p>2.1.6. Обеспечивать Заказчика используемыми во время проведения онлайн-курсов электронными учебными материалами,  литературой и программным обеспечением.</p>
                <p>2.1.7. Разработать режим и периодичность проведения учебных мероприятий с учетом возраста Заказчиков и поместить расписание на сайте http://uc-gru.ru/ </p>
                <p>2.1.8. Уведомлять Заказчика о любых вносимых Исполнителем в одностороннем порядке изменениях в расписании путем направления электронного письма на адрес Заказчика, указанный при заполнении формы на сайте http://uc-gru.ru/, либо направлением сообщения посредством мессенджеров, либо иным способом, установленным Исполнителем. </p>
                <p>2.1.9. Выдать Заказчику по завершении Онлайн-Курсов сертификат о их прохождении. </p>
                <p>2.1.10. Сохранять конфиденциальность личных данных Заказчика, полученных в процессе регистрации на сайте.</p>
                <p>2.2. Исполнитель имеет право: </p>
                <p>2.2.1. Самостоятельно и в одностороннем порядке выбирать, разрабатывать и применять в процессе проведения обучения  материалы, литературу, методики, программное обеспечение, способствующее повышению эффективности проведения обучения  и вовлеченности в процесс Заказчика, достижению цели проведения обучения.</p>
                <p>2.2.2. В случае необходимости изменить график проведения онлайн-лекций  с уведомлением Заказчика посредством электронной почты и/или иным способом.</p>
                <p>2.2.3. Без согласования с Заказчиком привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные.</p>
                <p>2.2.4. Запрашивать у Заказчика всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.</p>
                <p>2.3. Заказчик обязан:</p>
                <p>2.3.1. Своевременно предоставлять полную и достоверную информацию, необходимую для оказания Услуг &#40;в том числе при регистрации на Сайте&#41; .</p>
                <p>2.3.2. Обеспечить конфиденциальность логина и пароля к личному кабинету на сайте. </p>
                <p>2.3.3. Соблюдать этические нормы поведения, общения в чатах  при прохождении онлайн-курсов. </p>
                <p>2.4. Заказчик вправе:</p>
                <p>2.4.1. Получать информационную поддержку по вопросам, связанным с порядком оказания Услуг и работой сайта, на протяжении всего срока действия настоящего Договора &#40;с момента его заключения&#41; .</p>
                <p>2.4.2. По завершении занятий на Онлайн-Курсе получить сертификат о прохождении Онлайн-Курса при условии выполнения требований Исполнителя по освоению Онлайн-Курса. Сертификат предоставляется Заказчику в электронном виде.</p>
                <p>2.4.3. После каждого Занятия, предполагающего выполнение практической работы, выполнить такую работу и отправить результат на проверку посредством функционала Личного кабинета.</p>
            </InfoBlock>
            <InfoBlock title="3. ОТВЕТСТВЕННОСТЬ СТОРОН">
                <p>3.1. Заказчик гарантирует, что все условия настоящего договора ему понятны, и Заказчик принимает все условия без оговорок и в полном объеме.</p>
                <p>3.2. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по настоящему договору в соответствии с действующим законодательством РФ.</p>
                <p>3.3. Стороны освобождаются от ответственности в случае возникновения форс- мажорных обстоятельств. Сторона, у которой возникли такие обстоятельства, должна в разумные сроки и доступным способом оповестить о таких обстоятельствах другую сторону.</p>
                <p>3.4. В случае возникновения споров или претензий между сторонами по настоящему Договору, стороны будут стремиться урегулировать их путем переговоров, а в случае не достижения согласия, урегулирование споров и претензий осуществляется в порядке, установленном законодательством РФ.</p>
                <p>3.5. Исполнитель не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору в результате: </p>
                <ul type="square">
                    <li>сбоев в работе программного обеспечения сайта, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении; </li>
                    <li>отсутствия &#40;невозможности установления, прекращения и пр.&#41;  Интернет-соединений; </li>
                    <li>других случаев, связанных с действием &#40;бездействием&#41;  пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования.</li>
                </ul>
            </InfoBlock>
            <InfoBlock title="4. СРОК ДЕЙСТВИЯ  ДОГОВОРА">
                <p>4.1. Договор вступает в силу с даты его акцепта Заказчиком и действует до полного исполнения Сторонами своих обязательств. </p>
                <p>4.2. Договор может быть в любое время расторгнут по инициативе Исполнителя в одностороннем порядке без указания причин. </p>
                <p>4.3. Договор может быть в любое время расторгнут в одностороннем порядке по инициативе Заказчика путем направления Исполнителю уведомления по электронной почте Исполнителя</p>
                <p>4.4. Настоящий Договор считается расторгнутым после оказания Исполнителем услуг в полном объеме согласно графику онлайн-курсов. </p>
            </InfoBlock>
            <InfoBlock title="14. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ">
                <p>Межрегиональная общественная организация «Союз ветеранов спецназа ГРУ» имени Героя Д.А.Шектаева</p>
                <p>Адрес: Россия, 620017, Свердловская обл, г. Екатеринбург, ул. Лобкова 2, оф. 30</p>
                <p>ИНН 6686114095</p>
                <p>ОГРН 1196658019645</p>
                <p>р/с 40703810316540004175</p>
                <p>УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</p>
                <p>БИК 046577674</p>
                <p>корр/с 30101810500000000674</p>
                <p>Юр. адрес: 620017, Свердловская обл., г. Екатеринбург, ул. Лобкова, 2, оф. 30</p>
                <p>E-mail: oovspngru@mail.ru</p>
                <p>Председатель Правления</p>
                <p>МООО «Союз ветеранов спецназа ГРУ» им. Героя Д.А. Шектаева</p>
                <p>Ю.А. Яночкин</p>
            </InfoBlock>
        </>
    )
}