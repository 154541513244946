import React from "react";
import Title from "./title";
import ButtonComp from "./button";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: "100px"
  },
  section__cards: {
    // overflow: "auto",

    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#99a63c",
      height: "10px",
      borderRadius: "10px"
    }
  },
  section__button: {
    paddingTop: "35px",
    margin: "0 auto"
  }
}));

export default function Section({ title, children, titleButton }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.section}>
      <Title title={title} />
      <Grid item container className={classes.section__cards}>
        {children}
      </Grid>
      {titleButton && (
        <Grid item className={classes.section__button}>
          <ButtonComp title={titleButton} endIcon={<ArrowRightAltIcon />} />
        </Grid>
      )}
    </Grid>
  );
}
