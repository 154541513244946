import React from "react";
import Menu from "./menu";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  containerPadding: {
    padding: "10px 0px 10px 0px"
  }
}));
export default function Header() {
  const classes = useStyles();
  return <Menu />;
}
