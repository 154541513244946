import React from 'react';
import {Route, Switch} from "react-router-dom";
import Profile from "./pages/profile";
import Home from "./pages/home";
import SpecialCourses from "./pages/specialCourses";
import Courses from "./pages/courses";
import News from "./pages/news";
import Contact from "./pages/contact/";
import OfficialInfo from "./pages/info";
import About from "./pages/about/";
import MobileApp from "./pages/mobileApp";
import Auth from "./pages/auth";
import TestForm from "./pages/tests";
import PrivateRoute from "./components/privateRoute";
import {AuthProvider} from './contexts/AuthContext';
import ConstructorTest from "./pages/constructorTest";
import Dashboard from "./pages/dashboard";
import Library from "./pages/library";
import {dataTests} from './dataTest';
import "./styles.css";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/tests" exact><TestForm dataTests={dataTests} /></Route>
          <Route path="/special-courses" component={SpecialCourses} />
          <Route path="/courses" component={Courses} />
          <Route path="/news" component={News} />
          <Route path="/official-info" component={OfficialInfo} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/mobile-application-download" component={MobileApp} />
          <Route path="/auth" component={Auth} />
          <Route path="/library" component={Library} />
          <PrivateRoute path="/profile" component={Profile} />
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
