export const workHome = [
    {
        nameLesson: "Военная история",
        task: [
            {name: "Военная история", src: "https://youtu.be/vlCxMxwlaUw"},
            {name: "Полководцы России. От Древней Руси до ХХ века", src: "https://zen.yandex.ru/media/id/608c9b0647167d224cde2912/polkovodcy-rossii-ot-drevnei-rusi-do-hh-veka-istoriia-608c9b3147167d224cde29a8"},

        ]
    },
    {
        nameLesson: "Виды и рода войск Вооруженных Сил Российской Федерации",
        task: [
            {name: "Виды и рода войск Вооруженных Сил Российской Федерации", src: "https://zen.yandex.ru/media/videouroki/vidy-i-roda-voisk-voorujennyh-sil-rossiiskoi-federacii-obj-10-klass-60b46d78f91b153cf466af3a"},
            {name: "Ритуалы Вооруженных Сил Российской Федерации", src: "https://zen.yandex.ru/media/videouroki/ritualy-voorujennyh-sil-rossiiskoi-federacii-obj-11-klass-60b47837f91b153cf466cf39"},
        ]
    },
    {
        nameLesson: "Боевые традиции и символы воинской чести ВС РФ",
        task: [
            {name: "Ритуалы Вооруженных Сил Российской Федерации", src: "https://zen.yandex.ru/media/videouroki/ritualy-voorujennyh-sil-rossiiskoi-federacii-obj-11-klass-60b47837f91b153cf466cf39"},
        ]
    },
    {
        nameLesson: "Военная доктрина",
        task: [
            {name: "Военная доктрина России", src: "https://www.youtube.com/watch?v=BLqmAl9g5WA"},
        ]
    },
    {
        nameLesson: "ВС в структуре государства",
        task: [
            {name: "Виды и рода войск Вооруженных Сил Российской Федерации", src: "https://zen.yandex.ru/media/videouroki/vidy-i-roda-voisk-voorujennyh-sil-rossiiskoi-federacii-obj-10-klass-60b46d78f91b153cf466af3a"},
        ]
    },
    {
        nameLesson: "Современное состояние и перспективы развития ВС РФ",
        task: [
        ]
    },
    {
        nameLesson: "Основные тенденции развития международной обстановки",
        task: [
        ]
    },
    {
        nameLesson: "Угрозы безопасности России и её военная политика",
        task: [
        ]
    },
    {
        nameLesson: "Вооружение и техника",
        task: [
        ]
    },
    {
        nameLesson: "Организация и ведение общевойскового боя",
        task: [
            {name: "Основы современного общевойскового боя", src: "https://www.youtube.com/watch?v=Zj4GYaZx4sI"},
        ]
    },
    {
        nameLesson: "Действия солдата в бою",
        task: [
            {name: "Действия солдата в бою", src: "https://www.youtube.com/watch?v=AhpqXbvWQkI"},
        ]
    },
    {
        nameLesson: "Ядерное, химическое, биологическое и зажигательное оружие вероятного противника и защита от него",
        task: [
        ]
    },
    {
        nameLesson: "Средства индивидуальной и коллективной защиты и пользование ими",
        task: [
        ]
    },
    {
        nameLesson: "Приемы и способы действия личного состава на зараженной местности",
        task: [
        ]
    },
    {
        nameLesson: "Материальная часть стрелкового оружия",
        task: [
            {name: "Материальная часть АК", src: "https://www.youtube.com/watch?v=KzYqz_ffOMk"},
        ]
    },
    {
        nameLesson: "Стрелковые тренировки",
        task: [
        ]
    },
    {
        nameLesson: "Военная связь",
        task: [
        ]
    },
    {
        nameLesson: "Военнослужащие Вооруженных Сил и взаимоотношения между ними",
        task: [
        ]
    },
    {
        nameLesson: "Воинская дисциплина, ее сущность и значение",
        task: [
        ]
    },
    {
        nameLesson: "Обязанности лиц суточного наряда",
        task: [
        ]
    },
    {
        nameLesson: "Организация и несение караульной службы",
        task: [
        ]
    },
    {
        nameLesson: "Строевые приемы и движения без оружия",
        task: [

        ]
    },
    {
        nameLesson: "Ориентирование и движение на местности без карты",
        task: [
            {name: "Военная топография", src: "https://www.youtube.com/watch?v=-XYV4o9vYnU"},
            {name: "Ориентирование на местности без карты", src: "https://www.youtube.com/watch?v=uHB5aQjmB6Y"},
        ]
    },
    {
        nameLesson: "Отечественные топографические карты, условные знаки, чтение топографических карт",
        task: [
        ]
    },
    {
        nameLesson: "Ориентирование и движение на местности по карте",
        task: [
            {name: "Ориентирование по карте и компасу", src: "https://yandex.ru/efir?stream_id=vkAk8B_Tltkg"},
        ]
    },
    {
        nameLesson: "Оказание первой помощи при ранениях и кровотечениях",
        task: [
            {name: "Военно-медицинская эстафета-2020", src: "https://youtu.be/5ep4-XQckLs"},
        ]
    },
    {
        nameLesson: "Оказание первой помощи при островозникших заболеваниях",
        task: [
        ]
    }
]