import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Yeseva One",
    fontSize: "2rem",
    marginBottom: "35px",
    textTransform: "uppercase",
    letterSpacing: "0.1rem",
    flexBasis: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px"
    }
  }
}));

export default function Title({ title, className }) {
  const classes = useStyles();
  return <div className={clsx(classes.title, className)}>{title}</div>;
}
