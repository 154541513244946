import React from "react";
import InfoBlock from "../../components/infoBlock";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    learn_program_link: {
        display: "flex",
        margin: "20px"

    },
}))

export default function LearningPrograms() {
    const classes = useStyles();
    return (
        <>
            <h1>Образовательные программы</h1>
            {/* <a href='/static/pdf/mnemotekhnika. bazovy kurs.pdf' target="_blank" className={classes.learn_program_link}>
                <GetAppIcon />
                <div>Мнемотехника. Базовый курс.</div>
            </a>


            <a href='/static/pdf/skorochteniye. bazovy kurs.pdf' target="_blank" className={classes.learn_program_link}>
                <GetAppIcon />
                <div>Скорочтение. Базовый курс</div>
            </a>


            <a href='/static/pdf/emotsionalny intellekt.pdf' target="_blank" className={classes.learn_program_link}>
                <GetAppIcon />
                <div>Эмоциональный интеллект</div>
            </a> */}


            <a href='/static/pdf/kurs_na_patriotizm.pdf' target="_blank" className={classes.learn_program_link}>
                <GetAppIcon />
                <div>Курс на патриотизм</div>
            </a>

            <a href='/static/pdf/kurs_pk_ugolovno_pravovaya_otvetstvennost__sredi_voennoslujaschih.pdf' target="_blank" className={classes.learn_program_link}>
                <GetAppIcon />
                <div>«Уголовно-правовая ответственность за преступления среди военнослужащих»</div>
            </a>

        </>
    )
}