import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import {ReactComponent as DoneIcon} from '../../static/svg/done.svg';
import {ReactComponent as LockIcon} from '../../static/svg/lock.svg';

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100%',
        //overflow: 'hidden',
        width: '100%',

    },
    main__content: {
        padding: '30px',
    },
    block__header: {
        display: 'grid',
        gridTemplateColumns: 'auto 0fr',
        gridGap: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
        },
    },
    block__number: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '1.1rem'
    },
    block__progress: {
        fontFamily: 'Yeseva One, Open Sans',
        fontSize: '0.7rem'
    },
    block__content: {
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 250px) auto',
        gridGap: '20px',
        margin: '40px 0px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
        },
    },
    progress__value: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '3rem'
    },
    block__content__title: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '1.4rem',
        fontWeight: '100'
    },
    section__header: {
        //display: 'grid',
        //gridTemplateColumns: 'auto auto',
        //justifyContent: 'space-between',
        //display: 'grid',
        //gridTemplateColumns: '1fr auto',
    },
    header__text: {
        display: 'flex',
        borderBottom: '1px solid black',
        justifyContent: 'space-between',
    },
    header__number: {
        fontSize: '0.9rem',
        fontFamily: 'Russo One, Open Sans',
        borderBottom: '1px solid #000000',
    },
    header__title: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '1rem',
        fontWeight: '100',
        margin: '10px 0px 40px 0px',
    },
    header__progress: {
        fontSize: '0.7rem',
        fontFamily: 'Yeseva One, Open Sans',
        //borderBottom: '1px solid black',
    },
    header__value: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '3rem'
    },
    main__list: {
        //margin: '10px 0px 40px 20px',
    },
    listItem: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '5px 10px',
        '&:hover': {
            background: '#e6e6e6'
        },
        '&:hover svg': {
            opacity: '1',
        }
    },
    item__icon: {
        '& svg': {
            //color: '#99a63c',
            opacity: '0.5',
            fill: 'currentColor',
        }
    }
}))

const ItemDescription = ({data, outputClass, enterClass}) => {
    const classes = useStyles();
    const {id, block, progress, sections} = data;
    return (
        <div className={clsx(classes.main, outputClass, enterClass)}>
            <div className={classes.block__header}>
                <div className={classes.block__number__progress}>
                    <p className={classes.block__number}>Блок {id + 1}</p>
                    <h2 className={classes.block__content__title}>
                        {block.title}
                    </h2>

                </div>
                <div>
                    <p className={classes.block__progress}>Выполнено:</p>
                    <p className={classes.progress__value}>{progress}%</p>
                </div>
            </div>
            {
                sections.map((el) => {
                    const {id, title, lessons} = el;
                    return (
                        <div className={classes.block__content}>
                            <div className={classes.section__header}>
                                <div className={classes.header__number}>Раздел {id + 1}</div>
                                <h3 className={classes.header__title}>{title}</h3>
                                <div className={classes.header__progress}>Выполнено:</div>
                                <div className={classes.header__value}>3/4</div>
                            </div>

                            <div className={classes.section__main}>
                                <div className={classes.header__number}>Темы:</div>
                                <ol className={classes.main__list}>
                                    {
                                        lessons.map((el) => {
                                            const {title, description} = el;
                                            return (
                                                <li className={classes.listItem}><div>{title}</div>
                                                    <span className={classes.item__icon}><DoneIcon /></span>
                                                </li>
                                            )
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    )
                })
            }
        </div >
    )
}

export default ItemDescription;
