import React from "react";

import {ReactComponent as LogoVkIcon} from "../static/svg/logoVk.svg";
import {ReactComponent as LogoInstagramIcon} from "../static/svg/logoInstagram.svg";
import {config} from "../config";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";

const useStyles = makeStyles((theme) => ({
  block__item: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  },
  aside__block__item_padding: {
    padding: "40px 0px 0px 0px",
    textAlign: "center"
  },
  contant__item: {
    paddingRight: "10px"
  },
  item__iconSocial: {
    width: "25px",
    margin: "10px"
  },
  visible: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

export default function AsideFooter() {
  const classes = useStyles();
  return (
    <Grid item className={classes.block__item}>
      <Grid
        item
        container
        justify="center"
        className={classes.aside__block__item}
      >
        <Grid item>
          <a href="mailto:moo@svs-gru.ru">
            <Grid
              item
              container
              alignItems="center"
              className={classes.item__contant}
            >
              <EmailRoundedIcon className={classes.contant__item} />
              <span className={classes.visible}>{config.email}</span>
            </Grid>
          </a>
          <a href="tel:{config.phone}">
            <Grid
              item
              container
              alignItems="center"
              className={classes.item__contant}
            >
              <PhoneInTalkRoundedIcon className={classes.contant__item} />
              <span className={classes.visible}>{config.phone}</span>
            </Grid>
          </a>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
      // className={classes.aside__block__item_padding}
      >
        <Grid item className={classes.item__iconSocial}>
          {/*<LogoVkIcon />*/}
        </Grid>
        <Grid item className={classes.item__iconSocial}>
          {/*<LogoInstagramIcon />*/}
        </Grid>
      </Grid>
    </Grid>
  );
}
