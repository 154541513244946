import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useParams, useHistory} from "react-router-dom";
import {dataCourse} from './dataCourse';
import {getStudent, getLesson, setLessons} from '../../adapters/getProfile';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '20px 0px'
    },
    header__section: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridGap: '20px',
        alignItems: 'baseline',
        margin: '10px 0px',
        [theme.breakpoints.down('xs')]: {
            gridGap: '7px',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr auto',
        },
    },
    section__lesson__info_2: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '20px 0px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },

    },
    header__section_2: {
        padding: '10px',
        border: '1px solid #a5a5a5',
        flex: '1 1 0',
        '&:not(:last-child)': {
            margin: '0px 10px 0px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            '&:not(:last-child)': {
                margin: '0px 0px 10px 0px',
            },
        },
    },
    section__description_2: {
        fontFamily: 'Play, Open Sans',
        fontSize: '1.8rem',
        fontWeight: '100',
    },
    section__title: {
        fontFamily: 'Russo One, Play, Open Sans',
        fontSize: '0.8rem',
        fontWeight: '100',
        //padding: '5px',

    },
    section__description: {
        fontFamily: 'Play, Open Sans',
        fontSize: '1rem',
        fontWeight: '100',
        //padding: '5px',
    },
    description__lesson: {
        margin: '20px 0px 20px 0px',
        background: 'white',
        padding: '10px',
        border: '1px solid #dedede',
        borderRadius: '2px',
        boxShadow: '1px 1px 4px 0px #b1b1b180',
    },
    lesson_title: {
        fontSize: '1.2rem',
    },
    section__lesson__info: {
        margin: '10px 0px',
        background: 'white',
        padding: '10px',
        border: '1px solid #dedede',
        borderRadius: '2px',
        boxShadow: '1px 1px 4px 0px #b1b1b180',
    },
    section__materials: {
        margin: '10px 0px',
        padding: '10px'
    },
    materials__description: {
        fontFamily: 'Play, Open Sans',
        fontSize: '0.8rem',
        fontWeight: '100',
        margin: '20px 0px'
    },
    material__list: {
        listStyleType: 'none',
        display: 'flex',
    },
    material__item: {
        background: 'white',
        padding: '10px',
        '&:not(:last-child)': {
            margin: '0px 10px 0px 0px',
        },
        '&:hover': {
            boxShadow: '1px 1px 10px 0px #bababa96',
            cursor: 'pointer'
        }
    },
    disable__material: {
        pointerEvents: 'none',
        opacity: '0.5',
    },
    button__back: {
        alignItems: 'center',
        display: 'inline-flex',
        padding: '5px 7px',
        background: '#ffffff5e',
        border: '0',
        borderRadius: '3px',
        '&:hover': {
            boxShadow: '1px 1px 10px 0px #bababa96',
            cursor: 'pointer',
            background: '#ffffff',
        }
    },
    button__text: {

    },

}))

export default function LessonDescription({setActiveBlock}) {
    const classes = useStyles();
    const history = useHistory();
    const {block, section, id} = useParams();
    const [paramCourse, setParamCourse] = useState();

    useEffect(() => {
        setActiveBlock(block);
        //getLesson(`lesson` + (+id[7] + 1)).then((result) => {
        //    setParamCourse(result.date.toDate().toLocaleDateString());
        //}).catch((error) => {
        //    console.log(error);
        //});
    }, [])

    const _block = dataCourse[block[6]];
    const _section = _block.sections[section[8]];
    const titleLesson = _section.lessons[id[7]].title;
    const backHistory = () => {
        history.goBack();
    }

    return (
        <div className={classes.main}>
            <button className={classes.button__back} onClick={backHistory}>
                <ArrowBackIosIcon />
                <span className={classes.button__text}>Назад</span>
            </button>

            <div className={classes.section__lesson__info_2}>
                <div className={classes.header__section_2}>
                    <div className={classes.section__title}>Дата занятия:</div>
                    <div className={classes.section__description_2}>{paramCourse ? paramCourse : '05.06.2022'} </div>
                </div>
                <div className={classes.header__section_2}>
                    <div className={classes.section__title}>Статус занятия:</div>
                    <div className={classes.section__description_2}>не пройдено</div>
                </div>
            </div>

            <div className={classes.description__lesson}>
                <div className={classes.header__section}>
                    <div className={classes.section__title}>{`Блок ${_block.id + 1}`}:</div>
                    <h2 className={classes.section__description}>
                        {_block.block.title}
                    </h2>
                </div>

                <div className={classes.header__section}>
                    <div className={classes.section__title}>Раздел {_section.id + 1}:</div>
                    <h3 className={classes.section__description}>{_section.title}</h3>
                </div>

                <div className={classes.header__section}>
                    <div className={classes.section__title}>Занятие:</div>
                    <div className={clsx(classes.section__description, classes.lesson_title)}>{titleLesson}</div>
                </div>
            </div>

            <div className={classes.section__lesson__info}>
                <div className={classes.header__section}>
                    <div className={classes.section__title}>Педагог:</div>
                    <div className={classes.section__description}>Шо Александ Александрович</div>
                </div>
                <div className={classes.header__section}>
                    <div className={classes.section__title}>Контакт с педагогом:</div>
                    <div className={classes.section__description}>Телеграм: @prepodCool</div>
                </div>
                <div className={classes.header__section}>
                    <div className={classes.section__title}>Дата занятия:</div>
                    <div className={classes.section__description}>{paramCourse ? paramCourse : '11.11.1111'} </div>
                </div>
                <div className={classes.header__section}>
                    <div className={classes.section__title}>Ссылка на занятие:</div>
                    <div className={classes.section__description}>нет</div>
                </div>
            </div>

            <div className={classes.section__materials}>
                <div className={classes.section__title}>Материалы для скачивания:</div>
                <div className={classes.materials__description}>
                    <ul className={classes.material__list}>
                        <li className={classes.material__item}>Презентация</li>
                        <li className={clsx(classes.material__item, classes.disable__material)}>Запись</li>
                    </ul>
                </div>
            </div>
        </div >
    )
}