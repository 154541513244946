import React from "react";
import SkillCourseItem from "./skillCourseItem";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    skillsText:{
        margin:"50px 0px 50px 0px",
        [theme.breakpoints.down("xs")]: {
            margin:"25px 0px 25px 0px",
          }
    },
    skillsTextItem: {
        margin: "10px",
        background: "#99a63c",
        color:"white",
    }

}))

export default function SkillsCoursesAll({text}){
    const classes = useStyles();
    return (
        <Grid container className={classes.skillsText}>
            {text.map((el)=>(
                <Grid item xs={12} sm className={classes.skillsTextItem}>
                    <SkillCourseItem text={el}/>
                </Grid>
            ))}
        </Grid>
    )
}