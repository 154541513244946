import React from "react";
import ButtonComp from "./common/button";
import {ReactComponent as ContentBlockIcon} from "../static/svg/content-block.svg";
import {ReactComponent as LogoGruIcon} from "../static/svg/logo-gru.svg";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "80%",
    alignContent: "start"
  },
  content__block: {
    backgroundColor: "#F7F7F7",
    padding: "0px 45px 0px 45px",
    position: "relative",
    "&::before": {
      left: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    "&::after": {
      right: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 25px 0px 25px"
    }
  },
  content__block_left: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_right: {
    textAlign: "center"
  },
  content__block_position: {
    // position: "absolute"
  },
  content__block_title: {
    fontFamily: "Yeseva One",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    }
  },
  content__block_subtitle: {
    fontFamily: "Open Sans",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem"
    }
  },
  content__block_padding: {
    padding: "0px 0px 20px 0px"
  },
  content__block_right_size: {
    maxWidth: "300px",
    minWidth: "200px"
  },
  content__block_size: {
    height: "65px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  content__block__leftTop: {
    transform: "skewX(-50deg)",
    transformOrigin: "bottom"
    // "&::before": {
    //   left: "0",
    //   width: "8%",
    //   height: "100%",
    //   content: "''",
    //   position: "absolute",
    //   borderTop: "1px solid #99A63C",
    //   borderLeft: "1px solid #99A63C",
    //   borderBottom: "1px solid #99A63C",

    // },
    // "&::after": {
    //   right: "0",
    //   width: "8%",
    //   height: "100%",
    //   content: "''",
    //   position: "absolute",
    //   borderTop: "1px solid #99A63C",
    //   borderRight: "1px solid #99A63C",
    //   borderBottom: "1px solid #99A63C"
    // },
  },
  block__leftTop_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  block__rightTop_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightTop: {
    transformOrigin: "bottom"
  },
  content__block__leftBottom: {
    // transform: "skewX(-50deg)",
    transformOrigin: "top",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__leftBottom_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightBottom: {
    transform: "skewX(-50deg)",
    transformOrigin: "top",
    zIndex: "-1",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__rightBottom_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  test: {
    cursor: "pointer",
    fontFamily: "Russo One, Open Sans",
    fontSize: "1.2rem",
    textAlign: 'center',
    margin: '20px 0px',
  }
}));

export default function Content() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.test}><Link to='/tests'>Пройти тест: Вспомнить все!</Link></div>
      <Grid item container className={classes.content}>
        {/* <ContentBlockIcon className={classes.content__block_position} /> */}
        <Grid
          item
          className={clsx(
            classes.content__block__leftTop,
            classes.content__block_size
          )}
        >
          <div className={classes.block__leftTop_shadow}></div>
        </Grid>
        <Grid
          item
          className={clsx(
            classes.content__block__rightTop,
            classes.content__block_size
          )}
        >
          <div className={classes.block__rightTop_shadow}></div>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          className={classes.content__block}
        >
          <Grid item sm className={classes.content__block_left}>
            <Typography
              variant="h4"
              className={clsx(
                classes.content__block_title,
                classes.content__block_padding
              )}
            >
              Курсы специальной военной подготовки
            </Typography>
            <Typography
              variant="subtitle1"
              className={clsx(
                classes.content__block_subtitle,
                classes.content__block_padding
              )}
            >
              При поддержке общественной организации "Союза ветеранов спецназа
              ГРУ", действующих офицеров Вооруженных Сил РФ и офицеров запаса
            </Typography>
            <ButtonComp
              link="/special-courses/kurs-na-patriotizm"
              title="Подробнее"
              endIcon={<ArrowRightAltIcon />}
            />
          </Grid>
          <Grid item sm className={classes.content__block_right}>
            <LogoGruIcon className={classes.content__block_right_size} />
          </Grid>
        </Grid>

        <Grid
          item
          className={clsx(
            classes.content__block__leftBottom,
            classes.content__block_size
          )}
        >
          <div className={classes.block__leftBottom_shadow}></div>
        </Grid>
        <Grid
          item
          className={clsx(
            classes.content__block__rightBottom,
            classes.content__block_size
          )}
        >
          <div className={classes.block__rightBottom_shadow}></div>
        </Grid>
      </Grid>
    </>
  );
}
