import React from "react";
import Title from "./common/title";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  news__cards: {},
  content: {
    // height: "80%",
    alignContent: "start"
    // paddingTop: "70px"
  },
  content__block_subtitle: {
    fontFamily: "Open Sans"
  },
  content__block_title: {
    fontFamily: "Russo One",
    fontSize: "1.5rem"
  },
  content__block_img_size: {
    width: "320px"
  },
  content__markers__circle: {
    width: "35px",
    height: "35px",
    background: "#99A63C",
    borderRadius: "50%",
    position: "relative"
  },
  content__text: {
    paddingLeft: "20px",
    fontFamily: "Segoe UI"
  },
  markers__circle__mini: {
    "&::before": {
      content: "''",
      width: "15px",
      height: "15px",
      position: "absolute",
      background: "#735F22",
      borderRadius: "50%",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto"
    }
  },
  content__markers_line: {
    height: "31px",
    width: "3px",
    background: "#735F22",
    margin: "5px 16px 5px 16px"
  }
}));

export default function StagesAdmission() {
  const classes = useStyles();
  return (
    <Grid container style={{ paddingTop: "70px" }}>
      <Title title="Как поступить в УЦ" />
      <Grid item container className={classes.news__cards}>
        <Grid item container alignItems="center" className={classes.content}>
          <Grid
            item
            className={clsx(
              classes.content__markers__circle,
              classes.markers__circle__mini
            )}
          ></Grid>
          <Grid item className={classes.content__text}>
            Изучить виды дисциплин
          </Grid>
        </Grid>
        <Grid item className={classes.content__markers_line}></Grid>
        <Grid item container alignItems="center" className={classes.content}>
          <Grid item className={classes.content__markers__circle}></Grid>
          <Grid item className={classes.content__text}>
            Пройти тест, для определения навыков.
          </Grid>
        </Grid>
        <Grid item className={classes.content__markers_line}></Grid>
        <Grid item container alignItems="center" className={classes.content}>
          <Grid item className={classes.content__markers__circle}></Grid>
          <Grid item className={classes.content__text}>
            Пройти регистрацию.
          </Grid>
        </Grid>
        <Grid item className={classes.content__markers_line}></Grid>
        <Grid item container alignItems="center" className={classes.content}>
          <Grid item className={classes.content__markers__circle}></Grid>
          <Grid item className={classes.content__text}>
            Выбрать курс и дату начала обучения.
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
