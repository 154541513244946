import React from 'react';
import {tasks_lesson} from "../../api/data";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

const useStyles = makeStyles((theme) => ({
    tasks: {
        padding: "20px"
    },
    tasks_content: {
        //margin: "0px 20px 0px 20px",
        padding: "10px 10px 10px 10px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: "1px 2px 9px 0px rgb(40, 40, 40, 0.27 )"
        },
    },
    tasks_title: {
        paddingLeft: "10px",
        flex: "1 1 0",
    },
    tasks_progress: {
        paddingLeft: "10px"
    },
    tasks_button: {
        paddingLeft: "10px"
    }

}))

export default function TasksItem({data, type = 'test'}) {
    const classes = useStyles();
    return (
        <>
            {
                type === 'test' ?
                    <Grid item xs={12} container className={classes.tasks_content}>
                        <Grid item className={classes.tasks_icon}><StarBorderOutlinedIcon /></Grid>
                        <Grid item className={classes.tasks_title}> {data.name_task}</Grid>
                        <Grid item className={classes.tasks_progress}>{data.progress}</Grid>
                        <Grid item className={classes.tasks_button}><ArrowRightAltIcon /></Grid>
                    </Grid>
                    :
                    <a style={{width: "100%"}} target="_blank" href={data.src}>
                        <Grid item xs={12} container className={classes.tasks_content}>
                            <Grid item className={classes.tasks_icon}><StarBorderOutlinedIcon /></Grid>
                            <Grid item className={classes.tasks_title}> {data.name}</Grid>
                            <Grid item className={classes.tasks_button}><ArrowRightAltIcon /></Grid>
                        </Grid>
                    </a>
            }
        </>
    )
}