import React from "react";
import ButtonComp from "./button";
import { ReactComponent as ContentBlockIcon } from "../../static/svg/content-block.svg";
import { ReactComponent as LogoGruIcon } from "../../static/svg/logo-gru.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "80%",
    alignContent: "start"
  },
  content__block: {
    // backgroundColor: "#F7F7F7",
    // padding: "0px 45px 0px 45px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      // padding: "0px 25px 0px 25px"
    }
  },
  content__block_left: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_right: {
    textAlign: "center"
  },
  content__block_right_size: {
    maxWidth: "300px",
    minWidth: "200px"
  },
  content_fullWidth: {
    width: "100%"
  },
  content_list: {
    flexGrow: "0",
    maxWidth: "50%",
    flexBasis: "50%",
  }
}));


export default function CardMediaNoCorners({ children, imgUrl, sizeСorners = "big", fullWidth }) {
  const classes = useStyles();
  return (
    <Grid item sm={12} container className={clsx(classes.content, fullWidth ? classes.content_fullWidth : classes.content_list  )}>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classes.content__block}
      >
        <Grid item sm className={classes.content__block_left}>
          {children}
          {/* <ButtonComp title="Подробнее" endIcon={<ArrowRightAltIcon />} /> */}
        </Grid>
        {imgUrl && (
          <Grid item sm className={classes.content__block_right}>
            <img
              src={imgUrl}
              alt={""}
              className={classes.content__block_right_size}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
