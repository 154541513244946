import React from "react";

import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TodayIcon from "@material-ui/icons/Today";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingRight: "50px"
  },
  card__img: {
    width: "350px",
    height: "200px",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    }
  },
  card__text: {},
  card__img_size: {
    minWidth: "100%",
    height: "100%"
  },
  card__info: {
    padding: "10px 0px 10px 0px",
    fontSize: "0.8rem"
  },
  card__text_title: {
    fontSize: "1.2rem",
    fontFamily: "Play",
    fontWeight: "bold",
    padding: "0px 0px 10px 0px"
  },
  card__text_preview: {
    fontSize: "1rem"
  },
  card__info_padding: {
    padding: "0px 0px 0px 5px"
  },
  card__info__icon: {
    fill: "rgba(153, 166, 60, 75%)",
    fontSize: "1rem"
  }
}));

export default function CardMediaNews({
  img,
  alt,
  tag,
  views,
  date,
  title,
  text
}) {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.card}>
      <Grid item className={classes.card__img}>
        <img src={img} alt={alt} className={classes.card__img_size} />
      </Grid>
      <Grid item container justify="space-between" className={classes.card__info}>
        <Grid item className={classes.card__info_tag}>
          #{tag}
        </Grid>
        <Grid item wrap="nowrap" justify="flex-end">
          {/* <Grid item container wrap="nowrap" alignItems="center">
            <Grid item>
              <VisibilityIcon className={classes.card__info__icon} />
            </Grid>
            <Grid item className={classes.card__info_padding}>
              {views}
            </Grid>
          </Grid> */}
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.card__info_date}
          >
            <Grid item>
              <TodayIcon className={classes.card__info__icon} />
            </Grid>
            <Grid item className={classes.card__info_padding}>
              {date}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.card__text}>
        <Grid item className={classes.card__text_title}>
          {/* <div dangerouslySetInnerHTML={{ __html: `${title}` }}></div> */}
          {title}
        </Grid>
        <Grid item className={classes.card__text_preview}>
          {text}
        </Grid>
      </Grid>
    </Grid >
  );
}
