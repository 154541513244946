import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TodayIcon from "@material-ui/icons/Today";


const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0px 0px 50px 0px"
    },
    card__img: {

    },
    card__text: {},
    card__img_size: {
        width: "100%"
    },
    card__info: {
        padding: "10px 0px 10px 0px",
        fontSize: "0.8rem"
    },
    card__text_title: {
        fontSize: "2rem",
        textAlign: "center",
        fontFamily: "Play",
        fontWeight: "bold",
        margin: "0px 0px 20px 0px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px 10px 0px",
            textAlign: "left",
            fontSize: "1.8rem",
        }
    },
    card__text_preview: {
        fontSize: "1rem"
    },
    card__info_padding: {
        padding: "0px 0px 0px 5px"
    },
    card__info__icon: {
        fill: "rgba(153, 166, 60, 75%)",
        fontSize: "1rem"
    },
    card__text_h2: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        fontFamily: "Play",
        paddingBottom: "10px"
    },

}));


export default function NewsItem({ data }) {
    const classes = useStyles();
    const { url } = useParams();
    function get(url) {
        const isItem = (p) => p.url === url;
        return data.find(isItem);
    }
    const newItem = get(url);
    if (!newItem) {
        return <div>Страница не найдена</div>;
    }
    return (
        <>
            <Grid item container xs={12} className={classes.card}>
                <Grid item sm={12} className={classes.card__img}>
                    <img src={newItem.img} alt={newItem.alt} className={classes.card__img_size} />
                </Grid>
                <Grid item sm={12} container>
                    <Grid item container justify="space-between" className={classes.card__info}>
                        <Grid item className={classes.card__info_tag}>
                            #{newItem.tag}
                        </Grid>
                        <Grid item wrap="nowrap" justify="flex-end">
                            {/* <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item>
                                <VisibilityIcon className={classes.card__info__icon} />
                            </Grid>
                            <Grid item className={classes.card__info_padding}>
                                {views}
                            </Grid>
                        </Grid> */}
                            <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                className={classes.card__info_date}
                            >
                                <Grid item>
                                    <TodayIcon className={classes.card__info__icon} />
                                </Grid>
                                <Grid item className={classes.card__info_padding}>
                                    {newItem.date}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.card__text}>
                        <Grid item className={classes.card__text_title}>
                            {newItem.title}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.card__text_h2}>
                    {newItem.text}
                </Grid>
                <Grid item className={classes.card__text_preview}>
                    <div dangerouslySetInnerHTML={{ __html: `${newItem.description}` }}></div>
                </Grid>
                {/* <Grid><ButtonComp title="Подробнее" /></Grid> */}
            </Grid >
        </>
    )
}
