import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Title from "../Title";


const useStyles = makeStyles((theme) => ({
    section: {
        paddingBottom: "100px"
    },
    section__cards: {
        // overflow: "auto",

        "&::-webkit-scrollbar": {
            height: "10px"
            // width: "30px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#99a63c",
            height: "10px",
            borderRadius: "10px"
        }
    },
}));

const Section = ({title, children}) => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <Title title={title} />
            <div className={classes.section__cards}>
                {children}
            </div>
        </div>
    );
}

export default Section;
