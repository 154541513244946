export const dataMedia = [
    {
        id: 1,
        img: 'img1.jpg',
        date: '30/11/2021',
        time: '05:55',
        title: 'Как это работает? Автомат Калашникова.',
        videoId: 'J9xIVlUaLkg',
    },
    {
        id: 2,
        img: 'img2.jpg',
        date: '30/11/2021',
        time: '01:19',
        title: 'Как это работает? Пистолет бесшумный (ПБ)',
        videoId: '_sRHIeomWfk',
    },
    {
        id: 3,
        img: 'img3.jpg',
        date: '01/12/2021',
        time: '01:06',
        title: 'Как это работает? Пулемёт Калашникова Модернизированный (ПКМ)',
        videoId: 'pDkQMHOIuP8',
    },
    {
        id: 4,
        img: 'img-ak.jpg',
        date: '12/12/2021',
        time: '02:00',
        title: 'Как это работает? Автомат Калашникова - 2',
        videoId: 'cQi-A9YLsPg',
    },
    {
        id: 5,
        img: 'img-pistol-glock.jpg',
        date: '12/12/2021',
        time: '02:45',
        title: 'Как это работает? Пистолет Глок',
        videoId: 'Rt7dnBvNDJg',
    },
    {
        id: 6,
        img: 'img-pistol-makarova.jpg',
        date: '12/12/2021',
        time: '02:15',
        title: 'Разбор пистолета Макарова',
        videoId: '8WJzMBuEl08',
    },
    {
        id: 7,
        img: 'img-svd.jpg',
        date: '12/12/2021',
        time: '01:15',
        title: 'Принцип работы СВД',
        videoId: '30ozjBYFgGk',
    },
    {
        id: 8,
        img: 'img-ruger.jpg',
        date: '12/12/2021',
        time: '01:43',
        title: 'Принцип работы винтовки Ruger',
        videoId: 'HUstNOLtQVA',
    },
]