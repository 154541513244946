import React from "react";
import BorderContent from "../../../components/common/borderContent";
import TasksItem from "../dashboard/tasks/tasksItem";
import TaskContent from "./taskContent";
import {tasks_lesson} from "../api/data";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {testData} from "../api/testData";
import {endTest} from "../api/endTest";

const useStyles = makeStyles((theme) => ({
    tasks: {
        padding: "20px"
    },
    tasks_content: {
        margin: "0px 20px 0px 20px"
    },
    tasks_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        // margin: "10px 0px 10px 20px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    lesson_time: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "2rem",
        textTransform: "uppercase"
    },
    lesson_time2: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.5rem",
        margin: "40px 0px"
    }
}))

export default function TasksPage({data}) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.lesson_time}>
                Ваши задания
                {/* {lesson.start_lesson} */}
            </div>
            {data && data.length === 0 ? <div className={classes.lesson_time2}>У вас нет курсов </div> :

                data && data[0]?.lessons.finTest ?
                    <TaskContent data={data} testData={endTest.testEnd} numberTest={data && data[0]?.lessons.activeTest || 1} finTest="true" />

                    :
                    <TaskContent data={data} testData={testData[`test${data && data[0]?.lessons.activeTest || 1}`]} numberTest={data && data[0]?.lessons.activeTest || 1} />


            }
        </>
    )
}

{/*<BorderContent classGrid={classes.tasks} fullWidth>

                        <Grid item className={classes.tasks_subtopic}>Задания</Grid>
                        {
                            tasks_lesson.tasks.map((el) => {
                                return <TasksItem data={el} />
                            })
                        }
                    </BorderContent>*/}