export const dataTestsSVD = [
    {
        id: 0,
        question: 'Начальная скорость полёта пули:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '715 м/с',
                answer: false,
            },
            {
                id: 2,
                text: '825 м/с',
                answer: false,
            },
            {
                id: 3,
                text: '830 м/с',
                answer: true,
            },
            {
                id: 4,
                text: '900 м/с',
                answer: false,
            }
        ]
    },
    {
        id: 1,
        question: 'Прицельная дальность стрельбы с оптическим прицелом:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '900 м',
                answer: false,
            },
            {
                id: 2,
                text: '1200 м',
                answer: false,
            },
            {
                id: 3,
                text: '1300 м',
                answer: true,
            },
            {
                id: 4,
                text: '1500 м',
                answer: false,
            }
        ]
    },
    {
        id: 2,
        question: 'Дальность эффективной стрельбы:',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'до 400',
                answer: false,
            },
            {
                id: 2,
                text: '800',
                answer: true,
            },
            {
                id: 3,
                text: '1000',
                answer: false,
            },
            {
                id: 4,
                text: '1200',
                answer: false,
            }
        ]
    },
    {
        id: 3,
        question: 'Ёмкость магазина:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '10 патронов',
                answer: true,
            },
            {
                id: 2,
                text: '12 патронов',
                answer: false,
            },
            {
                id: 3,
                text: '15 патронов',
                answer: false,
            },
            {
                id: 4,
                text: '20 патронов',
                answer: false,
            }
        ]
    },
    {
        id: 4,
        question: 'Калибр СВД:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '5,45 мм',
                answer: false,
            },
            {
                id: 2,
                text: '7,62 мм',
                answer: true,
            },
            {
                id: 3,
                text: '9мм',
                answer: false,
            },
            {
                id: 4,
                text: '12.5мм',
                answer: false,
            }
        ]
    },
    {
        id: 5,
        question: 'Масса обыкновенной пули со стальным сердечником:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '5 г',
                answer: false,
            },
            {
                id: 2,
                text: '7.5 г',
                answer: false,
            },
            {
                id: 3,
                text: '9.6 г',
                answer: true,
            },
            {
                id: 4,
                text: '10.6 г',
                answer: false,
            }
        ]
    },
    {
        id: 6,
        question: 'Масса винтовки без штыка-ножа с оптическим прицелом, неснаряженным магазином и щекой:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '3,5 кг',
                answer: false,
            },
            {
                id: 2,
                text: '4,3кг',
                answer: true,
            },
            {
                id: 3,
                text: '7,5 кг',
                answer: false,
            },
            {
                id: 4,
                text: '9,5 кг',
                answer: false,
            }
        ]
    },
    {
        id: 7,
        question: 'Дальность на которой сохраняется убойное действие пули:',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'до 1500 м',
                answer: false,
            },
            {
                id: 2,
                text: 'до 2000 м',
                answer: false,
            },
            {
                id: 3,
                text: 'до 2200 м',
                answer: false,
            },
            {
                id: 4,
                text: 'до 3800 м',
                answer: true,
            }
        ]
    },
    {
        id: 8,
        question: 'Масса порохового заряда:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '1 г',
                answer: false,
            },
            {
                id: 2,
                text: '2.5 г',
                answer: false,
            },
            {
                id: 3,
                text: '3.1 г',
                answer: true,
            },
            {
                id: 4,
                text: '5 г',
                answer: false,
            }
        ]
    },
    {
        id: 9,
        question: 'СВД была принята на вооружение в',
        answer: 2,
        options: [
            {
                id: 1,
                text: '1961',
                answer: false,
            },
            {
                id: 2,
                text: '1963',
                answer: true,
            },
            {
                id: 3,
                text: '1967',
                answer: false,
            },
            {
                id: 4,
                text: '1969',
                answer: false,
            }
        ]
    },
    {
        id: 10,
        question: 'На чем основано автоматическое действие СВД?',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'с использованием отдачи затвора',
                answer: false,
            },
            {
                id: 2,
                text: 'с использованием энергии других источников',
                answer: false,
            },
            {
                id: 3,
                text: 'с использованием отдачи подвижного ствола',
                answer: false,
            },
            {
                id: 4,
                text: 'с использованием энергии пороховых газов, отводимых в газовую камеру',
                answer: true,
            }
        ]
    },
    {
        id: 11,
        question: 'Дальность прямого выстрела у СВД по бегущая фигуре:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '430 м',
                answer: false,
            },
            {
                id: 2,
                text: '640 м',
                answer: true,
            },
            {
                id: 3,
                text: '750 м',
                answer: false,
            },
            {
                id: 4,
                text: '1000 м',
                answer: false,
            }
        ]
    },
    {
        id: 12,
        question: 'Дальность прямого выстрела у СВД по головной фигуре:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '250 м',
                answer: false,
            },
            {
                id: 2,
                text: '300 м',
                answer: false,
            },
            {
                id: 3,
                text: '350 м',
                answer: true,
            },
            {
                id: 4,
                text: '500 м',
                answer: false,
            }
        ]
    }
]