import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    history__block:{
        margin:"50px 0px 50px 0px"
    },
}))

export default function HistoryCourse({history}){
    const classes = useStyles();
    return(
        <div className={classes.history__block}>
        {history}
        </div>
    )
}