import React from "react";
import Aside from "../../components/aside";
import Header from "../../components/header";
import OfficialInfoPage from "./officialInfoPage";
import Footer from "../../components/footer";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    main: {
        flex: "1 1 0",
        maxWidth: "1400px",
        padding: "60px 80px 30px 80px",
        // width: "70%",
        width: "calc(100% - 280px)",
        marginLeft: "280px",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "100px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "10px 25px 10px 25px",
            marginLeft: "0px"
        }
        // width: "700px"
    }
}));

export default function OfficialInfo() {
    const classes = useStyles();
    return (
        <>
            <Grid container>
                <Aside />
                <Grid item className={classes.main}>
                    <Header />
                    <OfficialInfoPage />
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}
