import React from "react";
import CardContent from "./cardContent";
import ButtonComp from "./button";
import { ReactComponent as CertificateIcon } from "../../static/svg/certificate.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "80%",
    alignContent: "start"
    // paddingTop: "70px"
  },
  content__block: {
    // backgroundColor: "#F7F7F7",
    padding: "45px",
    position: "relative"
    // "&::before": {
    //   left: "0",
    //   width: "8%",
    //   height: "100%",
    //   content: "''",
    //   position: "absolute",
    //   zIndex: "-1",
    //   boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
    //   backgroundColor: "#F7F7F7"
    // },
    // "&::after": {
    //   right: "0",
    //   width: "8%",
    //   height: "100%",
    //   content: "''",
    //   position: "absolute",
    //   zIndex: "-1",
    //   boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
    //   backgroundColor: "#F7F7F7"
    // }
  },
  content__block_left: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_right: {
    textAlign: "center"
  },
  content__block_position: {
    // position: "absolute"
  },
  content__block_title: {
    fontFamily: "Russo One",
    fontSize: "1.5rem"
  },
  content__block_subtitle: {
    fontFamily: "Open Sans"
  },
  content__block_padding: {
    padding: "0px 0px 20px 0px"
  },
  content__block_right_size: {
    width: "240px"
  }
  // content__block__leftTop: {
  //   height: "65px",
  //   width: "50%",
  //   backgroundColor: "#F7F7F7",
  //   transform: "skewX(-50deg)",
  //   transformOrigin: "bottom"
  // "&::before": {
  //   left: "0",
  //   width: "8%",
  //   height: "100%",
  //   content: "''",
  //   position: "absolute",
  //   borderTop: "1px solid #99A63C",
  //   borderLeft: "1px solid #99A63C",
  //   borderBottom: "1px solid #99A63C",

  // },
  // "&::after": {
  //   right: "0",
  //   width: "8%",
  //   height: "100%",
  //   content: "''",
  //   position: "absolute",
  //   borderTop: "1px solid #99A63C",
  //   borderRight: "1px solid #99A63C",
  //   borderBottom: "1px solid #99A63C"
  // },
  // }
  // block__leftTop_shadow: {
  //   position: "relative",
  //   height: "100%",
  //   backgroundColor: "#F7F7F7",
  //   "&::before": {
  //     left: 0,
  //     width: "89%",
  //     height: "100%",
  //     content: "''",
  //     position: "absolute",
  //     boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
  //     zIndex: -1
  //   }
  // },
  // block__rightTop_shadow: {
  //   position: "relative",
  //   height: "100%",
  //   backgroundColor: "#F7F7F7",
  //   "&::before": {
  //     right: 0,
  //     width: "89%",
  //     height: "100%",
  //     content: "''",
  //     position: "absolute",
  //     boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
  //     zIndex: -1
  //   }
  // },
  // content__block__rightTop: {
  //   height: "65px",
  //   width: "50%",
  //   backgroundColor: "#F7F7F7",
  //   transformOrigin: "bottom"
  // },
  // content__block__leftBottom: {
  //   height: "65px",
  //   width: "50%",
  //   backgroundColor: "#F7F7F7",
  //   // transform: "skewX(-50deg)",
  //   transformOrigin: "top"
  // },
  // block__leftBottom_shadow: {
  //   position: "relative",
  //   height: "100%",
  //   backgroundColor: "#F7F7F7",
  //   "&::before": {
  //     left: 0,
  //     width: "89%",
  //     height: "100%",
  //     content: "''",
  //     position: "absolute",
  //     boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
  //     zIndex: -1
  //   }
  // },
  // content__block__rightBottom: {
  //   height: "65px",
  //   width: "50%",
  //   backgroundColor: "#F7F7F7",
  //   transform: "skewX(-50deg)",
  //   transformOrigin: "top",
  //   zIndex: "-1"
  // },
  // block__rightBottom_shadow: {
  //   position: "relative",
  //   height: "100%",
  //   backgroundColor: "#F7F7F7",
  //   "&::before": {
  //     right: 0,
  //     width: "89%",
  //     height: "100%",
  //     content: "''",
  //     position: "absolute",
  //     boxShadow: "1px 1px 7px 0px rgb(50 50 50 / 40%)",
  //     zIndex: -1
  //   }
  // }
}));

// const CardLeft = () => {
//   const classes = useStyles();
//   return (
//     <Grid
//       item
//       container
//       justify="center"
//       alignItems="center"
//       className={classes.content__block}
//     >
//       <Grid item sm className={classes.content__block_right}>
//         <CertificateIcon className={classes.content__block_right_size} />
//         {/* {imgUrl} */}
//       </Grid>
//       <Grid item sm className={classes.content__block_left}>
//         {/* {children} */}
//         <Typography
//           variant="subtitle1"
//           className={clsx(classes.content__block_subtitle)}
//         >
//           Обучающийся, прошедший обучение в «Учебном центре», успешно сдавший
//           тесты и экзамены на всех трех этапах, является
//         </Typography>
//         <Typography variant="h4" className={clsx(classes.content__block_title)}>
//           специалистом III класса
//         </Typography>
//         {/* {button.title} {button.onClick} */}
//         <ButtonComp title="Подробнее" endIcon={<ArrowRightAltIcon />} />
//       </Grid>
//     </Grid>
//   );
// };

export default function CardMedia({ children, ...other }) {
  const classes = useStyles();
  return <CardContent {...other}>{children}</CardContent>;
}
