import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardFolder from '../../common/cardFolder';

const useStyles = makeStyles((theme) => ({
    section: {
        paddingBottom: "100px"
    },
    section__cards: {
        // overflow: "auto",

        "&::-webkit-scrollbar": {
            height: "10px"
            // width: "30px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#99a63c",
            height: "10px",
            borderRadius: "10px"
        }
    },
    card: {
        //cursor: 'pointer',
        opacity: '0.8',
        //'&:hover': {
        //    filter: 'drop-shadow(2px 2px 6px #0000002e)',
        //}
    },
    main: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        //justifyItems: 'center',
        gridGap: '20px'
    },
    main__icon: {
        //fontSize: '4rem',
    },
    main__title: {
        fontSize: '1.2rem',
        fontFamily: 'Russo One, Open Sans',
    },
}));

const FolderIconTitle = ({title, icon}) => {
    const classes = useStyles();
    const _title = typeof title === 'function' ? title() : title;
    return (
        <CardFolder classGrid={classes.card}>
            <div className={classes.main}>
                {icon &&
                    <div className={classes.main__icon}>
                        {icon}
                    </div>
                }
                <div className={classes.main__title}>
                    {_title}
                </div>
            </div>
        </CardFolder>
    );
}

export default FolderIconTitle;
