import React from "react";
import Section from "../../components/common/section";
import CardContent2 from "../../components/common/cardContent2";
import CardMedia2 from "../../components/common/cardMedia2";
import Advantage from "../../components/advantage";
import StagesTraining from "../../components/stagesTraining";
import StagesAdmission from "../../components/stagesAdmission";
import DescriptionСourses from "../specialties/descriptionСourses";
import ButtonComp from "../../components/common/button";
import data from "../../api/dataSpecialCourses.json";
import Title from "../../components/common/title";

import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import {
    Link,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    StudyPrograms: {},
    content__block_title: {
        fontFamily: "Russo One",
        fontSize: "1.8rem",
        marginBottom: "5px"
    },
    content__block_subtitle: {
        fontFamily: "Open Sans"
    }
}));

function SpecialCourseBlock() {
    const classes = useStyles();
    let {url} = useRouteMatch();
    return (
        <Section title="Спецкурсы">
            <CardMedia2 sizeСorners="small" link={`${url}/kurs-na-patriotizm`} titleButton="Подробнее" fullWidth>
                <CardContent2
                    title="Курс на патриотизм"
                    description='Обучение представляет собой серию интерактивных занятий, тестирований и видеоуроков о патриотическом воспитании, военной подготовке и основах безопасности жизнедеятельности.'
                />
            </CardMedia2>
            <Advantage />
            <StagesTraining />
            <Grid container justify="center">
                <Link to={`${url}/kurs-na-patriotizm`}><Title title="Подробнее о курсе" /></Link>
            </Grid>

            {/* <StagesAdmission /> */}

        </Section>
    );
}

export default function SpecialCoursesPage() {
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={SpecialCourseBlock} />
            <Route path={`${path}/:url`}><DescriptionСourses data={data} /></Route>
        </Switch>
    );
}