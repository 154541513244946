import React from "react";

import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TodayIcon from "@material-ui/icons/Today";

import {
    Link,
    Route,
    useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    card__text: {},
    card__info: {
        padding: "10px 0px 10px 0px",
        fontSize: "0.8rem"
    },
    card__info_style: {
        alignContent: "baseline"
    },
    card__text_title: {
        fontSize: "1.2rem",
        fontFamily: "Play",
        fontWeight: "bold",
        padding: "0px 0px 10px 0px"
    },
    card__text_preview: {
        fontSize: "1rem"
    },
    card__info_padding: {
        padding: "0px 0px 0px 5px"
    },
    card__info__icon: {
        fill: "rgba(153, 166, 60, 75%)",
        fontSize: "1rem"
    },
    hidden: {
        transform: "translate3d(0px, -100%, 0px)",
        zIndex: -1,
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transform: "translate3d(0px, 0%, 0px)",
        transition: "opacity 1s, transform 1s",
    },
    displayItemNone: {
        display: "none",
    },
    displayItemBlock: {
        display: "block",
    }
}));

export default function CardMediaNewsInfo({
    tag,
    date,
    title,
    text
}) {
    const classes = useStyles();
    return (
        <Grid item sm={12} md={6} alignItems="baseline" container className={clsx(classes.card__info_style)}>
            <Grid item container justify="space-between" className={classes.card__info}>
                <Grid item className={classes.card__info_tag}>
                    #{tag}
                </Grid>
                <Grid item wrap="nowrap" justify="flex-end">
                    {/* <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item>
                                <VisibilityIcon className={classes.card__info__icon} />
                            </Grid>
                            <Grid item className={classes.card__info_padding}>
                                {views}
                            </Grid>
                        </Grid> */}
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={classes.card__info_date}
                    >
                        <Grid item>
                            <TodayIcon className={classes.card__info__icon} />
                        </Grid>
                        <Grid item className={classes.card__info_padding}>
                            {date}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.card__text}>
                <Grid item className={classes.card__text_title}>
                    {title}
                </Grid>
                <Grid item className={classes.card__text_preview}>
                    {text}
                </Grid>
            </Grid>
        </Grid>
    );
}
