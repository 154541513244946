export const lesson = [
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "История Вооруженных сил РФ",
        topic_lesson: "Военная история",
        subtopics_lesson: [
            "Значимые события военной истории.",
            "Выдающиеся военачальники и полководцы.",
            "Воинские подвиги Героев.",
            "История партизанского движения.",
            "Истории от существующих Героев РФ и боевых офицеров."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "История Вооруженных сил РФ",
        topic_lesson: "Вооруженные силы РФ",
        subtopics_lesson: [
            "История, предназначение ВС РФ.",
            "Виды и рода войск ВС РФ, состав и их структура."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "История Вооруженных сил РФ",
        topic_lesson: "Боевые традиции и символы воинской чести ВС РФ",
        subtopics_lesson: [
            "Обычаи и нормы поведения военнослужащих, связанные с выполнением боевых задач и несением воинской службы.",
            "Виды наград и история их появления. ",
            "Воинские звания и их отличия.",
            "Формы одежды",
            "Боевое знамя.",
            "Памятники, монументы, мемориалы и их значение."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общественно-государственная подготовка",
        topic_lesson: "Военная доктрина",
        subtopics_lesson: [
            "Основы военной доктрины РФ.",
            "Общие понятия: военный конфликт, вооруженный конфликт, военная угроза, локальная война, региональная война, крупномасштабная война.",
            "Военные опасности и военные угрозы Российской Федерации.",
            "Особенности современных военных конфликтов.",
            "Основные направления социально-политического обеспечения военной безопасности России. "
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общественно-государственная подготовка",
        topic_lesson: "ВС в структуре государства",
        subtopics_lesson: [
            "Структура органов государственной власти Российской Федерации.",
            "Место и роль Вооруженных Сил России в структуре органов государственной власти.",
            "Конституции Российской Федерации.",
            "Военная служба как особый вид государственной службы.",
            "Сущность, структура и функции военной политики государства. "
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общественно-государственная подготовка",
        topic_lesson: "Современное состояние и перспективы развития ВС РФ",
        subtopics_lesson: [
            "Вооруженные Силы РФ.",
            "Система комплектования ВС РФ.",
            "Система военного образования.",
            "Военно-патриотическое движение.",
            "Военные учения.",
            "Боевых возможностей армии и флота. ",
            "Оснащение ВС РФ.",
            "Прохождения военной службы."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общественно-государственная подготовка",
        topic_lesson: "Основные тенденции развития международной обстановки",
        subtopics_lesson: [
            "Россия в современном геополитическом пространстве.",
            "Гонка вооружений.",
            "Особенности развития международной обстановки.",
            "Особенности развития военно-политической обстановки в мире."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общественно-государственная подготовка",
        topic_lesson: "Угрозы безопасности России и её военная политика",
        subtopics_lesson: [
            "Угрозы национальной безопасности.",
            "Военная политика государства."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Тактическая  подготовка",
        topic_lesson: "Вооружение и техника",
        subtopics_lesson: [
            "Организация и структура ВС,  их состав и назначение, виды ВС РФ. Состав и назначение родов войск и специальных войск сухопутных войск.",
            "Современный общевойсковой бой, его характер, способы ведения и средства борьбы."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Тактическая  подготовка",
        topic_lesson: "Организация и ведение общевойскового боя",
        subtopics_lesson: [
            "Определение боевой готовности, чем достигается постоянная боевая готовность подразделений и частей.",
            "Оборона.",
            "Наступление.",
            "Марш и расположение на месте.",
            "Боевое обеспечение.",
            "Организация МСВ (МСО) ВС РФ.",
            "Походный, предбоевой, боевой поря-док МСВ(МСО) в пешем порядке и на машинах."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Тактическая  подготовка",
        topic_lesson: "Действия солдата в бою",
        subtopics_lesson: [
            "Развертывание взвода в предбоевой и бое-вой  порядки и перестроения.",
            "Способы и приемы передвижения л/с м/с подразделений в бою при действиях в пешем порядке.",
            "Порядок передачи сигналов, команд и постановки задач по радио.",
            "Управление одиночными военнослужащими в бою. Выполнение команд в бою.",
            "Действия солдата при передвижении в лесу.",
            "Действие наблюдателя при ведении разведки. Выбор, занятие и маскировка места для наблюдения."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиационная, химическая и биологическая защита",
        topic_lesson: "Ядерное, химическое, биологическое и зажигательное оружие вероятного противника и защита от него",
        subtopics_lesson: [
            "Виды ядерных взрывов, поражающие факторы ядерного взрыва и защита от них.",
            "ОВ, назначение, классификация, свойства, средства применения; первая помощь при поражении",
            "Биологическое оружие, общее понятие, способы применения, признаки применения, меры предосторожности и предупреждения инфекционных заболеваний"
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиационная, химическая и биологическая защита",
        topic_lesson: "Средства индивидуальной и коллективной защиты и пользование ими",
        subtopics_lesson: [
            "Назначение, устройство, подбор и пользование фильтрующим противогазом. Порядок надевания. Пользование поврежденным противогазом.",
            "Назначение, размеры и устройство ОЗК. Порядок надевания."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиационная, химическая и биологическая защита",
        topic_lesson: "Приемы и способы действия личного состава на зараженной местности",
        subtopics_lesson: [
            "Мероприятия по защите личного состава при продолжительных действиях на зараженной местности",
            "Средства и способы специальной обработки.",
            "Порядок передачи сигналов, команд и постановки задач по радио.",
            "Дезактивация, дегазация и дезинфекция продовольствия, и обеззараживание воды."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Огневая подготовка",
        topic_lesson: "Материальная часть стрелкового оружия",
        subtopics_lesson: [
            "Назначение, ТТХ автоматов Калашникова АКМ, общее устройство, принцип работы. Неполная разборка и сборка автомата после неполной разборки. Задержки при стрельбе и способы их устранения. Уход за автоматом, осмотр и подготовка его к стрельбе.",
            "Проверка боя автомата и приведение его к нормальному бою.",
            "Биологическое оружие, общее понятие, способы применения, признаки применения, меры предосторожности и предупреждения инфекционных заболеваний"
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Огневая подготовка",
        topic_lesson: "Стрелковые тренировки",
        subtopics_lesson: [
            "Выполнение подготовительного упражнения из мелкокалиберной винтовки."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиоподготовка",
        topic_lesson: "Военная связь",
        subtopics_lesson: [
            "История развития военной связи.",
            "Ее значение, состав и структура.",
            "Азбука Морзе."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиоподготовка",
        topic_lesson: "«Радиостанции Р-392, «Северок-1К»",
        subtopics_lesson: [
            "Общее устройство и ТТД радиостанции Р-392 ее боевое применение.",
            "Органы управления, подготовка к работе, настройка на заданную частоту.",
            "Общее устройство и ТТД радиостанции «Северок-1К», ее боевое применение.",
            "Органы управления, подготовка к работе, настройка на заданную частоту."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Радиоподготовка",
        topic_lesson: "Радиоприемник Р-255пп, маркерный передатчик Р-255мп",
        subtopics_lesson: [
            "Назначение, ТТД, общее устройство и подготовка Р-255пп к работе.",
            "Назначение, ТТД, общее устройство и подготовка Р-255мп к работе."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общевоинские уставы",
        topic_lesson: "Военнослужащие Вооруженных Сил и взаимоотношения между ними",
        subtopics_lesson: [
            "Права, обязанности и ответственность военнослужащих.",
            "Взаимоотношения между военнослужащими.",
            "Ответственность военнослужащих за совершение воинских преступлений."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общевоинские уставы",
        topic_lesson: "Воинская дисциплина, ее сущность и значение",
        subtopics_lesson: [
            "Сущность воинской дисциплины, обязанности военнослужащих по ее соблюдению.",
            "Поощрения.",
            "Дисциплинарные взыскания",
            "О предложениях, заявлениях и жалобах."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общевоинские уставы",
        topic_lesson: "Обязанности лиц суточного наряда",
        subtopics_lesson: [
            "Суточный наряд, его предназначение, состав и вооружение.",
            "Выполнение обязанностей дневального по роте.",
            "Выполнение обязанностей дежурного по роте."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Общевоинские уставы",
        topic_lesson: "Организация и несение караульной службы",
        subtopics_lesson: [
            "Общие понятия об организации караульной службы в войсках",
            "Порядок развода и смены караула"
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Строевая подготовка",
        topic_lesson: "Строевые приемы и движения без оружия",
        subtopics_lesson: [
            "Выполнение команд.",
            "Движение строевым шагом."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Военная топография",
        topic_lesson: "Ориентирование и движение на местности без карты",
        subtopics_lesson: [
            "Сущность и способы ориентирования на местности.",
            "Определение направлений на стороны горизонта.",
            "Измерение расстояний.",
            "Измерение углов на местности.",
            "Движение по азимуту."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Военная топография",
        topic_lesson: "Отечественные топографические карты, условные знаки, чтение топографических карт",
        subtopics_lesson: [
            "Карта и определение расстояний по ней.",
            "Условные знаки местных предметов.",
            "Изображение местных предметов и рельефа на топографических картах."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Военная топография",
        topic_lesson: "Ориентирование и движение на местности по карте",
        subtopics_lesson: [
            "Способы ориентирования по карте.",
            "Определение на карте точки своего стояния.",
            "Ориентирование на местности по карте в движении"
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Медицинское обеспечение",
        topic_lesson: "Оказание первой помощи при ранениях и кровотечениях",
        subtopics_lesson: [
            "Общие меры профилактики.",
            "Состав походной аптечки для РГ Спин.",
            "Первая помощь при ранениях и кровотечениях.",
            "Первая помощь при ушибах, вывихах и переломах.",
            "Первая помощь при потере сознания, сотрясении мозга, легкой и тяжелой контузии, шоке.",
            "Тренировка в наложении кровоостанавливающего жгута.",
            "Тренировка в шинировании и наложении повязок на различные участки тела."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
    {
        start_lesson: "24.05.2021",
        time_start: "15:00",
        course_name: "Курс на патриотизм",
        topic_block_course: "Медицинское обеспечение",
        topic_lesson: "Оказание первой помощи при островозникших заболеваниях",
        subtopics_lesson: [
            "Первая помощь при ожогах и перегреве тела, поражении молнией и электрическим током.",
            "Первая помощь при охлаждении тела и болезнях, обусловленных географической средой.",
            "Первая помощь при отравлениях.",
            "Первая помощь при укусах ядовитых змей и насекомых."
        ],
        lesson_state: "not started",
        url_lesson: "qwertyuio",
    },
]