import React from "react";
import Content from "../../components/content";
import News from "../../components/news";
import ListMediaModule from "./listMediaModule";

export default function HomePage() {
    return (
        <>
            <Content />
            <ListMediaModule />
            <News />
        </>
    );
}
