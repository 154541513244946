import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import {ReactComponent as ExpandIcon} from "../../../../static/svg/expand_more.svg";
import CardFolder from '../../../common/cardFolder';
import {ReactComponent as DoneAnswerIcon} from "../../../../static/svg/done.svg";
import {ReactComponent as DelAnswerIcon} from "../../../../static/svg/del.svg";
import TextField from '../../../ui/TextField';
import HeaderModule from '../../../ui/HeaderModule';

const useStyles = makeStyles((theme) => ({
    module__content: {
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
    },
    content__header: {
        display: 'grid',
        gridGap: '20px',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr auto',
    },
    header__title: {
        fontSize: '1rem',
        fontFamily: 'Russo One, Open Sans',
    },
    rollButton: {

    },
    rollIcon: {
        transform: 'rotate(0deg)',
        transition: 'transform 0.6s',
    },
    rollIcon__close: {
        transform: 'rotate(180deg)',
    },
    content__textField: {
        overflow: 'hidden',
        maxHeight: '500px',
        transition: 'max-height 0.6s',
        //transition: 'height 0.6s, min-height 0.6s',
        //willChange: 'height',
    },
    content__textField_close: {
        animation: `$rollUp 300ms forwards`,
        //height: '35px',
        //maxHeight: '45px',

    },
    '@keyframes rollUp': {
        'from': {
            //height: '35px',
            maxHeight: '500px',
        },
        'to': {
            //height: '35px',
            maxHeight: '45px',
        }
    },
    textField: {
        display: 'grid',
        position: 'relative',
        margin: '15px 0px',
        alignItems: 'baseline',
        gridTemplateColumns: '1fr auto auto',

        '&:focus-within > label': {
            color: 'green',
            transform: 'translate(0px, -15px) scale(0.85)',
        },

        '& textarea': {
            '&:required ~ label': {
                color: 'green',
                transform: 'translate(0px, -15px) scale(0.85)',
            },
            '&:invalid ~ label': {
                color: '#00000078',
                transform: 'translate(0px, 5px) scale(1)',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:focus ~ label': {
                transform: 'translate(0px, -15px) scale(0.85)',
            },
        },
    },

    textField__textarea_close: {
        //height: '45px!important',
    },
    textField__textarea: {
        width: '100%',
        resize: 'none',
        border: 'none',
        padding: '10px',
        background: '#efefef',
        transition: 'height 0.6s',
        boxSizing: 'border-box',
        borderLeft: '1px dashed black',
        fontFamily: 'Play, Open Sans, sans-serif',
    },
    textField__label: {
        top: '0',
        zIndex: '1',
        display: 'block',
        color: '#00000078',
        position: 'absolute',
        padding: '0px 10px',
        padding: '0px 10px',
        pointerEvents: 'none',
        transformOrigin: 'top left',
        fontFamily: 'Open Sans, sans-serif',
        fontFamily: ' Open Sans, sans-serif',
        transform: 'translate(0px, 5px) scale(1)',
        transition: 'color 200ms, transform 200ms',
    },
    icon_fill: {
        fill: '#C5C5C5',
        '&:hover': {
            fill: '#787977'
        }
    },
}));

const AnswerOptions = ({id, moduleRollUp, item, addOptionsModule}) => {
    const classes = useStyles();
    const [roll, setRoll] = useState(false);
    //const [content, setContent] = useState({
    //    question: '',
    //    options: [],
    //});

    const rollUp = () => {
        setRoll((prev) => !prev);
    }

    const checkOption = (e) => {
        const idOption = e.currentTarget.getAttribute('data-value');
        //addOptionsModule(()=>{
        //    return{id: item.id, idOption: '', text: }
        //})
    }

    const aasd = (div) => {
        console.log('props', div);
        return (
            <div>ssdf</div>
        )
    }

    const handleOption = (e) => {
        const idOption = e.currentTarget.getAttribute('id');

        addOptionsModule(() => {
            return {id: item.id, idOption: idOption, text: e.target.value}
        })
    }

    useEffect(() => {
        if (moduleRollUp && moduleRollUp.roll) {
            setRoll(true);
        }
    }, [moduleRollUp])
    return (
        <>
            <CardFolder>
                <div className={classes.module__content} data-value={item.id}>
                    <HeaderModule
                        title='Вопрос'
                        numberElement={id + 1}
                        roll={roll}
                        rollUp={rollUp}
                    />
                    <div className={clsx(classes.content__textField, roll && classes.content__textField_close)}>
                        <TextField
                            area
                            id="area_question"
                            label='Введите вопрос'
                            required
                        />
                    </div>
                </div>
            </CardFolder>
            <CardFolder>
                <div className={classes.module__content}>
                    <HeaderModule
                        title='Ответы'
                        roll={roll}
                        rollUp={rollUp}
                    />
                    <div className={clsx(classes.content__textField, roll && classes.content__textField_close)}>
                        <div className={classes.textField}>
                            <TextareaAutosize
                                id='area__answer_1'
                                className={clsx(classes.textField__textarea)}
                                onChange={handleOption}
                                required
                            />
                            <label className={classes.textField__label} htmlFor='area_answer_1'>Ответ: 1</label>
                            <div><IconButton data-value='area_answer_1' className={classes.icon_fill} onClick={checkOption}><DoneAnswerIcon /></IconButton></div>
                            <div><IconButton data-value='' className={classes.icon_fill}><DelAnswerIcon /></IconButton></div>
                        </div>
                        <div className={classes.textField}>
                            <TextareaAutosize
                                id='area__answer_2'
                                className={clsx(classes.textField__textarea)}
                                onChange={handleOption}
                                required
                            />
                            <label className={classes.textField__label} htmlFor='area_answer_2'>Ответ: 2</label>
                            <div><IconButton data-value='area_answer_2' className={classes.icon_fill} onClick={checkOption}><DoneAnswerIcon /></IconButton></div>
                            <div><IconButton className={classes.icon_fill}><DelAnswerIcon /></IconButton></div>
                        </div>
                        <div className={classes.textField}>
                            <TextareaAutosize
                                id='area__answer_3'
                                className={clsx(classes.textField__textarea)}
                                onChange={handleOption}
                                required
                            />
                            <label className={classes.textField__label} htmlFor='area_answer_3'>Ответ: 3</label>
                            <div><IconButton data-value='area_answer_3' className={classes.icon_fill} onClick={checkOption}><DoneAnswerIcon /></IconButton></div>
                            <div><IconButton className={classes.icon_fill}><DelAnswerIcon /></IconButton></div>
                        </div>
                        <div className={classes.textField}>
                            <TextareaAutosize
                                id='area__answer_4'
                                className={clsx(classes.textField__textarea)}
                                onChange={handleOption}
                                required
                            />
                            <label className={classes.textField__label} htmlFor='area_answer_4'>Ответ: 4</label>
                            <div><IconButton data-value='area_answer_4' className={classes.icon_fill} onClick={checkOption}><DoneAnswerIcon /></IconButton></div>
                            <div><IconButton className={classes.icon_fill}><DelAnswerIcon /></IconButton></div>
                        </div>
                    </div>
                </div>
            </CardFolder >
        </>
    )
}

export default AnswerOptions;