import React from "react";
import BorderContent from "../../../components/common/borderContent";
import TasksItem from "../dashboard/tasks/tasksItem";
import {tasks_lesson} from "../api/data";
import {useAuth} from '../../../contexts/AuthContext';
import Grid from "@material-ui/core/Grid";

import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import {addTest} from "../../../adapters/getProfile";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    tasks: {
        padding: "20px"
    },
    tasks_content: {
        margin: "0px 20px 0px 20px"
    },
    tasks_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        // margin: "10px 0px 10px 20px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
}))

export default function TaskContent({testData, numberTest, data, finTest = "false"}) {
    const classes = useStyles();
    const {currentUser} = useAuth();
    const [value, setValue] = React.useState({});
    const [resultTest, setResultTest] = React.useState();
    const [resultTest2, setResultTest2] = React.useState();
    const [error, setError] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [error3, setError3] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [formLoading, setFormLoading] = React.useState(false)
    const [message, setMessage] = React.useState('');

    const handleRadioChange = (event) => {

        setValue({...value, [event.target.name]: event.target.value});
        setHelperText(' ');
        setError(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const result = [];
        const a = Object.entries(value);

        for (let i = 0; i < a.length; i++) {
            const n = a[i][1];
            const w = n.split(' ');
            result.push(w[1]);
        }
        setResultTest(result);
        if (result.length === testData.length) {
            setError2(false)
            const resultt = result.some(
                el => {

                    return true
                }
            )
            if (resultt) {
                setError3(false)
                setResultTest2(true)
                const nameT = `test${numberTest}`;
                addTest(currentUser.uid, "Курс на патриотизм", {tests: {...data[0].tests, [nameT]: value}}).then((msg) => {

                    setFormLoading(false);
                    setMessage(msg);
                })
                    .catch((error) => {
                        setFormLoading(false);
                        setMessage(error.message);
                    });
            } else {
                setError3(true)
            }

        } else {
            setError2(true)
        }


    };
    return (
        <BorderContent classGrid={classes.tasks} fullWidth>
            {finTest === 'true' ?
                <Grid item className={classes.tasks_subtopic}>Финальный тест</Grid>
                :
                <Grid item className={classes.tasks_subtopic}>Задания</Grid>
            }
            <form onSubmit={handleSubmit}>
                {
                    testData.map(el => {
                        return (
                            <FormControl key={el.nameQuestion} component="fieldset" error={error} className={classes.formControl} >
                                <FormLabel component="legend">{el.labelQuestion}</FormLabel>
                                <RadioGroup aria-label={el.nameQuestion} name={el.nameQuestion} onChange={handleRadioChange}>
                                    {
                                        el.questions.map((qu, id) => {
                                            return (
                                                <FormControlLabel key={qu + id} value={qu.id + " " + qu.answer} control={<Radio />} label={qu.label} />
                                            )
                                        })
                                    }
                                </RadioGroup>
                                <FormHelperText>{helperText}</FormHelperText>
                            </FormControl>
                        )
                    })
                }
                {error2 && <div>Не на все вопросы Вы ответили</div>}
                {error3 && <div> Есть ошибки</div>}
                {resultTest2 && <div> Тест пройден</div>}
                <br></br>
                <Button type="submit" variant="outlined" color="primary" className={classes.button}>
                    Отправить ответы
                </Button>
            </form>
        </BorderContent >
    )
}