import React from "react";
import ButtonComp from "../../components/common/button";
import AvatarProfile from "../../components/avatar";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import ChatIcon from '@material-ui/icons/Chat';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';

import clsx from "clsx";
import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    aside_icon: {
        marginBottom: "20px",
        color: "#99a63c"
    }
}));

export default function PanelListIcon({ img, menu, footer }) {
    const classes = useStyles();
    const { path } = useRouteMatch();
    return (
        <Grid item container justify="space-evenly" className={classes.aside_icon}>
            <Link to={`./notifications`}><IconButton><NotificationsIcon /></IconButton></Link>
            <Link to={`./settings`}><IconButton><SettingsIcon /></IconButton></Link>
            <Link to={`./chat`}><IconButton><ChatIcon /></IconButton></Link>
        </Grid>
    );
}
