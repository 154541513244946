export const dataTests = [
    {
        id: 0,
        question: 'Подача патронов при стрельбе производится из коробчатого магазина ёмкостью на:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '30 патронов',
                answer: true,
            },
            {
                id: 2,
                text: '35 патронов',
                answer: false,
            },
            {
                id: 3,
                text: '40 патронов',
                answer: false,
            },
            {
                id: 4,
                text: '44 патронов',
                answer: false,
            }
        ]
    },
    {
        id: 1,
        question: 'Сосредоточенный огонь из автомата по наземным целям ведется на дальности:',
        answer: 3,
        options: [
            {
                id: 1,
                text: 'до 1200 метров',
                answer: false,
            },
            {
                id: 2,
                text: 'до 1000 метров',
                answer: false,
            },
            {
                id: 3,
                text: 'до 800 метров',
                answer: true,
            },
            {
                id: 4,
                text: 'до 500 метров',
                answer: false,
            }
        ]
    },
    {
        id: 2,
        question: 'Прицельная дальность стрельбы:',
        answer: 3,
        options: [
            {
                id: 1,
                text: '1100 м',
                answer: false,
            },
            {
                id: 2,
                text: '900 м',
                answer: false,
            },
            {
                id: 3,
                text: '1000 м',
                answer: true,
            },
            {
                id: 4,
                text: '1300 м',
                answer: false,
            }
        ]
    },
    {
        id: 3,
        question: 'Дальность прямого выстрела по грудной фигуре:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '350 м',
                answer: true,
            },
            {
                id: 2,
                text: '380 м',
                answer: false,
            },
            {
                id: 3,
                text: '300 м',
                answer: false,
            },
            {
                id: 4,
                text: '400 м',
                answer: false,
            }
        ]
    },
    {
        id: 4,
        question: 'Дальность прямого выстрела по бегущей фигуре:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '525 м',
                answer: true,
            },
            {
                id: 2,
                text: '550 м',
                answer: false,
            },
            {
                id: 3,
                text: '600 м',
                answer: false,
            },
            {
                id: 4,
                text: '650 м',
                answer: false,
            }
        ]
    },
    {
        id: 5,
        question: 'Темп стрельбы из автомата составляет следующее количество выстрелов в минуту:',
        answer: 4,
        options: [
            {
                id: 1,
                text: '1000 выстрелов',
                answer: false,
            },
            {
                id: 2,
                text: '800 выстрелов',
                answer: false,
            },
            {
                id: 3,
                text: '900 выстрелов',
                answer: false,
            },
            {
                id: 4,
                text: '600 выстрелов',
                answer: true,
            }
        ]
    },
    {
        id: 6,
        question: 'Боевая скорострельность при стрельбе очередями:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '75 выстрелов в минуту',
                answer: false,
            },
            {
                id: 2,
                text: '100 выстрелов в минуту',
                answer: true,
            },
            {
                id: 3,
                text: '150 выстрелов в минуту',
                answer: false,
            },
            {
                id: 4,
                text: '200 выстрелов в минуту',
                answer: false,
            }
        ]
    },
    {
        id: 7,
        question: 'Боевая скорострельность при стрельбе одиночными выстрелами:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '40 выстрелов в минуту',
                answer: true,
            },
            {
                id: 2,
                text: '50 выстрелов в минуту',
                answer: false,
            },
            {
                id: 3,
                text: '60 выстрелов в минуту',
                answer: false,
            },
            {
                id: 4,
                text: '80 выстрелов в минуту',
                answer: false,
            }
        ]
    },
    {
        id: 8,
        question: 'Назначение ствола автомата АКМ:',
        answer: 1,
        options: [
            {
                id: 1,
                text: 'служит для направления полёта пули',
                answer: true,
            },
            {
                id: 2,
                text: 'для создания пули вращательного движения',
                answer: false,
            },
            {
                id: 3,
                text: 'для предания пули ускорения',
                answer: false,
            },
            {
                id: 4,
                text: 'для отвода лишних газов',
                answer: false,
            }
        ]
    },
    {
        id: 9,
        question: 'Назначение затвора:',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'для разбивания капсюля и открывания канала ствола',
                answer: false,
            },
            {
                id: 2,
                text: 'для досылания патрона в патронник, закрывания канала ствола, разбивания капсюля и извлечения гильзы',
                answer: true,
            },
            {
                id: 3,
                text: 'для выбрасывания гильзы из патронника',
                answer: false,
            },
            {
                id: 4,
                text: 'для отвода лишних газов',
                answer: false,
            }
        ]
    },
    {
        id: 10,
        question: 'Масса автомата со снаряжённым магазином:',
        answer: 4,
        options: [
            {
                id: 1,
                text: '5,5 кг',
                answer: false,
            },
            {
                id: 2,
                text: '5кг',
                answer: false,
            },
            {
                id: 3,
                text: '4,5 кг',
                answer: false,
            },
            {
                id: 4,
                text: '3,6 кг',
                answer: true,
            }
        ]
    },
    {
        id: 11,
        question: 'Дальность на которой сохраняется убойное действие пули:',
        answer: 1,
        options: [
            {
                id: 1,
                text: 'до 1500 м',
                answer: true,
            },
            {
                id: 2,
                text: 'до 2000 м',
                answer: false,
            },
            {
                id: 3,
                text: 'до 2200 м',
                answer: false,
            },
            {
                id: 4,
                text: 'до 2500 м',
                answer: false,
            }
        ]
    },
    {
        id: 12,
        question: 'Начальная скорость полёта пули:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '715 м/с',
                answer: true,
            },
            {
                id: 2,
                text: '815 м/с',
                answer: false,
            },
            {
                id: 3,
                text: '860 м/с',
                answer: false,
            },
            {
                id: 4,
                text: '900 м/с',
                answer: false,
            }
        ]
    },
]