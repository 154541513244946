import React, {useState} from 'react';
import {v4 as uuidv} from 'uuid';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {reorder, getItemStyle, getListStyle} from "./utils";
import CardModules from './cardModules';

const initialModule = {
    name: '', question: '', answer: '', options: [
        {
            id: '0', text: '',
        },
    ]
}

const initialState = {
    id: uuidv(),
    name: 'Добавить модуль',
    moduleList: [initialModule]
}



const initialState2 = {
    name: '4 варианта ответов',
    item: [
        {
            name: '4 варианта ответов', question: 'Какая погода', answer: '3', options: [
                {
                    id: 0, text: 'Плохая',
                    id: 1, text: 'Хорошая',
                    id: 2, text: 'Отличная',
                    id: 3, text: 'Дождливая',
                },
            ]
        },
        {
            name: '4 варианта ответов', question: 'Какой день недели', answer: '1', options: [
                {
                    id: 0, text: 'Понедельник',
                    id: 1, text: 'Вторник',
                    id: 2, text: 'Среда',
                    id: 3, text: 'Четверг',
                },
            ]
        }

    ]
}

const getItems = (count) =>
    Array.from({length: count}, (v, k) => k).map((k) => ({
        id: uuidv(),
        content: `item ${k}`
    }));

const ContentModules = () => {
    const [modules, setModules] = useState([initialState]);

    // ----------------------------------------------------------------
    // Функции добавления и удаления Карточек с Модулями

    const addCardModules = (e) => {
        const titleModule = e.target.innerText;
        const id = String(uuidv());
        const _modules = [...modules];
        const _moduleItem = {...initialModule, id: id, name: titleModule};
        const length = _modules.length - 1;
        _modules.splice(length, 1, {..._modules[length], name: titleModule, moduleList: [_moduleItem]}, {...initialState, id: String(uuidv())});
        setModules(_modules);
    }

    //const addCardModules = (e) => {
    //    const titleModule = e.target.innerText;
    //    const id = String(uuidv());
    //    const _modules = [...modules];
    //    const _moduleItem = {...initialModule, id: id, name: titleModule};
    //    _modules.splice(_modules.length - 1, 1, {...initialState, id: id, name: titleModule, moduleList: [_moduleItem]}, initialState);
    //    setModules(_modules);
    //}

    const removeCardModules = (e) => {
        const idModule = e.target.getAttribute('data-value')
        const result = modules.filter((el) => el.id !== idModule);
        setModules(result)
        //e.stopPropagation()
    }

    // ----------------------------------------------------------------
    // Функции добавления и удаления Модулей

    const addModule = (e) => {
        const idModule = e.currentTarget.getAttribute('data-value');
        const nameModule = e.currentTarget.getAttribute('data-name');
        const cardModule = modules.map((el) => {
            if (el.id === idModule) {
                return {...el, moduleList: [...el.moduleList, {...initialModule, id: String(uuidv()), name: nameModule}]}
            }
            return el;
        });
        setModules(cardModule);
    }

    const removeModule = (e) => {

    }

    // ----------------------------------------------------------------
    // Функции добавления и удаления Options/Answer Модуля

    const addOptionsModule = (idCardModule, callback) => {
        const module = callback();
        const _modules = modules.map((el) => {
            if (el.id === idCardModule) {
                const _moduleList = el.moduleList.map((item) => {
                    if (item.id === module.id) {
                        const idOption = item.options.findIndex((option) => option.id === module.idOption);
                        if (idOption >= 0) {
                            const _module = item.options.map((option) => {
                                if (option.id === module.idOption) {
                                    return {...option, text: module.text}
                                }
                                return option
                            })
                            return {...item, options: _module};
                        } else {
                            return {...item, options: [...item.options, {id: module.idOption, text: module.text}]}
                        }
                    }
                    return item;
                });

                return {...el, moduleList: _moduleList}
            }
            return el;
        });
        setModules(_modules)
    }


    // ----------------------------------------------------------------

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const _modules = reorder(
            modules,
            result.source.index,
            result.destination.index
        );
        setModules(_modules);
    };

    console.log(modules)
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="QUESTIONS">
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {modules.map((el, id) => {
                                    const firstModule = el.name === '' ? 'Добавить модуль' : el.name;
                                    return (
                                        <Draggable
                                            key={el.id}
                                            draggableId={String(el.id)}
                                            index={id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <CardModules
                                                        id={el.id}
                                                        name={firstModule}
                                                        modules={modules[id]}
                                                        addModule={addModule}
                                                        onClickItem={addCardModules}
                                                        addOptionsModule={addOptionsModule}
                                                        removeCardModules={removeCardModules}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </>
    )
}

export default ContentModules;


    //const onDragEnd = (result) => {
    //    // dropped outside the list
    //    if (!result.destination) {
    //        return;
    //    }

    //    if (result.type === "QUESTIONS") {
    //        const questions = Reorder(
    //            modules,
    //            result.source.index,
    //            result.destination.index
    //        );

    //        setModules(questions);
    //    } else {
    //        const answers = Reorder(
    //            modules[parseInt(result.type, 10)].answers,
    //            result.source.index,
    //            result.destination.index
    //        );

    //        const questions = JSON.parse(JSON.stringify(modules));

    //        questions[result.type].answers = answers;

    //        setModules(questions);
    //    }
    //}
