export const tasks_lesson = {
    start_lesson: "24.05.2021",
    time_start: "15:00",
    course_name: "Курс на патриотизм",
    topic_block_course: "Тактическая подготовка",
    topic_lesson: "Вооружение и техника",
    tasks: [
        {
            type_task: "тест",
            name_task: "Действия солдата в бою",
            progress: "60%",
            state_task: "work",
            url_taks: ""
        },
        {
            type_task: "тренажёр",
            name_task: "Состав и назначение родов войск",
            progress: "0%",
            state_task: "not started",
            url_taks: ""
        },
        {
            type_task: "тест",
            name_task: "Способы ведения и средства борьбы",
            progress: "100%",
            state_task: "finished",
            url_taks: ""
        }
    ],
    lesson_state: "not started",
    url_lesson: "qwertyuio",
}