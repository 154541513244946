import React from "react";
import ButtonComp from "./common/button";
import AsideFooter from "./asideFooter";
import { useAuth } from '../contexts/AuthContext';
import { ReactComponent as LogoIcon } from "../static/svg/logo.svg";
import { ReactComponent as Logo2Icon } from "../static/svg/logo2.svg";
import { ReactComponent as LogoUrfuIcon } from "../static/svg/logoUrfu.svg";
import { ReactComponent as LogoGruIcon } from "../static/svg/logo-gru.svg";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
import AsideNavigation from "./common/asideNavigation";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  aside: {
    flex: "0 1 280px",
    width: "280px",
    height: "100%",
    backgroundColor: "#F7F7F7",
    position: "fixed",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      flex: "0 1 100px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  aside__block: {
    padding: "30px 40px 30px 40px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 5px 30px 5px"
    }
    // textAlign: "center"
  },
  aside__block__item_padding: {
    padding: "40px 0px 0px 0px",
    textAlign: "center"
  },
  aside__partners: {
    fontSize: "1rem",
    fontFamily: "Open Sans"
  },
  contant__item: {
    paddingRight: "10px"
  },
  item__icon: {
    width: "50px",
    margin: "10px"
  },
  item__iconSocial: {
    width: "25px",
    margin: "10px"
  }
}));

export default function Aside({ }) {
  const classes = useStyles();
  const { signout, currentUser } = useAuth();
  const logoProfile = currentUser ? currentUser.photoURL : false
  return (
    <Grid item container className={classes.aside}>
      <Grid item xs className={classes.aside__block}>
        <AsideNavigation logoProfile={logoProfile} />
      </Grid>
    </Grid>
  );
}
