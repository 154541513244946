import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tests from './main';
import QuestoinItem from './question';
//import {dataTests} from './data';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    listQuestions__number: {
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 10px',
    },
}));

function getSteps() {
    //['', '', '', '', '', '', '', '', '', ''];
    return ['', '', '', '', '', '', '', ''];
}



export default function TestForm({dataTests}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [answers, setAnswers] = React.useState({});
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setAnswers({})

    };
    const answerQuestion = (e, id) => {
        if (answers[activeStep] === undefined) {
            setAnswers((prevState) => {return {...prevState, [activeStep]: {click: id, answer: dataTests[activeStep].answer}}})
        }
    }
    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 1:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 2:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 3:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 4:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 5:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 6:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 7:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            case 8:
                return <QuestoinItem data={dataTests[stepIndex]} answerQuestion={answerQuestion} answerState={answers[activeStep]} />;
            default:
                return 'Unknown stepIndex';
        }
    }

    const countingAnswers = () => {
        let result = {correct: 0, wrong: 0};
        for (let i = 0; i < steps.length; i++) {
            answers[i] !== undefined && answers[i].click === answers[i].answer ? result.correct += 1 : result.wrong += 1
        }
        return `Правильные ответы: ${result.correct} из ${steps.length}`;
    }

    return (
        <Tests answers={answers[activeStep]} handleReset={handleReset} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps}>
            <div className={classes.root}>
                {/*<Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel><div className={classes.listQuestions__number}>{label}</div></StepLabel>

                        </Step>
                    ))}
                </Stepper>*/}
                <>
                    {activeStep === steps.length ? (
                        <>
                            <Typography className={classes.instructions}>{countingAnswers()}</Typography>
                        </>
                    ) : (
                        <>
                            {getStepContent(activeStep)}
                        </>
                    )}
                </>
            </div>
        </Tests>
    );
}