import React from 'react';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        cursor: 'pointer',
        transition: 'transform 0.4s',
        '&:hover': {
            transform: 'translate3d(0px, -10px, 0px)',
        },
        '&:hover p': {
            color: '#7a8432'
        }
    },
    main__img: {
        width: '100%',
    },
    description: {
        display: 'grid',
        gridTemplateAreas: "'date duration''title title'",
    },
    description__date: {
        gridArea: 'date',
        fontSize: '0.8rem',
    },
    description__duration: {
        gridArea: 'duration',
        textAlign: 'right',
        fontSize: '0.8rem',
    },
    description__title: {
        gridArea: 'title',
        fontFamily: 'Play, Open Sans',
        padding: '10px 0px',
    },

}));

const CardMediaItem = ({img, date, time, title}) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <img src={img} className={classes.main__img} />
            <div className={classes.description}>
                <div className={classes.description__date}>{date}</div>
                <div className={classes.description__duration}>{time}</div>
                <p className={classes.description__title}>{title}</p>
            </div>

        </div>
    )
}

export default CardMediaItem;
