import React from "react";
import Title from "../../components/common/title";
import CardMediaItem from '../../components/ui/CardMediaItem/CardMediaItem';
import ButtonComp from "../../components/common/button";

import {dataMedia} from "../library/dataMedia";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  news__cards: {
    overflow: "auto",
    padding: "20px 0px 20px 0px",
    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#99a63c",
      height: "10px",
      borderRadius: "10px"
    }
  },
  news__button: {
    paddingTop: "35px",
    margin: "0 auto"
  },
  listItem: {
    width: '350px',
    paddingRight: '50px',
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    }
  }
}));

export default function ListMediaModule() {
  const classes = useStyles();
  const data = [...dataMedia].reverse();
  return (
    <Grid container style={{paddingTop: "70px"}}>
      <Title title="Новое в библиотеке" />
      <Grid item container wrap="nowrap" className={classes.news__cards}>
        {
          data.map(el => {
            return (
              <Link to={`/library/media/${el.id}`}>
                <div className={classes.listItem}>
                  <CardMediaItem
                    img={`static/img/library/${el.img}`}
                    date={el.date}
                    time={el.time}
                    title={el.title}
                    data-name="1"
                  />
                </div>
              </Link>
            )
          })
        }
      </Grid>
      <Grid item className={classes.news__button}>
        <ButtonComp link="library" title="Перейти в библиотеку" endIcon={<ArrowRightAltIcon />} />
      </Grid>
    </Grid>
  );
}
