import React from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    btn: {
        //backgroundColor: 'transparent',
        border: '0',
        position: 'relative',
        display: 'inline-block',
        outline: '0',
        background: '0 0',
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontFamily: 'Play, Open Sans, sans-serif',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
        },
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            transform: ' scaleX(0)',
            transformOrigin: 'bottom right',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            inset: '0 0 0 0',
            background: '#a7b354',
            transition: 'transform .3s ease',
        },
        '&:hover::before': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
        }
    },
    btn__text: {
        position: 'relative',
    },
    btn_active: {
        '&::before': {
            //transform: 'scaleX(1)',
        }
    }
}));

const Button = ({children, active, ...other}) => {
    const classes = useStyles();
    return (
        <button {...other} className={clsx(classes.btn, active && classes.btn_active)}><span className={classes.btn__text}>{children}</span></button>
    )
}

export default Button;
