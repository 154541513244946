import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    skillsText: {
        fontSize:"1rem",
        // border:"1px solid #bdbdbd",
        height: "100%",
        padding: "8px",
        boxSizing: "border-box",
        textAlign:"center"
    }

}))

export default function SkillCourseItem({text}){
    const classes = useStyles();
    return (
        <div className={classes.skillsText}>
            {text}
        </div>
    )
}