import React from 'react';
import BorderContent from "../../../../components/common/borderContent";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    lesson: {
        padding: "20px"
    },
    lesson_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        margin: "10px 0px 30px 0px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course__numberBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
        }
    },
    lesson_content: {
        // margin: "0px 20px 0px 20px"
    },
    lesson_topic: {
        marginTop: "20px",
        fontFamily: "Open Sans, Yeseva One",
        fontSize: "1.1rem"
    },
    lesson_url: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "1.4rem"
    },
    lesson_time: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "2rem",
        textTransform: "uppercase"
    },
    lesson_time2: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.5rem",
        margin: "40px 0px"
    }

}))

export default function CoursesList({data}) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.lesson_time}>
                Ваши курсы
                {/* {lesson.start_lesson} */}
            </div>
            <Grid container>
                <Grid item xs={12} sm={6} >
                    {data && data.length === 0 ? <div className={classes.lesson_time2}>У вас нет курсов </div> :
                        data && data.map(el => (

                            <BorderContent classGrid={classes.lesson}>
                                <Grid item xs={12} container className={classes.lesson_content}>
                                    <Grid item xs={12} className={classes.lesson_url}>{el.course}</Grid>
                                    <Grid item xs={12} className={classes.lesson_topic}>
                                        {el.link.id ? <a href={el.link.id} target="_blank">Перейти к уроку</a> : "Урок еще не начался"}

                                    </Grid>
                                </Grid>
                            </BorderContent>

                        ))}
                </Grid>
            </Grid>
            {/* <Grid item xs={12} className={classes.lesson_subtopic}> {lesson.topic_lesson} </Grid>
                    <Grid item xs={12} className={classes.lesson_url}>Занятие начнется через 2 часа</Grid> */}

        </>
    )
}