import React from "react";

import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  default: {
    "&:hover": {
      "&::before": {
        top: "0",
        width: "100%",
        height: "5px",
        content: "''",
        position: "absolute",
        borderTop: "1px solid #444",
        borderLeft: "1px solid #444",
        borderRight: "1px solid #444"
      },
      "&::after": {
        bottom: "0",
        width: "100%",
        height: "5px",
        content: "''",
        position: "absolute",
        borderLeft: "1px solid #444",
        borderRight: "1px solid #444",
        borderBottom: "1px solid #444"
      }
    }
  },
  default_visit: {
    "&::before": {
      left: "0",
      width: "7px",
      height: "100%",
      content: "''",
      position: "absolute",
      borderTop: "1px solid #030514",
      borderLeft: "1px solid #030514",
      borderBottom: "1px solid #030514"
    },
    "&::after": {
      right: "0",
      width: "7px",
      height: "100%",
      content: "''",
      position: "absolute",
      borderTop: "1px solid #030514",
      borderRight: "1px solid #030514",
      borderBottom: "1px solid #030514"
    },
    "&:hover": {}
  },
  signIn: {
    "&::before": {
      left: "0",
      width: "7px",
      height: "100%",
      content: "''",
      position: "absolute",
      borderTop: "1px solid #030514",
      borderLeft: "1px solid #030514",
      borderBottom: "1px solid #030514"
    },
    "&::after": {
      right: "0",
      width: "7px",
      height: "100%",
      content: "''",
      position: "absolute",
      borderTop: "1px solid #030514",
      borderRight: "1px solid #030514",
      borderBottom: "1px solid #030514"
    },
    "&:hover": {}
  }
}));

const StyledButton = withStyles({
  root: {
    fontFamily: "Open Sans",
    fontWeight: "100",
    margin: "2px",
    lineHeight: 1,
  },
  label: {
    textTransform: "none",
    padding: "0 20px 0 20px"
  },
  "@media (max-width: 960px)": {
    startIcon: {
      marginRight: 0
    }
  }
})(Button);

export default function ButtonComp({
  title,
  className,
  link,
  viewButton = "default_visit",
  onClick,
  ...other
}) {
  const classes = useStyles();
  const hidden = viewButton === "signIn" ? classes.hidden : undefined;
  if (link) {
    return (
      <Link to={link}>
        <StyledButton
          className={clsx(classes[viewButton], className)}
          onClick={onClick}
          {...other}
        >
          <span className={hidden}>{title}</span>
        </StyledButton>
      </Link>
    );
  }
  return (
    <StyledButton
      className={clsx(classes[viewButton], className)}
      onClick={onClick}
      {...other}
    >
      <span className={hidden}>{title}</span>
    </StyledButton>
  );
}
