import React from "react";
import ButtonComp from "./button";
import { ReactComponent as ContentBlockIcon } from "../../static/svg/content-block.svg";
import { ReactComponent as LogoGruIcon } from "../../static/svg/logo-gru.svg";
import { useAuth } from '../../contexts/AuthContext';

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "80%",
    alignContent: "start",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px 20px 0px",
    },
  },
  content__block: {
    backgroundColor: "#F7F7F7",
    padding: "0px 45px 0px 45px",
    position: "relative",
    "&::before": {
      left: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    "&::after": {
      right: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 25px 0px 25px"
    }
  },
  content__block_left: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_right: {
    textAlign: "center"
  },
  content__block_position: {
    // position: "absolute"
  },
  content__block_title: {
    fontFamily: "Yeseva One",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    }
  },
  content__block_subtitle: {
    fontFamily: "Open Sans",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem"
    }
  },
  content__block_padding: {
    padding: "0px 0px 20px 0px"
  },
  content__block_right_size: {
    maxWidth: "300px",
    minWidth: "200px"
  },
  content__block_size_big: {
    height: "65px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  content__block_size_small: {
    height: "35px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "15px"
    }
  },
  content__block__leftTop: {
    transform: "skewX(-50deg)",
    transformOrigin: "bottom"
  },
  block__leftTop_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  block__rightTop_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightTop: {
    transformOrigin: "bottom"
  },
  content__block__leftBottom: {
    // transform: "skewX(-50deg)",
    transformOrigin: "top",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__leftBottom_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightBottom: {
    transform: "skewX(-50deg)",
    transformOrigin: "top",
    zIndex: "-1",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__rightBottom_shadow: {
    position: "relative",
    height: "100%",
    background: "#F7F7F7",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content_fullWidth: {
    width: "100%",
    flexBasis: "100%",
    maxWidth: "100%",
  },
  content_list: {
    flexGrow: "0",
    maxWidth: "50%",
    flexBasis: "50%",
  }
}));

const styleSizeCorners = {
  big: "content__block_size_big",
  small: "content__block_size_small"
};

export default function CardMedia2({ children, imgUrl, sizeСorners = "big", fullWidth, link, titleButton = "Подробнее" }) {
  const { currentUser } = useAuth();
  const classes = useStyles();
  const styleCorners = styleSizeCorners[sizeСorners];
  return (
    <Grid item sm={12} lg={6} container className={clsx(classes.content, fullWidth ? classes.content_fullWidth : undefined)}>
      {/* <ContentBlockIcon className={classes.content__block_position} /> */}

      <Grid
        item
        className={clsx(classes.content__block__leftTop, classes[styleCorners])}
      >
        <div className={classes.block__leftTop_shadow}></div>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.content__block__rightTop,
          classes[styleCorners]
        )}
      >
        <div className={classes.block__rightTop_shadow}></div>
      </Grid>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classes.content__block}
      >
        <Grid item sm className={classes.content__block_left}>
          {children}
          <ButtonComp link={link} title={titleButton} endIcon={<ArrowRightAltIcon />} />
        </Grid>
        {imgUrl && (
          <Grid item sm className={classes.content__block_right}>
            <img
              src={imgUrl}
              alt={""}
              className={classes.content__block_right_size}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        className={clsx(
          classes.content__block__leftBottom,
          classes[styleCorners]
        )}
      >
        <div className={classes.block__leftBottom_shadow}></div>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.content__block__rightBottom,
          classes[styleCorners]
        )}
      >
        <div className={classes.block__rightBottom_shadow}></div>
      </Grid>
    </Grid>
  );
}
