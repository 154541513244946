import React from "react";
import ButtonComp from "../components/common/button";
import CardMediaNewsInfo from "../components/news/cardMediaNewsInfo";

import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TodayIcon from "@material-ui/icons/Today";

import {
    Link,
    Route,
    useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0px 0px 50px 0px"
    },
    card__img: {
        paddingRight: "20px",
        // width: "300px"
        // flexBasis: "300px",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "0px",
        }
    },
    card__text: {},
    card__img_size: {
        width: "100%"
    },
    card__info: {
        padding: "10px 0px 10px 0px",
        fontSize: "0.8rem"
    },
    card__info_style: {
        alignContent: "baseline"
    },
    card__text_title: {
        fontSize: "1.2rem",
        fontFamily: "Play",
        fontWeight: "bold",
        padding: "0px 0px 10px 0px"
    },
    card__text_preview: {
        fontSize: "1rem"
    },
    card__info_padding: {
        padding: "0px 0px 0px 5px"
    },
    card__info__icon: {
        fill: "rgba(153, 166, 60, 75%)",
        fontSize: "1rem"
    }
}));

export default function CardMediaNews({
    img,
    alt,
    ...other
}) {
    const classes = useStyles();
    return (
        <Grid item container xs={12} className={classes.card}>
            <Grid item sm={12} md={6} className={classes.card__img}>
                <img src={img} alt={alt} className={classes.card__img_size} />
            </Grid>
            <CardMediaNewsInfo {...other} />
            {/* <Grid><ButtonComp title="Подробнее" /></Grid> */}
        </Grid >
    );
}
