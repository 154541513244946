import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
    Link
} from "react-router-dom";
import CardMediaItem from '../../components/ui/CardMediaItem/CardMediaItem';

import {dataMedia} from './dataMedia';

const useStyles = makeStyles((theme) => ({
    list__media: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, auto))',
        gridGap: '20px',
        margin: '35px 0px',
    }
}));

const ListMedia = () => {
    const classes = useStyles();
    return (
        <div className={classes.list__media}>
            {
                dataMedia.map(el => {
                    return (
                        <Link to={`/library/media/${el.id}`}>
                            <CardMediaItem
                                img={`static/img/library/${el.img}`}
                                date={el.date}
                                time={el.time}
                                title={el.title}
                                data-name="1"
                            />
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default ListMedia;