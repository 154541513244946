import React, { useState } from "react";
import BorderContent from "./borderContent";
import ButtonComp from "./button";
import Title from "./title";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';



const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#bbc764",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);


const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        // padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        padding: "10px 10px 10px 0px"
    },
    form: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    button: {
        textAlign: "center"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }

    },
    form__left: {
        margin: "10px 0px 10px 0px",
        // paddingRight: "20px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        }
    },
    inputStyle_size: {
        minWidth: "150px",
    },
    form__consent: {
        marginTop: "20px"
    }

}))

export default function FeedBackForm({ title, titleButton, messageOff = false, ...other }) {
    const classes = useStyles();
    const [data, setData] = useState({ name: "", email: "", tel: "" });
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    return (
        <BorderContent {...other}>
            <Grid item container xs={12} className={classes.course_formBlock}>
                <Grid className={classes.course__title}>{title}</Grid>
                <form autoComplete="off" className={classes.form}>
                    <Grid item xs={12} container className={classes.form__left}>
                        <Grid item xs className={classes.inputStyle}>
                            <InputBaseCustom
                                className={classes.inputStyle_size}
                                placeholder="Имя"
                                type="text"
                                name="name"
                                value={data.name}
                                onChange={handleChange}
                                required
                            // inputProps={{ 'aria-label': 'naked' }}
                            />
                        </Grid>
                        <Grid item xs className={classes.inputStyle}>
                            <InputBaseCustom
                                className={classes.inputStyle_size}
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                                required
                            // inputProps={{ 'aria-label': 'naked' }}
                            />
                        </Grid>
                        <Grid item xs className={classes.inputStyle}>
                            <InputBaseCustom
                                className={classes.inputStyle_size}
                                placeholder="Телефон"
                                type="tel"
                                name="tel"
                                value={data.tel}
                                onChange={handleChange}
                            // inputProps={{ 'aria-label': 'naked' }}
                            />
                        </Grid>
                    </Grid>
                    {
                        messageOff ? null
                            :
                            <Grid item xs={12} className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.margin}
                                    placeholder="Сообщение"
                                    type="text"
                                    name="message"
                                    multiline
                                    rows={7}
                                    value={data.message}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                    }
                    <Grid item xs={12} className={classes.button}>
                        <ButtonComp type="submit" title={titleButton} />
                    </Grid>
                    <Grid item xs={12} className={classes.form__consent}>
                        Отправляя форму, я соглашаюсь с условиями политики обработки персональных данных
                        </Grid>
                </form>
            </Grid>
        </BorderContent>
    )
}