import React from 'react';
import {ReactComponent as CheckIcon} from "../../../static/svg/check_circle.svg";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        flex: "1 1 0",
        maxWidth: "1400px",
        padding: "60px 80px 30px 80px",
        // width: "70%",
        width: "calc(100% - 280px)",
        marginLeft: "280px",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "100px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "10px 25px 10px 25px",
            marginLeft: "0px"
        }
        // width: "700px"
    },
    content: {
        // height: "80%",
        alignContent: "start"
    },
    content__block: {
        width: '100%',
        backgroundColor: "#F7F7F7",
        padding: "0px 45px 0px 45px",
        position: "relative",
        "&::before": {
            left: "0",
            width: "8%",
            height: "100%",
            content: "''",
            position: "absolute",
            zIndex: "-1",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        },
        "&::after": {
            right: "0",
            width: "8%",
            height: "100%",
            content: "''",
            position: "absolute",
            zIndex: "-1",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 25px 0px 25px"
        }
    },
    content__block_left: {
        [theme.breakpoints.down("xs")]: {
            order: "2"
        }
    },
    content__block_right: {
        textAlign: "center"
    },
    content__block_position: {
        // position: "absolute"
    },
    content__block_title: {
        fontFamily: "Yeseva One",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.5rem"
        }
    },
    content__block_subtitle: {
        fontFamily: "Open Sans",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem"
        }
    },
    content__block_padding: {
        padding: "0px 0px 20px 0px"
    },
    content__block_right_size: {
        maxWidth: "300px",
        minWidth: "200px"
    },
    content__block_size: {
        height: "30px",
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            height: "25px"
        }
    },
    content__block__leftTop: {
        transform: "skewX(-50deg)",
        transformOrigin: "bottom"
    },
    block__leftTop_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            left: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    block__rightTop_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            right: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    content__block__rightTop: {
        transformOrigin: "bottom"
    },
    content__block__leftBottom: {
        // transform: "skewX(-50deg)",
        transformOrigin: "top",
        [theme.breakpoints.down("xs")]: {
            height: "35px"
        }
    },
    block__leftBottom_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            left: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    content__block__rightBottom: {
        transform: "skewX(-50deg)",
        transformOrigin: "top",
        zIndex: "-1",
        [theme.breakpoints.down("xs")]: {
            height: "35px"
        }
    },
    block__rightBottom_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            right: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    titlePage: {
        fontFamily: 'Yeseva One, Open Sans',
        fontSize: '2rem',
        margin: '0px 0px 20px 0px',
    },
    listQuestions: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1rem",
        display: 'flex',
        alignItems: 'center',
        margin: '0px 0px 20px 0px',
        fontSize: '0.8rem',
    },
    listQuestions__list: {
        display: 'flex',
        listStyleType: 'none',
    },
    listQuestions__number: {
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 10px',
    },
    question: {
        margin: "0px 0px 20px 0px"
    },
    questin__title: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.3rem",
        margin: "0px 0px 20px 0px"
    },
    questin__test: {
        fontFamily: "Open Sans",
    },
    answers: {

    },
    answers__title: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.3rem",
        margin: "0px 0px 20px 0px"
    },
    answers__list: {
        fontFamily: "Open Sans",
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '15px',
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: 'auto',
        }
    },
    answers__item: {
        cursor: "pointer",
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto auto',
        padding: '10px',
        border: '1px solid transparent',
        gridGap: '7px',
        '&:hover': {
            border: '1px solid #333333',
            borderRadius: '7px',
        }
    },
    answer__item__number: {
        //gridColumnStart: 1,
        //gridColumnEnd: 3,
        color: ' #211A0A',
        fontSize: '0.8rem',
    },
    answer__item__text: {
        fontFamily: 'Play, Open Sans'
    },
    answer__item__icon: {
        opacity: 0.4,
    },
    buttonNext: {
        cursor: "pointer",
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.2rem",
        textAlign: "center",
        margin: "20px 0px 0px 0px",
    },
    activeAnswere: {
        border: '1px solid #99a63c'
    }
}));

const QuestoinItem = ({data, answerQuestion, answerState}) => {
    const classes = useStyles();
    const {question, answer, options} = data;
    return (
        <>
            <div className={classes.question}>
                <div className={classes.questin__title}>Вопрос {data.id + 1}</div>
                <div className={classes.questin__test}>{question}</div>
            </div>
            <div className={classes.answers}>
                <div className={classes.answers__title}>Варианты ответов:</div>
                <div className={classes.answers__list}>
                    {options.map((el) => {
                        //const styleResult = answerState !== undefined ? el.id === answer ? 'green' : activeAnswer === el.id'red' : null;
                        let styleResult = '';
                        //if (answerState?.click !== answerState.answer) {
                        //    styleResult = 'red';
                        //}
                        styleResult = answerState?.click === el.id || el.id === answerState?.answer ? el.id === answerState.answer ? 'green' : 'red' : null;
                        const activeAnswerStyle = el.id === answerState?.answer && classes.activeAnswere;

                        return (
                            <div key={el.text} className={clsx(classes.answers__item, activeAnswerStyle)} onClick={(e) => answerQuestion(e, el.id)}>
                                <div className={classes.answer__item__number}>Вариант {el.id}</div>
                                <div className={classes.answer__item__icon}><CheckIcon style={{fill: `${styleResult}`}} /></div>
                                <div className={classes.answer__item__text}>{el.text}</div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default QuestoinItem;