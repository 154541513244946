import React from "react";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  card__content: {
    width: "300px"
  },
  content__block_title: {
    fontFamily: "Russo One",
    fontSize: "1.8rem",
    // margin: "10px 0px 10px 0px",
    minHeight: "70px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "1.4rem",
      height: "auto"
    }
  },
  content__block_subtitle: {
    fontFamily: "Open Sans",
    marginBottom: "10px",
    fontSize: "1rem",

  }
}));

export default function CardContent2({
  title,
  description,
  classTitle,
  classDescription,
  impotent
}) {
  const classes = useStyles();
  return (
    <>
      <div>{impotent}</div>
      <Typography
        variant="h4"
        className={clsx(classes.content__block_title, classTitle)}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        className={clsx(classes.content__block_subtitle, classDescription)}
      >
        {description}
      </Typography>
    </>
  );
}
