import React from 'react';
import clsx from "clsx";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useParams, useRouteMatch, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import YouTube from 'react-youtube';
import FolderIconTitle from '../../components/ui/FolderIconTitle';
import TestForm from './tests';
import {dataTests} from './tests/data';
import {dataTestsPKM} from './tests/dataPKM';
import {dataTestsSVD} from './tests/dataSVD';
import {dataTestsMakarov} from './tests/dataMakarov';

const _dataTests = [dataTests, false, dataTestsPKM, dataTests, false, false, dataTestsSVD];
//const _dataTests = [dataTests, false, dataTestsMakarov, dataTests, false, false, dataTestsSVD];

const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        margin: '35px 0px',
    },
    main__title: {
        fontSize: '1.5rem',
        fontFamily: 'Russo One, Open Sans',
        fontWeight: '100',
        marginBottom: '20px'
    },
    main__title2: {
        fontSize: '1rem',
        fontFamily: 'Russo One, Open Sans',
    },
    main__title3: {
        fontSize: '1rem',
        fontFamily: 'Russo One, Open Sans',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            color: 'green'
        }
    },
    main__title2_margin: {
        margin: '40px 0px',
        border: '1px solid #b1b1b1',
        padding: '10px',
        textTransform: 'uppercase',
        textAlign: 'center',
    },

    btn__back: {
        display: 'flex',
        alignItems: 'center',
        color: '#767676',
        cursor: 'pointer',
        marginBottom: '20px',
        fontSize: '0.8rem',
    },
    btn__text: {
        //fontSize: '1.2rem',
        fontFamily: 'Open Sans',
    }
}));

const MediaItem = ({data}) => {
    const classes = useStyles();
    const {url} = useParams();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [openTest, setOpenTest] = React.useState(false);
    const [finishVideo, setFinishVideo] = React.useState(false);

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTestOpen = () => {
        setOpen(false);
        setFinishVideo(true);
        setOpenTest(true)
    };

    const handleTestClose = () => {
        setOpenTest(false)
    };

    const handleBack = () => {
        history.goBack();
    };

    function get(url) {
        const isItem = (p) => p.id === +url;
        return data.find(isItem);
    }
    const item = get(url);

    if (!item) {
        return <div>Страница не найдена</div>;
    }

    const opts = {
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const isTest = _dataTests[item.id - 1];

    const checkElapsedTime = (e) => {
        console.log(e.target.playerInfo.playerState);
        if (isTest) {
            const duration = e.target.getDuration();
            const currentTime = e.target.getCurrentTime();
            if (currentTime / duration > 0.9) {
                setOpen(true);
                e.target.pauseVideo()
            }
        }
    };


    return (
        <>
            <div className={classes.main}>
                {
                    !openTest && (
                        <div onClick={handleBack} className={classes.btn__back}><ArrowBackIosNewIcon style={{fontSize: '0.8rem'}} /><span className={classes.btn__text}>Назад</span></div>
                    )
                }

                <h1 className={classes.main__title}>{item.title}</h1>
                {
                    openTest ?
                        <div>
                            <div onClick={handleTestClose} className={classes.btn__back}><ArrowBackIosNewIcon style={{fontSize: '0.8rem'}} /><span className={classes.btn__text}>Вернуться к видео</span></div>
                            <TestForm dataTests={isTest} />
                        </div>
                        :
                        (
                            <>

                                {
                                    isTest &&
                                    (<p className={classes.main__title2_margin}>
                                        <span className={classes.main__title3} onClick={handleTestOpen}>
                                            Перейти к тестированию
                                        </span>
                                    </p>)
                                }
                                <YouTube
                                    videoId={item.videoId}
                                    opts={opts}
                                    onPause={checkElapsedTime}
                                />

                            </>
                        )
                }

            </div>
            {/*<Dialog
                open={open}
                onClose={handleClose}
                keepMounted
            >
                <DialogTitle id="scroll-dialog-title">Перейти в тест?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Хотите пройти тест на тему: "{item.title}"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Нет
                    </Button>
                    <Button onClick={handleTestOpen} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>*/}
        </>
    )
}

export default MediaItem;
