import {db} from './firebase';
import firebase from 'firebase';

export const getUserProfile = (uid) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('userProfile')
            .doc(uid)
            .get()
            .then((snapshot) => {
                resolve(snapshot);
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};

export const updateProfile = (uid, data) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('userProfile')
            .doc(uid)
            .update(data)
            .then(() => {
                resolve('Profile updated successfully');
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};

export const enrollCourse = (uid, data) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('RegistrationForCourses')
            .add({
                id: uid,
                userProfileID: uid,
                course: data.course,
                createDate: firebase.firestore.FieldValue.serverTimestamp(),
                email: data.email,
                firstName: data.firstName,
                tel: data.tel,
                link: "",
                lessons: {
                    activeLessonNumber: 1,
                    activeHome: 1,
                    activeTest: 1,
                    finTest: false,
                    linkLesson: ""
                },
                tests: {}

            }).then((docRef) => {
                resolve(docRef.id);

            })
            .catch((error) => reject(error));
    });
    return promise;
};



export const getUserCourse = (uid) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('RegistrationForCourses').where("userProfileID", "==", uid)
            .get().then((querySnapshot) => {
                let result = [];
                querySnapshot.forEach((doc) => {
                    result.push(doc.data())
                });
                resolve(result)
            }).catch((error) => {
                reject(error);
            });
    });
    return promise;
};

export const getIDCourse = (uid, name) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('RegistrationForCourses').where("userProfileID", "==", uid).where("course", "==", name)
            .get().then((querySnapshot) => {
                let result = [];
                querySnapshot.forEach((doc) => {
                    result.push(doc.id)
                });
                resolve(result)
            }).catch((error) => {
                reject(error);
            });
    });
    return promise;
};

export const getAllCourse = (uid) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('RegistrationForCourses')
            .get().then((querySnapshot) => {
                let result = [];
                querySnapshot.forEach((doc) => {
                    result.push(doc.data())
                });
                resolve(result)
            }).catch((error) => {
                reject(error);
            });
    });
    return promise;
};

export const addLinkUserCourse = (uid, name, value) => {
    let promise = new Promise(function (resolve, reject) {
        getIDCourse(uid, name).then((snapshot) => {
            db.collection('RegistrationForCourses')
                .doc(snapshot[0])
                .update(value)
                .then(() => {
                    resolve('Profile updated successfully');
                })
                .catch((error) => {
                    reject(error);
                });
        });
    })

    return promise;
};

export const addTest = (uid, name, value) => {
    let promise = new Promise(function (resolve, reject) {
        getIDCourse(uid, name).then((snapshot) => {
            db.collection('RegistrationForCourses')
                .doc(snapshot[0])
                .update(value)
                .then(() => {
                    resolve('Profile updated successfully');
                })
                .catch((error) => {
                    reject(error);
                });
        });
    })
    return promise;
};

export const getAllUsers = () => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('userProfile')
            .get()
            .then((snapshot) => {
                resolve(snapshot);
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};

export const getStudent2 = (idCourse) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('attendance').where("idCourse", "==", idCourse)
            .get().then((querySnapshot) => {
                let result = [];
                querySnapshot.forEach((doc) => {
                    result.push(doc.data())
                });
                console.log(result)
                //querySnapshot.forEach((doc) => {
                //    result.push(doc.id)
                //});
                resolve(result)
            }).catch((error) => {
                reject(error);
            });
    });
    return promise;
};

export const getStudent = (idCourse) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('attendance').doc('RKxHkuTVpOtOUrbT5xiU')
            .get().then((doc) => {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                reject(error);
            });
    });
    return promise;
};

export const getLessons = () => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('timetableClasses')
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                });
                //console.log("Document data:", snapshot);
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};
export const getLesson = (idLesson) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('timetableClasses').doc(idLesson)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    //console.log("Document data:", doc.data());
                    resolve(doc.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};

export const setLessons = (numberLesson, id, date, status, titleLesson, urlLesson, material) => {
    let promise = new Promise(function (resolve, reject) {
        db.collection('timetableClasses')
            .doc(numberLesson)
            .set({
                id,
                date,
                material,
                status,
                titleLesson,
                urlLesson,
            })
            .then((data) => {
                console.log("Document data:", data);
            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
};
