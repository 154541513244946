import React from "react";
import CardMediaNews from "../../components/cardMediaNews2";
import Title from "../../components/common/title";
import NewsItem from "./newsItem";
import ButtonComp from "../../components/common/button";
import { dataNews } from "../../dataNews";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {
    Link,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    news__cards: {
        padding: "0px 0px 30px 0px",
        "&::-webkit-scrollbar": {
            height: "10px"
            // width: "30px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#99a63c",
            height: "10px",
            borderRadius: "10px"
        }
    },
    news__button: {
        paddingTop: "35px",
        margin: "0 auto"
    }
}));

{/* <div dangerouslySetInnerHTML={{ __html: `${title}` }}></div> */ }

function NewsList() {
    const classes = useStyles();
    return (
        <Grid container style={{ paddingTop: "20px" }}>
            <Title title="Новости" />
            <Grid item container wrap="wrap" className={classes.news__cards}>
                {dataNews.map((el, id) => (
                    <Link to={`news/${el.url}`}>
                        <CardMediaNews
                            img={el.img}
                            tag={el.tag}
                            views={el.views}
                            date={el.date}
                            title={el.title}
                            text={el.text}
                            key={id}
                        />
                    </Link>
                ))}
            </Grid>
        </Grid>
    )
}


export default function NewsPage() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={NewsList} />
            <Route path={`${path}/:url`}> <NewsItem data={dataNews} /></Route>
        </Switch>
    );
}