import React from 'react';
import BorderContent from "../../../../components/common/borderContent";


import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    lesson: {
        padding: "20px"
    },
    lesson_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        margin: "10px 0px 15px 0px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course__numberBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
        }
    },
    lesson_content: {
        // margin: "0px 20px 0px 20px"
    },
    lesson_topic: {
        fontFamily: "Open Sans, Yeseva One",
        fontSize: "1.1rem"
    },
    lesson_url: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "1rem"
    },
    lesson_time: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "1rem"
    },
    lesson_subtopic_content: {
        fontFamily: 'Open Sans',
        fontSize: "0.8rem",
        margin: "8px auto",
        padding: "7px",
        borderBottom: "1px solid black"
    },
    lesson_subtopic_title: {
        fontFamily: 'Open Sans',
        fontSize: "0.9rem",
        margin: "8px auto",
        padding: "7px",
        borderBottom: "3px solid rgb(153, 166, 60)",
    }

}))

export default function Lesson({lesson}) {
    const classes = useStyles();
    const {topic_block_course, topic_lesson, subtopics_lesson} = lesson;
    return (
        <>
            {/*<div className={classes.lesson_time}>
                Занятие сегодня
                {lesson.start_lesson}
            </div>*/}
            <BorderContent classGrid={classes.lesson}>
                <Grid item xs={12} container className={classes.lesson_content}>
                    <Grid item xs={12} className={classes.lesson_topic}>Блок: {topic_block_course}</Grid>
                    <Grid item xs={12} className={classes.lesson_subtopic}> {topic_lesson} </Grid>
                    <Grid item xs={12} className={classes.lesson_subtopic_title}> Описание занятия: </Grid>
                    {subtopics_lesson.map(el => {
                        return (
                            <Grid item xs={12} className={classes.lesson_subtopic_content}> {el} </Grid>
                        )
                    })}

                    {/*<Grid item xs={12} className={classes.lesson_url}>Занятие начнется через 2 часа</Grid>*/}
                </Grid>
            </BorderContent>
        </>
    )
}