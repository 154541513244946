import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import clsx from 'clsx';
import CardFolder from '../../../common/cardFolder';
import AnswersBlock from '../../../ui/AnswersBlock';
import TextField from '../../../ui/TextField';
import HeaderModule from '../../../ui/HeaderModule';
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal';
import {ReactComponent as DelAnswerIcon} from '../../../../static/svg/del.svg';
import {ReactComponent as DelAnswerIcon2} from '../../../../static/svg/delete_outline.svg';
import {ReactComponent as DelAnswerIcon3} from '../../../../static/svg/clear.svg';
import './style.css'

const useStyles = makeStyles((theme) => ({
    module__content: {
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
    },
    content__textField: {
        overflow: 'hidden',
        maxHeight: '500px',
        position: 'relative',
        transition: 'max-height 0.6s',
        //transition: 'height 0.6s, min-height 0.6s',
        //willChange: 'height',
    },
    content__textField_close: {
        animation: `$rollUp 300ms forwards`,
        //height: '35px',
        //maxHeight: '45px',

    },
    '@keyframes rollUp': {
        'from': {
            //height: '35px',
            maxHeight: '500px',
        },
        'to': {
            //height: '35px',
            maxHeight: '45px',
        }
    },
    textField: {
        display: 'grid',
        position: 'relative',
        margin: '15px 0px',
        alignItems: 'baseline',
        gridTemplateColumns: '1fr auto auto',

        '&:focus-within > label': {
            color: 'green',
            transform: 'translate(0px, -15px) scale(0.85)',
        },

        '& textarea': {
            '&:required ~ label': {
                color: 'green',
                transform: 'translate(0px, -15px) scale(0.85)',
            },
            '&:invalid ~ label': {
                color: '#00000078',
                transform: 'translate(0px, 5px) scale(1)',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:focus ~ label': {
                transform: 'translate(0px, -15px) scale(0.85)',
            },
        },
    },
    icon_fill: {
        fill: '#C5C5C5',
        '&:hover': {
            fill: '#787977'
        }
    },
    content__textField__block: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    left__block: {
        //border: '1px solid red'
    },
    right__block: {
        //border: '1px solid green'
    },
    findError: {
        lineHeight: '17px',
        '&:~ span': {
            color: 'red'
        }
    },
    error__element: {
        cursor: 'pointer',
        color: 'black',
        '&:hover': {
            color: '#0061ff',
            //textDecoration: 'underline',
            textDecoration: 'line-through',
        }
    },
    error__element__true: {
        background: '#CDDEB9',
        borderRadius: '2px',
    },
    error__element__false: {
        background: '#E5B0B0',
        borderRadius: '2px',
    },
    btn__main: {
        textAlign: 'right',
    },
    findError__main: {
        left: '0px',
        top: '0px',
        zIndex: '1',
        padding: '8px',
        fontSize: '0.9rem',
        margin: '15px 0px',
        color: 'transparent',
        position: 'absolute',
        border: '1px solid transparent',
        transition: 'opacity .3s ease',
        //border: '1px dashed #d87f21',
        fontFamily: 'Play, Open Sans, sans-serif',
        //boxShadow: '1px 1px 1px 4px #2828284d',

        '&::before': {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: '-1',
            content: "' '",
            margin: '5px',
            display: 'block',
            // inset: '0 0 0 0',
            position: 'absolute',
            background: '#EFEFEF',
            transformOrigin: 'bottom left',
            transition: 'transform .3s ease',
        },
    },
    findError__main_display: {
        opacity: '1',
        visibility: 'visible',
        color: 'black',
        transitionDelay: '30ms',
        transitionProperty: 'color',

        '& .active__span': {
            //color: '#0061ff',
            //background: '#c1d9ff',
            background: '#CDDEB9',
            borderRadius: '2px',
            textDecoration: 'line-through',
        },

        '&::before': {
            transform: 'scaleX(1)',
        },
    },
    findError__main_hidden: {
        visibility: 'hidden',
        color: 'transparent',
        transitionDelay: '30ms',
        opacity: '0',
        transitionProperty: 'visibility, color, opacity',
        '& .active__span': {
            color: 'transparent',
            transition: 'color 0.1s',
        },

        '&::before': {
            transform: 'scaleX(0)',
        },
    },
    listError: {
        display: 'flex',
        gridGap: '20px',
        fontFamily: 'Play, Open Sans, sans-serif',
        margin: '15px  0px',
    },
    itemError: {
        padding: '5px',
        border: '1px solid #a1a1a1',
        position: 'relative',
    },
    itemError__content: {
        padding: '0px 5px',
    },
    itemError__error: {
        padding: '0px 5px',
        margin: '0px 0px 0px 5px',
    },
    itemError__close: {
        cursor: 'pointer',
        position: 'absolute',
        top: '-10px',
        background: '#f7f7f7',
        right: '-13px',
        height: '24px',
        borderRadius: '50%',
        fill: '#a1a1a1',
        '&:hover': {
            boxShadow: '1px 1px 12px 1px rgb(138 138 138 / 37%)',
        }
    },
}));

const FindError = ({id, moduleRollUp, item, addOptionsModule}) => {
    const classes = useStyles();

    const [roll, setRoll] = useState(false);
    const [dataText, setDataText] = useState('');
    const [isDisplay, setIsDisplay] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [data, setData] = useState([]);

    const [dataSelect, setDataSelect] = useState([]);
    const [activeElement, setActiveElement] = useState();
    const [replacement, setReplacement] = useState();

    const rollUp = () => {
        setRoll((prev) => !prev);
    }

    const handleChange = (e) => {
        setDataText(e.target.value)
    }

    /* ---------------  Трансформировать текст в объект --------------- */
    useEffect(() => {
        const arr = dataText.split(' ');
        const arrContent = arr.map((el, id) => {
            return {id: id, content: el, error: ''}
        });
        setData(arrContent);
    }, [dataText]);

    const delItem = (e) => {
        const idElement = e.currentTarget.getAttribute('data-id');
        const _data = [...data];
        _data.splice(idElement, 1, {id: _data[idElement].id, content: _data[idElement].content, error: ''});
        setData(_data);
    };
    //console.log(window.devicePixelRatio);
    const analizData = useMemo(() => {
        console.log('render')
        const result = data.map((el, id) => {
            // const content = el.error ? el.error : el.content;
            const selectedItem = Boolean(el.error);
            return (
                <React.Fragment key={el.id}>
                    <span data-value={el.id} className={clsx(classes.error__element, selectedItem && 'active__span')}>{el.content}</span>
                    <span> </span>
                    <span data-value={el.id} className={clsx(classes.error__element, classes.error__element__false)}>{el.error ? el.error : ''}</span>
                    <span> </span>
                </React.Fragment>
            )
        })
        return result
    }, [data]);
    console.log(data)

    /* -------------  Выбор слова для замены на ошибку  --------------- */
    const selectItem = (e) => {
        let arr = [];
        const target = e.target;
        const result = {
            id: target.getAttribute('data-value'),
            content: target.innerText,
            error: '',
            target: e.target,
        }
        //const findElement = dataSelect.findIndex((el) => el.id === result.id);

        //target.classList.add('active__span');

        //if (findElement >= 0) {
        //    arr = dataSelect.map((el) => el.id === result.id ? {...el, [el.content]: result.content} : el);
        //    setDataSelect(arr)
        //} else {
        //    setDataSelect((prev) => [...prev, result])
        //}

        handleOpen();
        setActiveElement(result);
    }

    /* ------------- Методы модального окна START ------------- */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const changeActiveElement = (e) => {
        setReplacement(e.target.value)
    };
    const saveСhangesModal = (e) => {
        const _data = [...data];
        _data.splice(activeElement.id, 1, {id: +activeElement.id, content: activeElement.content, error: replacement});
        setData(_data);
        activeElement.target.classList.add('active__span');
        setActiveElement((prev) => ({...prev, error: replacement}));
        setReplacement('');
        handleClose()
    }
    /* ----------- Методы модального окна END --------------- */

    const toggleDisplay = () => {
        setIsDisplay((prev) => !prev);
    }

    useEffect(() => {
        if (moduleRollUp && moduleRollUp.roll) {
            setRoll(true);
        }
    }, [moduleRollUp])
    console.log('DataSelect', dataSelect)
    return (
        <>
            <CardFolder>
                <div className={classes.module__content} data-value={item.id}>
                    <HeaderModule
                        title='Вопрос'
                        numberElement={id + 1}
                        roll={roll}
                        rollUp={rollUp}
                    />
                    <div className={clsx(classes.content__textField, roll && classes.content__textField_close)}>
                        <TextField
                            area
                            id="area_question"
                            label='Введите вопрос'
                            onChange={handleChange}
                            required
                        />
                        <div className={clsx(classes.findError__main, isDisplay ? classes.findError__main_display : classes.findError__main_hidden)}>
                            <div className={classes.findError} onClick={selectItem}>{analizData} </div>
                        </div>
                        <div>
                            <div>Выделенные ошибки</div>
                            <TransitionGroup className={classes.listError}>
                                {
                                    data.map((el, id) => {
                                        if (el.error) {
                                            return (
                                                <CSSTransition
                                                    key={el.id}
                                                    timeout={500}
                                                    classNames="fade"
                                                >
                                                    <div className={classes.itemError}>
                                                        <span className={classes.itemError__content}>{el.content}</span>
                                                        🠒
                                                        <span className={classes.itemError__error}>{el.error}</span>
                                                        <span
                                                            className={classes.itemError__close}
                                                            data-id={id}
                                                            onClick={delItem}
                                                        >
                                                            <DelAnswerIcon />
                                                        </span>
                                                    </div>
                                                </CSSTransition>
                                            )
                                        } else {return null}
                                    })
                                }
                            </TransitionGroup>
                        </div>
                    </div>
                </div>
                <div className={classes.btn__main}>
                    <Button onClick={toggleDisplay} active={isDisplay}>{isDisplay ? 'Редактировать текст' : 'Выделить ошибки'}</Button>
                </div>
            </CardFolder>
            <Modal open={open} handleClose={handleClose}>
                <div>Заменить "{activeElement && activeElement.content}" на {activeElement && activeElement.error ? activeElement.error : replacement}</div>
                <TextField
                    id="area_question"
                    label='Заполните поле'
                    onChange={changeActiveElement}
                    required
                />
                <div className={classes.btn__main}>
                    <Button onClick={saveСhangesModal}>Сохранить ошибки</Button>
                </div>
            </Modal>
        </>
    )
}

export default FindError;
