import React from "react";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    infoBlock: {
        margin: "20px 0px 5px 0px"
    }
}))


export default function InfoBlock({ title, children }) {
    const classes = useStyles();
    return (
        <>
            <h3 className={classes.infoBlock}>{title}</h3>
            <p>{children}</p>
        </>
    )
}