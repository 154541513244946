import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "80%",
    alignContent: "start",
    zIndex: 1,
  },
  content__block: {
    padding: "0px 25px 0px 25px",
    position: "relative",
    width: "100%",
    "&::before": {
      left: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    "&::after": {
      right: "0",
      width: "8%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: "-1",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 15px 0px 15px"
    }
  },
  content__block_left: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_right: {
    textAlign: "center"
  },
  content__block_position: {
    // position: "absolute"
  },
  content__block_title: {
    fontFamily: "Yeseva One",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    }
  },
  content__block_subtitle: {
    fontFamily: "Open Sans",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem"
    }
  },
  content__block_padding: {
    padding: "0px 0px 20px 0px"
  },
  content__block_right_size: {
    maxWidth: "300px",
    minWidth: "200px"
  },
  content__block_size_big: {
    height: "65px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  content__block_size_small: {
    height: "35px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "15px"
    }
  },
  content__block__leftTop: {
    transform: "skewX(-50deg)",
    transformOrigin: "bottom"
  },
  block__leftTop_shadow: {
    position: "relative",
    height: "100%",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  block__rightTop_shadow: {
    position: "relative",
    height: "100%",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightTop: {
    transformOrigin: "bottom"
  },
  content__block__leftBottom: {
    // transform: "skewX(-50deg)",
    transformOrigin: "top",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__leftBottom_shadow: {
    position: "relative",
    height: "100%",
    "&::before": {
      left: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  content__block__rightBottom: {
    transform: "skewX(-50deg)",
    transformOrigin: "top",
    zIndex: "-1",
    [theme.breakpoints.down("xs")]: {
      height: "35px"
    }
  },
  block__rightBottom_shadow: {
    position: "relative",
    height: "100%",
    "&::before": {
      right: 0,
      width: "89%",
      height: "100%",
      content: "''",
      position: "absolute",
      //   boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
      zIndex: -1
    }
  },
  white: {
    backgroundColor: "#F7F7F7"
  },
  green: {
    backgroundColor: "#99a63c"
  },
  content_fullWidth: {
    maxWidth: "100%"
  },
  content_list: {
    // flexGrow: "0",
    // maxWidth: "50%",
    // flexBasis: "50%",
  }
}));

const styleSizeCorners = {
  big: "content__block_size_big",
  small: "content__block_size_small"
};

export default function BorderContent({ children, classGrid, classMain, fill = "white", sizeСorners = "small", fullWidth }) {
  const classes = useStyles();
  const styleCorners = styleSizeCorners[sizeСorners];
  return (
    <Grid item sm={12} lg={7} container className={clsx(classes.content, classGrid, fullWidth ? classes.content_fullWidth : classes.content_list)}>
      <Grid
        item
        className={clsx(classes.content__block__leftTop, classes[styleCorners], classes[fill])}
      >
        <div className={classes.block__leftTop_shadow}></div>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.content__block__rightTop,
          classes[styleCorners], classes[fill]
        )}
      >
        <div className={classes.block__rightTop_shadow}></div>
      </Grid>
      <Grid
        item
        container
        className={clsx(classes.content__block, classMain, classes[fill])}
      >
        {children}
      </Grid>

      <Grid
        item
        className={clsx(
          classes.content__block__leftBottom,
          classes[styleCorners], classes[fill]
        )}
      >
        <div className={classes.block__leftBottom_shadow}></div>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.content__block__rightBottom,
          classes[styleCorners], classes[fill]
        )}
      >
        <div className={classes.block__rightBottom_shadow}></div>
      </Grid>
    </Grid >
  );
}
