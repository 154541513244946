import React, {useState} from "react";
import BorderContent from "../components/common/borderContent";
import ButtonComp from "../components/common/button";
import {useAuth} from '../contexts/AuthContext';
import {enrollCourse} from '../adapters/getProfile';
import Auth from "../pages/auth/authPage";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import Title from "./common/title";
import RegisterCourseForm from "./registerCourseForm";
import {Link} from "react-router-dom";
import {useHistory} from 'react-router-dom';

const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#bbc764",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);


const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("sm")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        margin: "20px 0px 20px 0px"
    },
    form__size: {
        width: "100%",
    },
    button: {
        textAlign: "center"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }
    },
    offPublic: {
        color: 'white',
        fontSize: '1.3rem',
    }

}))

export default function RegisterCourse({price, courseName, publicCourse}) {
    const {currentUser} = useAuth();
    const classes = useStyles();
    const [formLoading, setFormLoading] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();

    const [data, setData] = useState({firstName: "", email: "", tel: "", courses: courseName});

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const handleProfileUpdate = (e) => {
        setFormLoading(true);
        e.preventDefault();
        enrollCourse(currentUser.uid, data).then((msg) => {

            setFormLoading(false);
            setMessage(msg);
            //history.push('/profile/dashboard');

        })
            .catch((error) => {
                setFormLoading(false);
                setMessage(error.message);
            });
    }
    return (
        <>
            <Title title="Запись на курс" />
            <BorderContent fullWidth fill="green">
                {
                    publicCourse === 'Открыта запись' ?
                        <>
                            <Grid item sm={12} md className={classes.course_priceBlock}>
                                <div className={classes.course_priceBlock__border}>
                                    <p>Стоимость курсов</p>
                                    <p className={classes.course_priceBlock_amount}>{price} ₽</p>
                                </div>
                            </Grid>
                            {
                                currentUser ?
                                    <RegisterCourseForm courseName={courseName} />
                                    :
                                    <GoAuth />
                            }
                        </>
                        : <div className={classes.offPublic}>На данный момент запись на курс не открыта. В разработке.</div>
                }


            </BorderContent>
        </>
    )
}

const GoAuth = (props) => {
    const classes = useStyles();
    return (
        <Grid item container sm={12} md className={classes.course_formBlock}>
            <Auth noRedirect />
        </Grid>
    )
}