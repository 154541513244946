export const dataNews = [
  {
    url: "news-1",
    img: "/static/img/news/6092100.jpg",
    alt: "Генерал-полковник Андрей Сердюков",
    tag: "Факты о ВДВ",
    views: "",
    date: "19/04/2021",
    title: "«В составе ВДВ появятся штурмовые соединения нового типа»",
    text:
      `Командующий Воздушно-десантными войсками генерал-полковник Андрей Сердюков — о реформах в «крылатой пехоте» и перспективном вооружении.`,
    description: `
      В российских ВДВ в последние годы проходят серьезные реформы. В будущем они должны составить основу войск быстрого реагирования, которые будут способны выполнить задачи в любом регионе мира. О подразделениях нового типа и их возможностях, о технике для «крылатой пехоты» и о парашютах «Юнкер-О» «Известиям» рассказал командующий ВДВ генерал-полковник Андрей Сердюков.
      <div class="news__item_h2">В зоне особого внимания</div>
      <p class="news__text">— В последние годы Воздушно-десантные войска активно реформируются. К чему в итоге должны прийти и какой круг задач смогут выполнять?</p>
      <p class="news__text">— Воздушно-десантные войска, как и все Вооруженные силы России, в последние годы прошли через ряд реформ, направленных на повышение их боевых возможностей. В подразделения поступают перспективные образцы техники, самые современные системы вооружения, а также АСУ, позволяющие командирам всех уровней оперативно принимать решения в любой обстановке.</p>
      <p class="news__text">В настоявшее время ВДВ состоят из командования, десантно-штурмовых и воздушно-десантных соединений и воинских частей, соединения специального назначения, «кузницы кадров» — Рязанского гвардейского высшего воздушно-десантного командного училища имени генерала армии В.Ф. Маргелова, а также отдельных воинских частей и специализированных образовательных учреждений, входящих в состав довузовских учебных заведений Минобороны.</p>
      <p class="news__text">Кроме того, в перспективе в составе ВДВ появятся десантно-штурмовые соединения нового типа, способные самостоятельно действовать в тылу противника во взаимодействии с воинскими частями армейской авиации. Это позволит оперативно решать задачи любой сложности, в том числе по обеспечению действий группировки войск.</p>
      <p class="news__text">На сегодняшний день целью развития ВДВ стало их совершенствование как основы сил быстрого реагирования с оптимальным боевым составом, оснащенным самым современным вооружением, военной и специальной техникой.</p>
      <p class="news__text">Воздушно-десантные войска должны иметь высокий боевой потенциал и мобильность, должны быть способны выполнять задачи как самостоятельно, так и в составе межвидовых группировок войск (сил) на стратегических и операционных направлениях.</p>
      <p class="news__text">— Какие задачи выполняли военнослужащие ВДВ на учении «Кавказ-2020»? Что за элементы и тактические приемы были отработаны в ходе маневров?</p>
     <p class="news__text"> — Соединения применялись в качестве оперативно-тактического и тактических воздушных десантов в глубине обороны противника и на флангах с целью наращивания усилий и обеспечения высоких темпов наступления группировки войск. Задачи по переносу усилий на угрожаемые направления решались мобильным резервом в составе усиленных десантно-штурмовых батальонов на вертолетах.</p>
      <p class="news__text">Кроме того, отрабатывались различные способы совместных действий с армейской авиацией, а также переброска артиллерии и боеприпасов в назначенный район.</p>
      <p class="news__text">Впервые было осуществлено десантирование парашютным способом парашютно-десантной роты на БМД-4М с применением современнейших средств, а также отдельного аэромобильного медицинского отряда. Кроме того, было проведено высотное десантирование военнослужащих со специальным снаряжением с высоты 8 тыс. метров.</p>
      <p class="news__text">Осуществлено десантирование экспериментальной управляемой парашютно-грузовой системы одного из предприятий-изготовителей.</p>
      <p class="news__text">— Как прошли совместные учения «Славянское братство – 2020» на территории Белоруссии? Какие задачи отрабатывали подразделения ВДВ? Появятся ли после них нововведения в программе боевой подготовки?</p>
      <p class="news__text">— С 14 по 25 сентября 2020 года на территории Республики Беларусь батальонные тактические группы из Псковского, Ивановского и Тульского гвардейских соединений приняли участие в плановом совместном белорусско-российском тактическом учении «Славянское братство – 2020». Оно проходило под руководством заместителя министра обороны Республики Беларусь генерал-майора Сергея Потапенко.</p>
      <p class="news__text">От ВДВ и ВКС России участвовали более тысячи военнослужащих, 26 единиц авиационной техники, свыше 200 единиц вооружений, военной и специальной техники.</p>
     <p class="news__text"> В ходе маневров было осуществлено наращивание группировки войск путем переброски комбинированным способом батальонных тактических групп с территории России. В частности, было проведено десантирование парашютно-десантного батальона с вооружением и техникой с аэродрома Иваново-Северный на полигон «Брестский». При этом в десантировании участвовали 20 самолетов Военно-транспортной авиации, 44 единицы техники и более 500 военнослужащих. Дальность десантирования составила 1,4 тыс. км.</p>
      <p class="news__text">Батальонные тактические группы соединений ВДВ с поставленными задачами справились в полном объеме. Личный состав получил практику в организации совместных действий по поиску и уничтожению диверсионно-разведывательных групп противника в назначенном районе. В ходе учений подразделения ВС РФ с поставленными учебно-боевыми задачами справились успешно.</p>
      <p class="news__text">ВДВ России, как основа сил быстрого реагирования, подтвердили свою способность в короткие сроки осуществлять перегруппировку и успешно выполнять поставленные задачи в кризисных районах.</p>
      <div class="news__item_h2">Плавающая и десантируемая</div>
      <p class="news__text">— Будет ли расширяться число частей и соединений ВДВ, которые получат на оснащение управляемые парашюты?</p>
      <p class="news__text">— Сегодня современные управляемые парашютные системы специального назначения поставляются во все соединения ВДВ. В первую очередь ими оснащаются подразделения специального назначения и разведывательные.</p>
      <p class="news__text">В соответствии с планом развития ВДВ, в этом году доля обеспеченности соединений и воинских частей современными управляемыми парашютными системами составила 100%. В войска поставлены комплекты для высотного десантирования с высоты до 10 тыс. метров «Юнкер-О», с использованием которых выполнено десантирование подразделения ВДВ на Земле Александры архипелага Земля Франца-Иосифа.</p>
      <p class="news__text">— Планируется ли расширять номенклатуру вооружения и бронетанковой техники ВДВ?</p>
      <p class="news__text">— Мы не стоим на месте. Расширение номенклатуры новых образцов бронетанкового вооружения и техники и оснащение ими частей и соединений ВДВ — безусловный приоритет. Но надо и понимать, что их разработка — это довольно длительный процесс. От подготовки, изготовления и испытаний до постановки на серийное производство может пройти несколько лет.</p>
      <p class="news__text">В 2020 году в войска поступило около 300 единиц современного бронетанкового вооружения, военной и специальной техники (ВВСТ). В том числе — боевые машины десанта БМД-4М, бронетранспортеры БТР-МДМ «Ракушка», более 100 единиц различной автомобильной техники.
      В войска приходят современные парашютные системы для личного состава Д-10 и «Арбалет-2», новейшие средства десантирования боевых машин и грузов, бесплатформенные системы ПБС-950У для БМД-4М.</p>
      <p class="news__text">Для увеличения боевого потенциала ВДВ разрабатываются перспективные образцы вооружения и техники и модернизируются уже имеющиеся по нескольким боевым свойствам: огневая мощь, защищенность, подвижность и командная управляемость.</p>
      <p class="news__text">Бронетанковая техника войск способна плавать и десантироваться и не имеет аналогов по своим характеристикам. Она в разы легче образцов техники сухопутных войск, но не уступает им в огневой мощи, а в скорости, маневренности и запасе хода во многом их превосходит. Эти же требования предъявляются и к перспективным образцам.</p>
      <p class="news__text">В настоящее время проводится серьезная работа по совершенствованию артиллерии ВДВ. Наши специалисты в тесном контакте с производителями проводят государственные испытания новой самоходной противотанковой пушки «Спрут-СДМ1».</p>
      <p class="news__text">— Какие выводы сделаны по итогам применения десантно-штурмовых подразделений на базе Ульяновской отдельной десантно-штурмовой бригады? Будут ли развиваться такого рода формирования?</p>
      <p class="news__text">— Соединения нового типа имеют возможность применяться в районах боевого предназначения и вести самостоятельные боевые действия в тылу противника, сочетая огонь наземного вооружения и армейской авиации. Применение подразделений на экспериментальных образцах легких специальных транспортных средств, перевозимых внутри грузовых кабин вертолетов, значительно повышает маневренные и огневые возможности техники.</p>
      <p class="news__text">С использованием вертолетов армейской авиации десантно-штурмовые подразделения могут эффективно противодействовать высокомобильным формированиям потенциального противника в различных физико-географических условиях и не зависеть от аэродромной сети. Применение армейской авиации значительно снижает вероятность поражения десанта огнем противника, повышает скорость перемещения, увеличивает дальность доставки в назначенный район.</p>
      <p class="news__text">Формирование соединений нового типа позволит повысить мобильность, а также увеличит боевой потенциал соединений ВДВ.</p>
      <p class="news__text">Уверен, что создание десантно-штурмовых соединений нового типа — давно назревшее веяние времени.</p>
      `
  },
  {
    url: "news-2",
    img: "/static/img/news/5083953.jpg",
    alt: "",
    tag: "Военное обозрение",
    views: "",
    date: "15/04/2021",
    title: "Артиллерийской установки «Лотос», разработанной для ВДВ. «Газета.Ru»",
    text:
      "Центральный научно-исследовательский институт точного машиностроения (ЦНИИточмаш) госкорпорации «Ростех» завершил приемо-сдаточные испытания перспективного самоходного артиллерийского орудия «Лотос» (индекс 2С42).",
    description: `
      Боевая машина для воздушно-десантных войск подтвердила соответствие заданным тактико-техническим характеристикам; работоспособность всех узлов, механизмов и приборов орудия отвечает требованиям заказчика, сообщили в госкорпорации.

      <p class="news__text">2С42 «Лотос» — российское авиадесантируемое плавающее самоходное артиллерийское орудие калибра 120 мм. Проект впервые был представлен на форуме «Армия-2017» в парке «Патриот.</p>
      <p class="news__text">Как пояснил «Газете.Ru» заместитель директора Центра анализа стратегий и технологий Константин Макиенко.</p>
      <p class="news__text">новое САО 2С42 для воздушно-десантных войск разрабатывается для замены советской 120-мм авиадесантной самоходной артиллерийско-минометной установки 2С9 «Нона-С». Для унификации с другой техникой ВДВ за основу было взято шасси авиадесантируемой БМД-4М «Садовница».</p>
      <p class="news__text">На 2С42 установлена автоматизированная башня со 120-мм орудием, которое совместимо с широкой номенклатурой 120-мм боеприпасов, в том числе перспективных. Экипаж состоит из четырех человек. САО 2С42 может десантироваться в полностью боеготовом состоянии с военно-транспортных самолетов типа Ил-76 с помощью десантных парашютных систем.</p>
      <p class="news__text">«В связи с необходимостью установки на 2С42 новых крупных агрегатов оригинальный корпус БМД-4М был удлинен при сохранении компоновки и уровня защиты. Общая масса САО 18 т. Все члены экипажа имеют собственные люки и смотровые приборы. Места командира и наводчика оборудованы новыми оптико-электронными прицельными устройствами. Двое членов экипажа размещаются в передней части корпуса, еще двое находятся в боевом отделении», — рассказал Макиенко.</p>
      <p class="news__text">В связи с удлинением корпуса и возросшей общей массой машины оригинальное шасси БМД-4М было увеличено до семи опорных катков — добавлены дополнительно две пары.</p>
      <p class="news__text">Шасси «Лотоса» основано на современных компонентах, что дает преимущества эксплуатационного характера. Новое шасси позволило получить существенный прирост ходовых характеристик.</p>
     <p class="news__text">Так, максимальная скорость САО 2С9 достигала 60 км/ч, тогда как скорость 2С42 на шоссе составляет почти 70 км/ч. Примечательно, что у «Лотоса» при этом удельная мощность ниже, чем у «Ноны-С» — 25 л. с./т против 30.</p>
      <p class="news__text">Запас хода рассчитан на 500 км.</p>
      <p class="news__text">Корпус БМД-4М имеет облегченное противопульное бронирование. Точные его параметры пока неизвестны. САО 2С42 сохраняет такую же защиту, а также получает башню с сопоставимой стойкостью. Таким образом, по уровню защиты от ствольных систем противника «Лотос», как минимум, не уступает находящейся на вооружении технике Воздушно-десантных войск.</p>
      <p class="news__text">Собственное бронирование новой самоходки имеет ограниченную стойкость, и потому живучесть на поле боя предлагается повышать за счет дополнительных средств. В этой связи «Лотос» комплектуется системой оптико-электронного противодействия кругового действия.</p>
      <p class="news__text">По периметру машины установлены датчики излучения систем наведения высокоточного оружия противника. При облучении автоматика разворачивает пусковые установки и отстреливает дымовые гранаты. Последние создают завесу, блокирующую инфракрасное и лазерное излучение. Важной особенностью такой защиты являются ее способность обнаружения излучения со всех направлений и всеракурсный отстрел гранат.</p>
      <p class="news__text">В 2С42 планируется установка нового универсального орудия, представляющего собой дальнейшее развитие 120-мм изделия 2А51 с большей длиной ствола и сочетающего в себе основные качества и возможности пушки, гаубицы и миномета.</p>
      <p class="news__text">Процесс подготовки к стрельбе максимально автоматизирован, что позволяет получить скорострельность до 6-8 выстрелов в минуту. Дальность стрельбы — до 13 км. Появление дополнительных объемов в боевом отделении позволило увеличить боекомплект.</p>
     <p class="news__text">САО «Нона-С» перевозит 20 снарядов, новый «Лотос» — почти вдвое больше.</p>
      <p class="news__text">В Воздушно-десантных войсках России в настоящее время, по некоторым данным, имеется около 750 САО 2С9 «Нона». Примерно треть этого количества находится на оснащении частей и соединений ВДВ. Остальные содержатся на базах хранения вооружения. Таким образом, для замены в полном объеме 2С9 требуется несколько сотен новых «Лотосов». На производство такого количества артиллерийских установок необходимо несколько лет. Эксперты полагают, что в этом случае перевооружение ВДВ продлится, как минимум, до середины 2020-х годов.</p>
      <p class="news__text">2С42 «Лотос» разрабатывается в тандеме с новой артиллерийской машиной управления огнем «Завет-Д», серийное производство обеих машин планируется начать в ближайшее время.</p>
      <p class="news__text">«Завет-Д» создан на базе БТР-МДМ «Ракушка». Разработка машины ведется с 2016 года. «Завет-Д» получил новейшее оборудование, позволяющее обнаруживать и опознавать различные цели, в том числе замаскированные. Предполагается также, что оборудование машины будет способно взаимодействовать с военными беспилотными летательными аппаратами различного назначения.</p>
      <p class="news__text">«Завет-Д» должен со временем заменить в воздушно-десантных войсках все устаревшие передвижные пункты разведки и управления огнем типа 1В119 «Реостат», находящиеся на вооружении уже 36 лет.</p>
      <p class="news__text">Кроме небольшой артиллерийской машины управления огнем «Завет-Д», в России разработали и более габаритный автоматизированный комплекс артиллерийской разведки 1Б75 «Пенициллин». Он определяет координаты цели в радиусе 25 км. </p>
       `
  },
  {
    url: "news-3",
    img: "/static/img/news/5234344.jpeg",
    alt: "",
    tag: "Мероприятия",
    views: "",
    date: "26/03/2021",
    title: "Занятие для курсантов РЦДППВ «Аванпост»",
    text:
      "Председатель Тюменского регионального отделения организации А.В. Поливанов провел занятие для курсантов Регионального центра допризывной подготовки и патриотического воспитания «Аванпост».",
    description: `
    <p class="news__text">В рамках заключенного 4 марта 2021 г. Соглашения о сотрудничестве между Свердловской областной общественной организацией «Союз ветеранов спецназа ГРУ» (сейчас – Межрегиональная общественная организация «Союз ветеранов ГРУ имени героя Российской Федерации Шектаева Д.А.») и Государственным автономным учреждением дополнительного образования Тюменской области «Региональный центр допризывной подготовки и патриотического воспитания «Аванпост».</p>

      <p class="news__text">Председатель Тюменского регионального отделения МОО «Союз ветеранов ГРУ имени героя Российской Федерации Шектаева Д.А.» А.В. Поливанов провел для курсантов специализированной группы добровольной подготовки к военной службе профориентационное занятие на тему: «Спецназ ГРУ – история, задачи, структура, штатное вооружение, критерии отбора».</p>
      <p class="news__text">Александр Владиславович также ответил на многочисленные вопросы ребят, ведь все они хоть сегодня готовы отправиться на службу в элитные подразделения спецназа!</p>
      `
  }
];
