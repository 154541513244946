import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import DropDownList from './dropDownList';
import ModuleList from './moduleList';
import {ReactComponent as AddModuleIcon} from "../../static/svg/addCircle.svg";

const useStyles = makeStyles((theme) => ({
    title__module__icon: {
        fontSize: '1.5rem',
        fontFamily: ' sans-serif',
        border: '1px solid transparent',
        transform: 'rotate(0deg)',
        transition: 'transform 0.4s, border 0.4s',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',

        '&:hover': {
            border: '1px solid black',
        }
    },
    title__module__content: {
        marginLeft: '10px',
    },
    title__module__icon_del: {
        transform: 'rotate(45deg)',
    },
    add__module__icon: {
        width: '100%',
        textAlign: 'center'
    },
    add__icon: {
        cursor: 'pointer',
        display: 'inline-block',
    }
}))

const CardModules = ({
    id,
    name,
    modules,
    addModule,
    onClickItem,
    removeCardModules,
    addOptionsModule,
    ...other
}) => {
    const classes = useStyles();
    const [modalModuleList, setModalModuleList] = useState(false);
    const [modulesRollUp, setModulesRollUp] = useState([]);

    const showModal = () => {
        setModalModuleList((prevState) => !prevState)
    }

    const onClickItemList = (e) => {
        setModalModuleList(false);
        onClickItem(e);
    }

    const _addModule = (e) => {
        setModulesRollUp((prev) => prev.map((el, id) => {
            return {...el, roll: true}
        }));
        addModule(e);
    }

    const _addOptionsModule = (callback) => {
        addOptionsModule(id, callback);
    }

    useEffect(() => {
        const arr = modules?.moduleList;
        const result = [];
        if (modulesRollUp.length === 0) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id) {
                    result.push({id: arr[i].id, roll: false})
                }
            }
            setModulesRollUp(result);
        } else {
            const moduleLast = arr[arr.length - 1];
            setModulesRollUp((prev) => [...prev, {id: moduleLast.id, roll: false}])
        }
    }, [modules?.moduleList.length])

    const delModule = modules.name !== 'Добавить модуль' ? true : false;

    return (
        <>
            <DropDownList isOpen={modalModuleList} showModal={showModal} onClickItem={onClickItemList} >
                <span
                    data-name={name}
                    data-value={id}
                    onClick={removeCardModules}
                    className={clsx(classes.title__module__icon, {[classes.title__module__icon_del]: delModule})}>
                    +
                </span>
                <span className={classes.title__module__content}>{name}</span>
            </DropDownList>
            <ModuleList moduleList={modules?.moduleList} modulesRollUp={modulesRollUp} addOptionsModule={_addOptionsModule} />
            {
                modules.name !== 'Добавить модуль' ?
                    <div className={classes.add__module__icon}>
                        <div
                            data-name={name}
                            data-value={id}
                            onClick={_addModule}
                            className={classes.add__icon}
                        ><AddModuleIcon /></div>
                    </div>
                    : null
            }
        </>
    )
}

export default CardModules;