import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import CardFolder from '../../../common/cardFolder';
import AnswersBlock from '../../../ui/AnswersBlock';
import TextField from '../../../ui/TextField';
import HeaderModule from '../../../ui/HeaderModule';

const useStyles = makeStyles((theme) => ({
    module__content: {
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
    },
    content__textField: {
        overflow: 'hidden',
        maxHeight: '500px',
        transition: 'max-height 0.6s',
        //transition: 'height 0.6s, min-height 0.6s',
        //willChange: 'height',
    },
    content__textField_close: {
        animation: `$rollUp 300ms forwards`,
        //height: '35px',
        //maxHeight: '45px',

    },
    '@keyframes rollUp': {
        'from': {
            //height: '35px',
            maxHeight: '500px',
        },
        'to': {
            //height: '35px',
            maxHeight: '45px',
        }
    },
    textField: {
        display: 'grid',
        position: 'relative',
        margin: '15px 0px',
        alignItems: 'baseline',
        gridTemplateColumns: '1fr auto auto',

        '&:focus-within > label': {
            color: 'green',
            transform: 'translate(0px, -15px) scale(0.85)',
        },

        '& textarea': {
            '&:required ~ label': {
                color: 'green',
                transform: 'translate(0px, -15px) scale(0.85)',
            },
            '&:invalid ~ label': {
                color: '#00000078',
                transform: 'translate(0px, 5px) scale(1)',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:focus ~ label': {
                transform: 'translate(0px, -15px) scale(0.85)',
            },
        },
    },

    textField__textarea_close: {
        //height: '45px!important',
    },
    textField__textarea: {
        width: '100%',
        resize: 'none',
        border: 'none',
        padding: '8px',
        fontSize: '0.8rem',
        background: '#efefef',
        transition: 'height 0.6s',
        boxSizing: 'border-box',
        borderLeft: '1px dashed black',
        fontFamily: 'Play, Open Sans, sans-serif',
    },
    textField__label: {
        top: '0',
        zIndex: '1',
        display: 'block',
        color: '#00000078',
        position: 'absolute',
        padding: '0px 10px',
        padding: '0px 10px',
        pointerEvents: 'none',
        transformOrigin: 'top left',
        fontFamily: 'Open Sans, sans-serif',
        fontFamily: ' Open Sans, sans-serif',
        transform: 'translate(0px, 5px) scale(1)',
        transition: 'color 200ms, transform 200ms',
    },
    icon_fill: {
        fill: '#C5C5C5',
        '&:hover': {
            fill: '#787977'
        }
    },
    content__textField__block: {
        //display: 'grid',
        //gridTemplateColumns: '1fr 1fr',
    },
    content__textField__item: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '10px',
        //margin: '15px 0px',
    },
    left__block: {
        //border: '1px solid red'
    },
    right__block: {
        //border: '1px solid green'
    },
    description__block: {
        fontSize: '0.7rem',
    },
}));

const Chronology = ({id, moduleRollUp, item, addOptionsModule}) => {
    const classes = useStyles();
    const [roll, setRoll] = useState(false);
    //const [content, setContent] = useState({
    //    question: '',
    //    options: [],
    //});

    const rollUp = () => {
        setRoll((prev) => !prev);
    }

    const checkOption = (e) => {
        const idOption = e.currentTarget.getAttribute('data-value');
        //addOptionsModule(()=>{
        //    return{id: item.id, idOption: '', text: }
        //})
    }

    const handleOption = (e) => {
        const idOption = e.currentTarget.getAttribute('id');

        addOptionsModule(() => {
            return {id: item.id, idOption: idOption, text: e.target.value}
        })
    }

    useEffect(() => {
        if (moduleRollUp && moduleRollUp.roll) {
            setRoll(true);
        }
    }, [moduleRollUp])
    return (
        <>
            <CardFolder>
                <div className={classes.module__content} data-value={item.id}>
                    <HeaderModule
                        title='Вопрос'
                        numberElement={id + 1}
                        roll={roll}
                        rollUp={rollUp}
                    />
                    <div className={clsx(classes.content__textField, roll && classes.content__textField_close)}>
                        <TextField
                            area
                            id="area_question"
                            label='Введите вопрос'
                            required
                        />
                    </div>
                </div>
            </CardFolder>
            <CardFolder>
                <div className={classes.module__content}>
                    <HeaderModule
                        title='Ответы'
                        roll={roll}
                        rollUp={rollUp}
                    />
                    <div className={clsx(classes.content__textField, roll && classes.content__textField_close)}>
                        <div className={classes.content__textField__block}>
                            <div className={classes.left__block}>
                                <span className={classes.description__block}>Заполните в правильном порядке</span>
                                {/*<AnswersBlock item={item} addOptionsModule={addOptionsModule} numberAnswers={4} area />*/}
                                <div className={classes.content__textField__item}>
                                    <TextField
                                        area
                                        id={`area__answer_1`}
                                        numberElement={1}
                                        onChange={handleOption}
                                        label='Ответ №'
                                        required
                                    />
                                    <TextField
                                        area
                                        id={`comments_1`}
                                        numberElement={1}
                                        onChange={handleOption}
                                        label='Добавить комментарий'
                                        required
                                    />
                                </div>
                                <div className={classes.content__textField__item}>
                                    <TextField
                                        area
                                        id={`area__answer_2`}
                                        numberElement={2}
                                        onChange={handleOption}
                                        label='Ответ №'
                                        required
                                    />
                                    <TextField
                                        area
                                        id={`comments_2`}
                                        numberElement={2}
                                        onChange={handleOption}
                                        label='Добавить комментарий'
                                        required
                                    />
                                </div>
                            </div>
                            <div className={classes.right__block}>
                                {/*<span>Комментарии</span>*/}
                                {/*<AnswersBlock item={item} addOptionsModule={addOptionsModule} numberAnswers={4} label='Комментарии' area />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </CardFolder >
        </>
    )
}

export default Chronology;
