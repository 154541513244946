import React, {useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {styled} from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './styles.css';

const useStylesModal = makeStyles((theme) => ({
    modal: {
        display: 'block',
        position: 'fixed',
        zIndex: '1300',
        top: '0',
        width: '100%',
        height: '100%',
        left: '0',
        //transitionProperty: 'display',
        //transitionDelay: '3s',
    },
    bg__modal: {
        background: '#ffffff2b',
        backdropFilter: 'blur(10px)',
        position: 'fixed',
        width: '100%',
        height: '100%',
        animation: '$to_come_in 0.4s forwards',
    },
    "@keyframes to_come_in": {
        from: {
            opacity: '0',
        },
        to: {
            opacity: '1',
        }
    },
    modal__block: {
        height: '100%',
        overflow: 'hidden auto',
        width: '100%',
        position: 'relative',
    },
    modal__close: {
        transition: 'visibility 1s 2s',
        //visibility: 'hidden',
        display: 'none',
    },
    modal__entry: {
        //visibility: 'visible',
        display: 'block',
    },
}));

const StyleDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        background: 'none',
        boxShadow: 'none',
        margin: '0',
        maxWidth: 'none',
        width: '100%',
    },
}));

const Modal = ({open, onClose, children}) => {
    const classes = useStylesModal();
    const refModal = useRef();
    const _onClose = (e) => {
        if (e.target.dataset.name) {
            onClose(e);
            refModal.current.classList.add('modal__out');
        }
    }
    const testClick = (e) => {
        console.log(e.target)

    }
    console.log('open', open)
    return (
        <>
            {
                open &&
                <div ref={refModal} className={clsx(classes.modal)}>
                    <div className={classes.bg__modal}></div>
                    <div data-name='modal' className={classes.modal__block} onClick={_onClose}>
                        {children}
                    </div>
                </div>
            }
        </>
    )
}


const ScrollDialog = ({openDialog, handleCloseDialog, children}) => {
    //const [open, setOpen] = React.useState(openDialog);
    const descriptionElementRef = React.useRef(null);

    const handleClickOpen = (scrollType) => () => {
        //handleCloseDialog(true);
    };

    const handleClose = (e) => {
        handleCloseDialog(e);
    };

    React.useEffect(() => {
        if (openDialog) {
            const body = document.body || document.getElementsByTagName('body')[0];
            //body.classList.add('scroll__off');
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog]);

    return (
        <Modal
            open={openDialog}
            onClose={handleClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            {children}
        </Modal>
    );
}

export default ScrollDialog;
