import React from "react";
import Main from "../../components/common/main";
import General from "./general";
import Policy from "./policy";
import ContractOffer from "./contractOffer";
import LearningPrograms from "./learningPrograms";

import {Link, Route, Switch} from "react-router-dom";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    officialInfo_main: {
        top: "260px",
        left: "0",
        zIndex: "2",
        width: "280px",
        height: "100%",
        position: "fixed",
        padding: "30px 0px 0px 30px",
        backgroundColor: "#F7F7F7",
        boxSizing: "border-box",
        backgroundColor: "#99a63c",
        color: "white",
        position: "absolute"

    },
    officialInfo_main__item: {
        position: "sticky",
        top: "20px",
        display: "flex",
        flexDirection: "column",
    }
}))

export default function OfficialInfoPage() {
    const classes = useStyles();
    return (
        <Main title="Правовая информация">
            <Switch>
                <Route path="/official-info" exact component={General} />
                <Route path="/official-info/general" exact component={General} />
                <Route path="/official-info/policy" component={Policy} />
                <Route path="/official-info/contract-offer" component={ContractOffer} />
                <Route path="/official-info/learning-programs" component={LearningPrograms} />
            </Switch>
            <div className={clsx(classes.officialInfo_main, "animation")}>
                <ul className={classes.officialInfo_main__item}>
                    <Link to="/official-info/general"><li>Пользовательское соглашение</li></Link>
                    <Link to="/official-info/policy"><li>Политика конфиденциальности</li></Link>
                    <Link to="/official-info/contract-offer"><li>Договор оферты</li></Link>
                    <Link to="/official-info/learning-programs"><li>Образовательные программы</li></Link>
                </ul>
            </div>
        </Main>
    )
}