import React from "react";
import Title from "../../components/common/title";
import { config } from "../../config";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';

const useStyles = makeStyles((theme) => ({
    contact__cards: {
        padding: "0px 0px 30px 0px",
        fontSize: "1.5rem"
    },
    contact__card__item: {
        marginBottom: "30px"
    },
    contact__icon: {
        marginRight: "20px"
    }
}));

export default function ContactPage() {
    const classes = useStyles();
    return (
        <Grid container style={{ paddingTop: "70px" }}>
            <Title title="Контакты" />
            <Grid item container className={classes.contact__cards}>
                <Grid item xs={12} container wrap="nowrap" alignItems="center" className={classes.contact__card__item}><span className={classes.contact__icon}><PhoneIcon fontSize="large" /></span> <a href="tel:{config.phone}">{config.phone}</a></Grid>
                <Grid item xs={12} container wrap="nowrap" alignItems="center" className={classes.contact__card__item}><span className={classes.contact__icon}><LocationOnIcon fontSize="large" /></span> {config.address}</Grid>
                <Grid item xs={12} container wrap="nowrap" alignItems="center" className={classes.contact__card__item}><span className={classes.contact__icon}><LocalPostOfficeIcon fontSize="large" /></span> <a href="mailto:{config.email}">{config.email}</a></Grid>
            </Grid>
        </Grid >
    );
}
