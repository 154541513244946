import React, {useState, useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import {useAuth} from '../../../contexts/AuthContext';
import {getUserCourse, getAllCourse} from '../../../adapters/getProfile';

import Dashboard from "../dashboard";
import Achievements from "../achievements";
import Progress from "../progress";
import Calendar from "../calendar";
import CoursesList from "../courses-list";
import Chat from "../chat";
import TasksPage from "../tasks";
import ProfileSetting from "../setting";
import ProfileNotifications from "../notifications";
import MyCourses from "../../teacher/myCourses";
import DashboardCourses from "../../teacher";

export default function RouterProfile() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const {currentUser} = useAuth();

    useEffect(() => {
        setLoading(true);
        getUserCourse(currentUser.uid).then((result) => {
            setData(result)
        }).catch((error) => {
            console.error(error);
        });
    }, [])
    return (
        <Switch>
            <Route path="/profile/dashboard" >
                <Dashboard data={data} />
            </Route>
            <Route path="/profile/tasks" >
                <TasksPage data={data} />
            </Route>
            <Route path="/profile/courses-list" component={CoursesList} />
            <Route path="/profile/calendar" component={Calendar} />
            <Route path="/profile/progress" component={Progress} />
            <Route path="/profile/achievements" component={Achievements} />
            <Route path="/profile/chat" component={Chat} />
            <Route path="/profile/settings" component={ProfileSetting} />
            <Route path="/profile/notifications" component={ProfileNotifications} />
            <Route path="/profile/courses" component={MyCourses} />
            <Route path="/profile/student-list" component={ProfileNotifications} />
            <Route path="/profile/dashboard-courses" component={DashboardCourses} />
        </Switch>
    );
}
