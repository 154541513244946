import React from "react";
import StudyPrograms from "../../components/studyPrograms";
import DescriptionСourses from "../specialties/descriptionСourses";
import data from "../../api/dataSpecialCourses.json";

import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

export default function CoursesPage() {
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={StudyPrograms} />
            <Route path={`${path}/:url`}> <DescriptionСourses data={data} /></Route>
        </Switch>
    );
}
