import React from "react";
import InfoBlock from "../../components/infoBlock";

import {makeStyles} from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    tableItem: {
        border: "1px solid grey"
    },
    tableItem__th: {
        textAlign: 'left',
        borderBottom: "1px solid grey",
        padding: "10px"
    },
    tableItem__td: {
        borderBottom: "1px solid grey",
        padding: "10px",
    }


}))

export default function General() {
    const classes = useStyles();
    return (
        <>
            <h1>Пользовательское соглашение</h1>
            <InfoBlock title="1. ОБЩИЕ ПОЛОЖЕНИЯ">
                <p>1.1. Межрегиональная общественная организация «Союз ветеранов спецназа ГРУ» имени Героя Д.А.Шектаева &#40;далее — Администратор&#41; предлагает пользователю сети Интернет &#40;далее — Пользователь&#41; использовать сервисы, доступные на сайте  https://uc-gru.ru &#40;далее — Сайт&#41; на условиях, изложенных в настоящем пользовательском соглашении &#40;далее — Соглашение&#41;, а также условиях использования отдельных сервисов Сайта.</p>
                <p>1.2. Предметом Соглашения является использование всех существующих на данный момент сервисов Администратора и других компаний, условия использования которых ссылаются на данное Соглашение, а также любое развитие и добавление новых сервисов. </p>
                <p>1.3. Пользователь принимает условия Соглашения в момент завершения регистрации на Сайте &#40;п. 3.2, п. 3.3 Соглашения&#41;. Регистрация Пользователя осуществляется в соответствии с разделом 3 Соглашения.</p>
                <p>1.4. В случае несогласия с условиями Соглашения полностью или в любой части Пользователь не должен регистрироваться на Сайте и обязан воздерживаться от использования любых сервисов.</p>
                <p>1.5. Актуальный текст Соглашения находится на странице по адресу: http://uc-gru.ru/official-info/general Администратор вправе в любое время изменять условия Соглашения. Новая редакция Соглашения действует с момента размещения в сети Интернет. Если после вступления в силу новой редакции Соглашения Пользователь продолжает использовать Сайт и любые его сервисы, считается, что Пользователь согласился с условиями новой редакции Соглашения.  При несогласии с условиями новой редакции Соглашения Пользователь обязуется прекратить использование Сайта.</p>
            </InfoBlock>
            <InfoBlock title="2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ">
                <p>Если из текста прямо не вытекает иное, термины, используемые в настоящем Соглашении и условиях использования отдельных сервисов, имеют следующие значения:</p>
                <table>
                    <tr>
                        <th className={classes.tableItem__th}>Сайт</th>
                        <td className={classes.tableItem__td}>Совокупность информации, текстов, графических элементов, дизайна, изображений, фото-, видеоматериалов и иных результатов интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет по сетевому адресу http://uc.gru.ru</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Сервис Сайта</th>
                        <td className={classes.tableItem__td}>Функциональные возможности и инструменты Сайта, обеспечивающие предоставление Пользователям доступа к услугам.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Онлайн-платформа</th>
                        <td className={classes.tableItem__td}>Программное обеспечение, представляющее собой набор взаимосвязанных веб-сервисов и модулей, составляющих единое пространство предоставления услуг потребителям в сети Интернет. Онлайн-платформа доступна на Сайте. </td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Личный кабинет</th>
                        <td className={classes.tableItem__td}>Совокупность защищенных страниц Сайта, созданных в результате регистрации Пользователя и доступных при вводе его аутентификационных данных &#40;адреса электронной почты и пароля&#41; в предусмотренные для этого поля на Сайте. Конкретный набор страниц, технические возможности и порядок их использования могут отличаться для различных категорий Пользователей: Школьника, Родителя, Учителя.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Обучающийся</th>
                        <td className={classes.tableItem__td}>Пользователь, зарегистрированный на Сайте и получивший доступ к Личному кабинету Обучающегося. Если Пользователь не является полностью дееспособным, регистрация осуществляется его законным представителем. Все действия, осуществляемые Обучаемым на Сайте, совершаются с согласия законного представителя. Если Пользователь является полностью дееспособным, регистрация и все действия, совершаемые на Сайте, осуществляются Обучаемым самостоятельно.</td>
                    </tr>
                    <tr>
                        <th className={classes.tableItem__th}>Преподаватель</th>
                        <td className={classes.tableItem__td}>Пользователь, зарегистрированный на Сайте и получивший доступ к Личному кабинету Преподавателя.</td>
                    </tr>
                </table>

            </InfoBlock>
            <InfoBlock title="3. РЕГИСТРАЦИЯ НА САЙТЕ">
                <p>3.1. Для регистрации на Сайте и создания Личного кабинета Пользователь предоставляет информацию о себе одним из следующих способов:</p>
                <p>3.1.1. Заполняет регистрационную форму, доступную по адресу https://uc-gru.ru/user/registration или на любой другой странице Сайта. </p>
                <p>3.1.2. Дает согласие на использование информации из своих аккаунтов социальных сетей.</p>
                <p>3.2. При регистрации на Сайте путем заполнения регистрационной формы Пользователь:</p>
                <p>3.2.1. Выбирает категорию Пользователя — Обучающийся, Преподаватель, Родитель.</p>
                <p>
                    3.2.2. Указывает в форме для регистрации фамилию, имя, номер телефона. Дополнительно в форме для регистрации Администратором может быть запрошен адрес электронной почты.
                    Заполняя форму, Пользователь гарантирует, что предоставляемая информация:
                    1 &rpar; принадлежит соответствующему Пользователю. В Личном кабинете Обучаемого также может быть указана информация законного представителя;
                    2 &rpar; является достоверной и актуальной в течение всего срока использования Сайта. Администратор не несет ответственность за невозможность использования Сайта, ошибки в выдаваемых документах и иные последствия, возникающие в результате невыполнения Пользователем обязательства по предоставлению достоверной и актуальной информации.
                    3 &rpar; является уникальной и ранее не использовалась для регистрации на Сайте.
                </p>
                <p>3.2.3. Проставляет символ в графе «Принимаю условия соглашения и политики конфиденциальности». </p>
                <p>3.2.4. Нажимает кнопку «Зарегистрироваться».</p>
                <p>3.2.5. В случае, если в форме для регистрации был запрошен адрес электронной почты, для завершения регистрации Пользователю необходимо осуществить переход по ссылке, содержащейся в сообщении, направленном с домена @uc.gru.ru на указанный Пользователем адрес электронной почты.
                    После совершения действий, указанных в п. 3.2, регистрация считается завершенной.
                </p>
                <p>3.3. При регистрации с использованием информации аккаунтов социальных сетей &#40;п. 3.1.2&#41; Пользователь выбирает социальную сеть, аккаунт которой будет использоваться для создания Личного кабинета на Сайте. Пользователь не вправе использовать для регистрации аутентификационные данные аккаунтов третьих лиц. После выбора Пользователем социальной сети, аккаунт которой будет использоваться для создания Личного кабинета, регистрация считается завершенной.  </p>
            </InfoBlock>
            <InfoBlock title="4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ЛИЧНОГО КАБИНЕТА">
                <p>4.1. Пользователю предоставляется доступ к Личному кабинету после ввода аутентификационных данных — адреса электронной почты и пароля, указанного в сообщении &#40;п. 3.2.5 Соглашения&#41;, либо выбора аккаунта социальной сети, которая будет использоваться для доступа к Личному кабинету. </p>
                <p>Пользователь может добавлять следующую информацию с использованием раздела «Персональная информация» Личного кабинета: </p>
                <ul type="square">
                    <li>фамилия и имя. Пользователь осознает и соглашается с тем, что Администратор вправе проводить опубликование и дальнейшее использование указанного Пользователем имени в комментариях и отзывах в Сервисах; в корпоративных блогах и медиа Администратора, а также на сторонних ресурсах. Если пользователь не настроил указанную информацию, Администратором будут использоваться имя и фамилия, указанные Пользователем в форме регистрации или аккаунте социальной сети &#40;в зависимости от того, каким способом осуществлялась регистрация&#41;;</li>
                    <li>дата рождения и класс &#40;для Пользователей, зарегистрированных в качестве Обучаемого&#41;; </li>
                    <li>адрес электронной почты; </li>
                    <li>номер телефона;</li>
                    <li>изображение для учетной записи &#40;аватар&#41;. Изображение учетной записи может сопровождать сообщения, отправляемые Пользователем в чатах, а также иные действия. В случае использования в качестве изображения учетной записи изображения Пользователя, Пользователь осознает и соглашается с тем, что Администратор вправе проводить опубликование и дальнейшее использование изображения Пользователя для использования в Сервисах Администратора; размещения изображения к отзывам; в корпоративных блогах и медиа Администратора, а также на сторонних ресурсах; </li>
                    <li>ссылки на аккаунты в социальных сетях; </li>
                    <li>образовательное учреждение;</li>
                    <li>домашний адрес.</li>
                </ul>
                <p>Пользователь вправе в любое время вносить изменения в указанную персональную информацию.</p>
                <p>Обработка персональных данных Пользователей Сайта осуществляется в соответствии с Политикой обработки персональных данных: http://uc-gru.ru/official-info/policy. </p>
                <p>4.3. Пользователь может в любое время вносить изменять пароль с использованием раздела «Смена пароля» Личного кабинета. </p>
                <p>4.4. Пользователь может изменять типы сообщений, направляемых Администратором, с использованием раздела «Настройка рассылок» Личного кабинета. </p>
                <p>4.5. После осуществления Пользователем входа в учетную запись, данные учетной записи могут быть автоматически сохранены в браузере устройства до завершения Пользователем работы под своей учетной записью и не требовать дополнительного ввода средств доступа к учетной записи при каждом использовании Сервисов Администратора. Автоматически сохраненные в браузере устройства данные учетной записи Пользователя могут быть использованы для доступа к Сервисам Администратора. </p>
                <p>4.6. Пользователь самостоятельно обеспечивает безопасность и сохранность аутентификационных данных, устройств и аккаунтов социальных сетей, адресов электронных почт, которые используются для доступа к Личному кабинету.  </p>
                <p>4.6.1. После завершения каждой сессии использования Сайта Пользователь осуществляет выход из Личного кабинета &#40;кнопка «Выйти»&#41;.</p>
                <p>4.6.2. Пользователь не вправе предоставлять свои аутентификационные данные любым третьим лицам без письменного согласия Администратора. В случае выявления факта использования идентичных аутентификационных данных разными лицами Администратор оставляет за собой право прекратить доступ к Сайту и его Сервисам Пользователю, чьими аутентификационными данными воспользовалось третье лицо, а также применить иные меры, предусмотренные настоящим Соглашением или условиями Сервиса.</p>
                <p>4.6.3. В случае получения третьими лицами доступа к Личному кабинету Пользователь обязан незамедлительно сообщить об этом Администратору на адрес электронной почты ask@uc.gru.ru. До момента поступления такого сообщения все действия, совершенные с использованием Личного кабинета Пользователя, будут считаться совершенными самим Пользователем.</p>
            </InfoBlock>
            <InfoBlock title="6. ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ СЕРВИСОВ САЙТА">
                <p>6.1. Пользователь использует Сервисы Сайта на свой риск. Сервисы предоставляются «как есть». Администратор не гарантирует, что Сайт соответствует целям и требованиям Пользователя, что доступ к Сайту будет предоставляться непрерывно, быстро, надежно и без ошибок.</p>
                <p>6.2 Использование отдельных Сервисов Сайта может быть доступно только для определенных категорий Пользователей &#40;раздел 4 Соглашения&#41;. Указанная информация размещается на Сайте.</p>
                <p>6.3. Для использования Сайта должны быть соблюдены следующие технические требования:</p>
                <p>Для компьютеров под управлением операционных систем Windows:</p>
                <ul type="square">
                    <li>Процессор 2,4 ГГц Intel® Pentium®, Celeron®, Core2 Duo или более производительный &#40;или аналогичный&#41; для Microsoft® Windows® 7, 8, 8.1, 10 &#40;32/64-разрядные версии&#41;.</li>
                    <li>2,0 Гб ОЗУ, не менее 10 Гб свободного места на системном диске.</li>
                    <li>Браузер: Google Chrome версии 58.0 или выше, Microsoft Internet Explorer 11 или выше; Mozilla Firefox 56 или выше.</li>
                    <li>Последняя версия плеера Adobe® Flash® для всех пользователей &#40;ведущих, докладчиков, участников и администраторов&#41;.</li>
                    <li>Порты 1935, 443 и 80 должны быть открыты.</li>
                    <li>Скорость интернет-соединения не менее 1024 Кбит/с</li>
                </ul>
                <p>Для компьютеров под управлением операционной системы macOS:</p>
                <ul type="square">
                    <li>Процессор Intel CoreTM Duo 1,83 ГГц или более производительный.</li>
                    <li>ОС Mac OS X v10.4, 10.5, 10.6 &#40;Intel&#41; или выше.</li>
                    <li>ОЗУ 1 ГБ &#40;рекомендуется 2 ГБ&#41;.</li>
                    <li>Браузер: Apple Safari 10 или выше; Mozilla Firefox 9 или выше; Google Chrome 58 или выше.</li>
                    <li>Скорость интернет-соединения не менее 1024 Кбит/с</li>
                </ul>
                <p>6.4. Доступ к отдельным Сервисам Сайта может быть платным. В этом случае стоимость указывается на странице Сайта, содержащей описание соответствующего Сервиса. Определение стоимости использования Сервисов, а также порядок ее оплаты устанавливаются в соответствии с разделом 8 настоящего Соглашения.</p>
                <p>6.5. При использовании Сайта Пользователю запрещается:</p>
                <p>6.5.1. Копировать и/или распространять какую-либо информацию, полученную на Сайте, кроме случаев, когда такая функция прямо предусмотрена на Сайте и закреплена в условиях использования соответствующего Сервиса Сайта;</p>
                <p>6.5.2. Использовать информацию, полученную на Сайте, для осуществления коммерческой деятельности, извлечения прибыли либо для использования противоречащим закону способом;</p>
                <p>6.5.3. Копировать или иным способом использовать программную часть Сайта, а также его дизайн;</p>
                <p>6.5.4. Размещать на Сайте любые персональные данные третьих лиц без их согласия, в том числе домашние адреса, телефоны, паспортные данные, адреса электронной почты;</p>
                <p>6.5.5. Размещать на Сайте коммерческую рекламу, коммерческие предложения, агитационную информацию и любую другую информацию кроме случаев, когда размещение такой информации согласовано с Администратором;</p>
                <p>6.5.6. Изменять каким бы то ни было способом программную часть Сайта; совершать действия, направленные на изменение функционирования и работоспособности Сайта;</p>
                <p>6.5.7. Употреблять бранные слова и выражения при использовании чата Сайта и интерактивного голосового общения; оскорблять и иным образом нарушать права и свободы других пользователей Сайта, третьих лиц, а также групп лиц. </p>
                <p>6.6. Сайт может содержать ссылки на другие сайты в сети Интернет &#40;сайты третьих лиц&#41;. Если из содержания страницы Сайта не следует иное, Администратор:</p>
                <p>6.6.1. Не проверяет сайты третьих лиц и их контент на соответствие тем или иным требованиям &#40;достоверности, полноты, законности и т.п.&#41;.</p>
                <p>6.6.2. Не несет ответственности за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием Сайта, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу, а также за доступность таких сайтов или контента и последствия их использования Пользователем.</p>
                <p>6.7. При использовании Сайта и его Сервисов Администратор вправе направлять Пользователю:</p>
                <p>6.7.1. Транзакционно-триггерные сообщения.</p>
                <p>Такие сообщения:</p>
                <p>- подтверждают факт использования Пользователем Сервисов Сайта &#40;регистрацию на Сайте, запись на Курс, запись на Открытое занятие и т.д.&#41;</p>
                <p>- предоставляют Пользователю информацию, имеющую значение для эффективного использования Сервиса Сайта &#40;уведомление о начале Курса, Занятия, Открытого занятия, Конкурса и т.д.&#41;</p>
                <p>Частота направления сообщений зависит от того, насколько активно Пользователь использует Сервисы Сайта. Администратор направляет сообщения указанной категории в течение всего времени, пока Пользователь совершает активные действия на Сайте с использованием Личного кабинета.</p>
                <p>6.7.2. Информационные, новостные и маркетинговые сообщения.</p>
                <p>Такие сообщения:</p>
                <p>- информируют Пользователя о мероприятиях, проводимых Администратором или его партнерами;</p>
                <p>- информируют Пользователя о действующих скидках и акциях Администратора;</p>
                <p>- информируют Пользователя о существующих Сервисах Сайта.</p>
                <p>В течение суток Администратор направляет Пользователю не более 1 сообщения указанной категории.</p>
                <p>6.8. Пользователь вправе в любое время отказаться от получения сообщений указанной категории. Для этого Пользователь может:</p>
                <p>- перейти по ссылке «Отписаться от рассылки», размещенной в нижней части сообщения;</p>
                <p>- самостоятельно изменить настройки в разделе «Настройка рассылок» в Личном кабинете;</p>
                <p>- обратиться в службу технической поддержки по электронному адресу: ask@uc.gru.ru.</p>
            </InfoBlock>
            <InfoBlock title="7. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ">
                <p>7.1. Используя Сервисы Сайта, Пользователь признает и соглашается с тем, что все содержимое Сайта и структура содержимого Сайта являются защищаемыми результатами интеллектуальной деятельности, права на которые принадлежат Администратору.</p>
                <p>7.2. В результате использования Сервисов Сайта к Пользователю не переходят никакие права на любое содержимое Сайта, включая аудиовизуальные произведения, конспекты занятий, изображения, литературные произведения, учебные и методические материалы, товарные знаки.</p>
                <p>7.3. Пользователю запрещается копировать, модифицировать, изменять, удалять, дополнять, публиковать, передавать содержащиеся на Сайте результаты интеллектуальной деятельности; создавать производные работы, изготавливать или продавать продукты на их основе, воспроизводить, отображать или любым другим образом использовать такие права без прямого письменного разрешения Администратора.</p>
                <p>7.4. При цитировании материалов Сайта, включая контент, содержащийся в Занятиях и Курсах, Пользователь обязуется указывать ссылку на Сайт.</p>
            </InfoBlock>
            {/* <InfoBlock title="8. ПЛАТА ЗА ИСПОЛЬЗОВАНИЕ СЕРВИСОВ">
                <p>8.1. Администратор вправе устанавливать плату за использование Сервисов Сайта. В этом случае стоимость использования Сервиса, а также порядок оплаты указываются в описании Сервиса на странице Сайта.</p>
                <p>8.2. Стоимость использования Сервисов Сайта зависит от объема услуг, оказываемых посредством Сервисов, и может определяться на основании тарифов. Информация об условиях тарифов указывается в описании Сервисов на странице Сайта.</p>
                <p>8.3. Стоимость использования Сервисов может изменяться по решению Администратора. Такое изменение не влияет на определение стоимости для Пользователей, осуществивших оплату в полном объеме по прежней цене. О соответствующем изменении Администратор уведомляет Пользователей путем указания новой стоимости использования Сервиса на соответствующей странице Сайта, а если использование Сервиса было выбрано, но не оплачено Пользователем, – в Личном кабинете.</p>
                <p>8.4. По выбору Пользователя оплата стоимости использования Сервиса может осуществляться:</p>
                <p>8.4.1. Единовременно в полном объеме;</p>
                <p>8.4.2. Ежемесячно фиксированной суммой.</p>
                <p>8.5. Оплата услуг может быть осуществлена одним из следующих лиц:</p>
                <p>8.5.1. Полностью дееспособным Пользователем;</p>
                <p>8.5.2. Недееспособным либо частично дееспособным Пользователем с согласия законного представителя;</p>
                <p>8.5.3. Третьим лицом, на которое Пользователем возложено исполнение денежного обязательства.</p>
                <p>8.6. Оплата услуг может осуществляться с привлечением провайдеров электронной системы платежей, указанных на странице оплаты.</p>
                <p>8.7. Обязательства по оплате считаются исполненными при положительном результате авторизации платежа провайдером электронной системы платежей.</p>
                <p>8.8. Администратор не контролирует аппаратно-программный комплекс систем платежей провайдеров и не несет ответственности за ошибки в таком аппаратно-техническом комплексе.  Если в результате таких ошибок произошло списание денежных средств Пользователя, но платеж не был авторизован провайдером, обязанности по возврату денежных средств Пользователю лежат на провайдере электронной системы платежей.</p>
                <p>8.9. Пользователь самостоятельно несет ответственность за правильность производимых им платежей. </p>
            </InfoBlock> */}
            {/* <InfoBlock title="9. ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ">
                <p>9.1. Полный или частичный возврат уплаченных Пользователем денежных средств производится в следующих случаях:</p>
                <p>9.1.1. При некачественном оказании услуг посредством Сервисов Сайта, если Пользователь предъявил претензию и такая претензия была удовлетворена Администратором;</p>
                <p>9.1.2. При осуществлении ошибочного платежа, если Пользователь направил уведомление об осуществлении ошибочного платежа и денежные средства поступили на расчетный счет Администратора;</p>
                <p>9.1.3. В иных случаях, предусмотренных Правилами возврата денежных средств (http://uc-gru.ru/legal/refund), условиями использования Сервисов Сайта, договорами об образовании и законодательством Российской Федерации.</p>
                <p>9.2. Для возврата денежных средств Пользователю необходимо направить Администратору уведомление об отказе от получения услуг. Уведомление должно быть письменным и содержать ФИО Пользователя, название Сервиса и требование о возврате денежных средств. Адрес для направления уведомления — ask@uc.gru.ru</p>
                <p>9.3. Возврат денежных средств осуществляется в течение десяти рабочих дней тем же способом и по тем же реквизитам, по которым Пользователем была осуществлена оплата.</p>
                <p>9.4. Возврат наличными денежными средствами не допускается.</p>
            </InfoBlock> */}
            <InfoBlock title="10. ОТВЕТСТВЕННОСТЬ">
                <p>10.1. В случае нарушения Пользователем условий настоящего Соглашения, условий использования отдельных сервисов Сайта, законодательства Российской Федерации, норм морали и нравственности либо технических требований к использованию Сайта Администратор вправе:</p>
                <p>10.1.1. Заблокировать или удалить Личный кабинет Пользователя;</p>
                <p>10.1.2. Запретить либо ограничить доступ Пользователя к определенным или всем Сервисам Сайта и оказываемым с их помощью услугам;</p>
                <p>10.1.3. Ограничить Пользователю возможность использовать чат Сайта и интерактивное голосовое общение.</p>
                <p>10.1.4. Удержать стоимость оплаченного, но не использованного доступа к любому Сервису в качестве штрафа за нарушение условий использования Сайта.</p>
                <p>10.2. Условия ответственности, указанные в п. 10.1, также могут быть применены Администратором в случае наличия угрозы нарушения условий Соглашения со стороны Пользователя.</p>
                <p>10.3. Программно-аппаратные ошибки, как на стороне Администратора, так и на стороне Пользователя, приведшие к невозможности получения Пользователем доступа к любым Сервисам Сайта, являются обстоятельствами непреодолимой силы и освобождают Администратора от ответственности за неисполнение обязательств.</p>
                <p>10.4. Администратор не несет ответственность за неисполнение или ненадлежащее исполнение обязательств, возникших в том числе в результате: </p>
                <p>10.4.1. Неправомерных действий третьих лиц, направленных на нарушения информационной безопасности или нормального функционирования Сайта, в частности, за несанкционированный доступ третьих лиц к Личному кабинету и устройствам Пользователя;</p>
                <p>10.4.2. Сбоев в работе Сайта, вызванных ошибками в коде, компьютерными вирусами и иными посторонними вредоносными программами, и действиями третьих лиц;</p>
                <p>10.4.3. Отсутствия интернет- соединения между сервером Пользователя и сервером Администратора;</p>
                <p>10.4.4. Выполнения Администратором профилактических работ на сайтах Сервиса.</p>
            </InfoBlock>
            <InfoBlock title="11. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ">
                <p>11.1. Все споры, разногласия и претензии, которые могут возникнуть в связи с исполнением, расторжением или признанием недействительным Соглашения, Стороны будут стремиться решить путем переговоров. Сторона, у которой возникли претензии и/или разногласия, направляет другой Стороне сообщение с указанием возникших претензий и/или разногласий. Сообщение направляется на адрес электронной почты: Пользователю — на адрес электронной почты, указанный при регистрации; Администратору — на адрес ask@uc.gru.ru.</p>
                <p>11.2. В случае если ответ на сообщение не будет получен направившей сообщение Стороной в течение 10 &#40;десяти&#41; рабочих дней с даты направления соответствующего сообщения, либо если Стороны не придут к соглашению по возникшим претензиям и/или разногласиям, спор подлежит разрешению в судебном порядке.</p>
            </InfoBlock>
            <InfoBlock title="12. ИЗМЕНЕНИЕ УСЛОВИЙ СОГЛАШЕНИЯ">
                <p>12.1. Администратор вправе в одностороннем порядке изменять условия Соглашения, при этом такие изменения вступают в силу с момента опубликования новой редакции Соглашения в соответствующем разделе Сайта. Уведомления об изменении Соглашения могут быть отображены на Сайте &#40;например, в Личном кабинете, через всплывающее окно или баннер&#41; или могут быть отправлены по электронной почте.</p>
                <p>12.2. При каждом последующем посещении Сайта до начала пользования Личным кабинетом Пользователь обязуется знакомиться с новой редакцией Соглашения.  Продолжение использования Сайта и Личного кабинета будет означать согласие Пользователя с условиями новой редакции Соглашения.</p>
                <p>12.3. При несогласии с условиями новой редакции Соглашения Пользователь обязуется прекратить использование Сайта.</p>
                <p>12.4. При наличии оплаченного, но не использованного доступа к платным Сервисам Сайта Пользователь, не согласный с новой редакцией Соглашения, обязан уведомить об этом Администратора, отправив сообщение на адрес электронной почты ask@uc.gru.ru. В этом случае Пользователь может продолжать использовать Личный кабинет только для доступа к соответствующим платным Сервисам. По завершении срока оплаченного доступа Пользователь обязан прекратить использование Сайта. Использование Личного кабинета для добавления комментариев, общения в чате, оплаты доступа к новым Сервисам Сайта означает принятие Пользователем условий новой редакции Соглашения.</p>
            </InfoBlock>
            <InfoBlock title="13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ">
                <p>13.1. Соглашение и все возникающие из него правоотношения регулируются законодательством Российской Федерации. Все возникающие споры разрешаются в соответствии с законодательством Российской Федерации.</p>
                <p>13.2. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.</p>
                <p>13.3. Бездействие со стороны Администратора в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администратора права предпринять позднее соответствующие действия в защиту своих интересов и защиту интеллектуальных прав на охраняемые в соответствии с законодательством материалы Сайта.</p>
            </InfoBlock>
            <InfoBlock title="14. РЕКВИЗИТЫ АДМИНИСТРАТОРА">
                <p>Межрегиональная общественная организация «Союз ветеранов спецназа ГРУ» имени Героя Д.А.Шектаева</p>
                <p>Адрес: Россия, 620017, Свердловская обл,г. Екатеринбург, ул. Лобкова 2,оф. 30</p>
                <p>ИНН  6686114095</p>
                <p>ОГРН 1196658019645</p>
                <p>Телефон: +7-919-377-00-02</p>
            </InfoBlock>
        </>
    )
}