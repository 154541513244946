import React, {useState} from 'react';
import BorderContent from "../../components/common/borderContent";
import ButtonComp from "../../components/common/button";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import {addLinkUserCourse} from "../../adapters/getProfile";

const useStyles = makeStyles((theme) => ({
    lesson: {
        padding: "20px"
    },
    lesson_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        margin: "10px 0px 30px 0px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course__numberBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
        }
    },
    lesson_content: {
        // margin: "0px 20px 0px 20px"
    },
    lesson_topic: {
        marginTop: "20px",
        fontFamily: "Open Sans, Yeseva One",
        fontSize: "1.1rem"
    },
    lesson_url: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "1.4rem"
    },
    lesson_time: {
        fontFamily: "Yeseva One, Open Sans",
        fontSize: "2rem"
    },
    button: {
        margin: "20px 0px"
    },
    inputStyle: {
        margin: " 20px 0px"
    }

}))

const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#E2E2E2",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);

export default function EditCourses({data}) {
    const classes = useStyles();
    const [value, setValue] = useState({});
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState('');
    const {signup} = useAuth();
    const history = useHistory();

    const handleChange = (e, id) => {
        e.preventDefault();
        setValue({...value, id: e.target.value});

    };
    const handleSignup = (e, id, name) => {
        e.preventDefault();
        addLinkUserCourse(id, name, {link: value}).then((result) => {
            setData(result)
            setFinished(true)
        }).catch((error) => {
            console.error(error);
        });

    }
    return (
        <>
            <div className={classes.lesson_time}>
                Ваши курсы
                {/* {lesson.start_lesson} */}
            </div>
            <Grid container>
                <Grid item xs={12} sm={12} >
                    {data && data.map((el, index) => (

                        < form autoComplete="off" className={classes.form} onSubmit={handleSignup}>
                            <BorderContent classGrid={classes.lesson} fullWidth>
                                <Grid item xs={12} container className={classes.lesson_content}>
                                    <Grid item xs={12} className={classes.lesson_url}>{el.course}</Grid>
                                    <Grid item xs={12} className={classes.lesson_topic}>
                                        {el.link.id ? "Ссылка установлена" : "Ссылка отсутствует"}
                                        <Grid item xs={12} className={classes.inputStyle}>
                                            <InputBaseCustom
                                                className={classes.inputStyle_size}
                                                placeholder="Ссылка на урок"
                                                type="text"
                                                name={el.id}
                                                value={value.[el.id]}
                                                onChange={(e) => handleChange(e, el.id)}
                                                required
                                            // inputProps={{ 'aria-label': 'naked' }}
                                            />
                                        </Grid>
                                        {finished && "Ссылка установлена"}
                                        <Grid item xs={6} className={classes.button}>
                                            <ButtonComp onClick={(e) => handleSignup(e, el.id, el.course)} disabled={loading} type="submit" title="Отправить" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BorderContent>
                        </form>

                    ))}
                </Grid>
            </Grid>
            {/* <Grid item xs={12} className={classes.lesson_subtopic}> {lesson.topic_lesson} </Grid>
                    <Grid item xs={12} className={classes.lesson_url}>Занятие начнется через 2 часа</Grid> */}

        </>
    )
}