export const dataTestsPKM = [
    {
        id: 0,
        question: 'Начальная скорость полёта пули:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '715 м/с',
                answer: false,
            },
            {
                id: 2,
                text: '825 м/с',
                answer: true,
            },
            {
                id: 3,
                text: '860 м/с',
                answer: false,
            },
            {
                id: 4,
                text: '900 м/с',
                answer: false,
            }
        ]
    },
    {
        id: 1,
        question: 'Прицельная дальность стрельбы:',
        answer: 4,
        options: [
            {
                id: 1,
                text: '900 м',
                answer: false,
            },
            {
                id: 2,
                text: '1000 м',
                answer: false,
            },
            {
                id: 3,
                text: '1100 м',
                answer: false,
            },
            {
                id: 4,
                text: '1500 м',
                answer: true,
            }
        ]
    },
    {
        id: 2,
        question: 'Боевая скорострельность при стрельбе:',
        answer: 2,
        options: [
            {
                id: 1,
                text: '200 выстрелов в минуту',
                answer: false,
            },
            {
                id: 2,
                text: '250 выстрелов в минуту',
                answer: true,
            },
            {
                id: 3,
                text: '350 выстрелов в минуту',
                answer: false,
            },
            {
                id: 4,
                text: '400 выстрелов в минуту',
                answer: false,
            }
        ]
    },
    {
        id: 3,
        question: 'Темп стрельбы:',
        answer: 4,
        options: [
            {
                id: 1,
                text: '400 выстрелов в минуту',
                answer: false,
            },
            {
                id: 2,
                text: '450 выстрелов в минуту',
                answer: false,
            },
            {
                id: 3,
                text: '550 выстрелов в минуту',
                answer: false,
            },
            {
                id: 4,
                text: '650 выстрелов в минуту',
                answer: true,
            }
        ]
    },
    {
        id: 4,
        question: 'Выберите неверное утверждение:',
        answer: 3,
        options: [
            {
                id: 1,
                text: 'самый популярный пулёмет в мире',
                answer: false,
            },
            {
                id: 2,
                text: 'низкая скорострельности по сравнению с FN MAG, MG-3',
                answer: false,
            },
            {
                id: 3,
                text: 'емкость патронной коробки достигает 400 патронов',
                answer: true,
            },
            {
                id: 4,
                text: 'одна из модификаций Пулемёта Калашникова называется "Печенег"',
                answer: false,
            }
        ]
    },
    {
        id: 5,
        question: 'ПКМ был принят на вооружение советской армии в',
        answer: 4,
        options: [
            {
                id: 1,
                text: '1961',
                answer: false,
            },
            {
                id: 2,
                text: '1964',
                answer: false,
            },
            {
                id: 3,
                text: '1967',
                answer: false,
            },
            {
                id: 4,
                text: '1969',
                answer: true,
            }
        ]
    },
    {
        id: 6,
        question: 'Масса пулемёта без патронов:',
        answer: 1,
        options: [
            {
                id: 1,
                text: '7,5 кг',
                answer: true,
            },
            {
                id: 2,
                text: '9,5кг',
                answer: false,
            },
            {
                id: 3,
                text: '11,5 кг',
                answer: false,
            },
            {
                id: 4,
                text: '15,5 кг',
                answer: false,
            }
        ]
    },
    {
        id: 7,
        question: 'Дальность на которой сохраняется убойное действие пули:',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'до 1500 м',
                answer: false,
            },
            {
                id: 2,
                text: 'до 2000 м',
                answer: false,
            },
            {
                id: 3,
                text: 'до 2200 м',
                answer: false,
            },
            {
                id: 4,
                text: 'до 3800 м',
                answer: true,
            }
        ]
    },
]