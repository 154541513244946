import React, {useState, useRef} from "react";
import BorderContent from "../../components/common/borderContent";
import ButtonComp from "../../components/common/button";
import Title from "../../components/common/title";
import CheckboxStyle from "../../components/common/checkbox";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {useHistory} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';

const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#E2E2E2",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);


const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        // padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        margin: "10px"
    },
    form: {
        flex: "1 0 100%",
    },
    button: {
        textAlign: "center"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }

    },
    form__left: {
        margin: "10px 0px 10px 0px",
        // paddingRight: "20px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        }
    },
    inputStyle_size: {
        minWidth: "150px",
    },
    form__consent: {
        marginTop: "20px"
    },
    form__padding: {
        // padding: "0px 25px 0px 25px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px 0px 10px",
        }
    },
    label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: "100",
        lineHeight: "1",
    },
    auth_title1: {
        fontSize: "1.8rem",
        fontFamily: "Yeseva One, Open Sans",
        margin: "35px 0",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.3rem",
            margin: "25px 0",
        }
        // fontWeight: "900",
    },
}))

export default function ClassesForm({handleChangeSub, data}) {
    const classes = useStyles();
    // const [data, setData] = useState({ topic: "", title: "", email: "", password: "", dateClasses: "false" });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const {signup} = useAuth();
    const history = useHistory();

    const handleChange = (e) => {
        handleChangeSub(e)
    };
    const handleSignup = (e) => {
        e.preventDefault();
        const {firstName, lastName, email, password} = data;

        setError('');
        setLoading(true);

        signup(email, password, firstName, lastName)
            .then((ref) => {
                setLoading(false);
                history.push('/profile');
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }

    return (
        <>
            <div className={classes.auth_title1}>Темы занятий</div>
            <BorderContent>
                <div className={classes.auth_content}>
                    <div className={classes.form__padding}>
                        <Grid container className={classes.form__left}>
                            <Grid item xs={12} className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.inputStyle_size}
                                    placeholder="Заголовок блока"
                                    type="text"
                                    name="topic"
                                    value={data.topic}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.inputStyle_size}
                                    placeholder="Тема"
                                    type="text"
                                    name="title"
                                    value={data.title}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.inputStyle_size}
                                    placeholder="Подтема"
                                    type="text"
                                    name="description"
                                    value={data.description}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.inputStyle_size}
                                    placeholder="Дата урока"
                                    type="date"
                                    name="dateClasses"
                                    value={data.email}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </BorderContent>
        </>

    )
}