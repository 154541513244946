import React from "react";
import FeedBackForm from "../../components/common/feedbackForm";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    main_text: {
        marginBottom: "20px"
    },

}));

export default function MobileAppPage() {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.main_text}>Приложение в разработке. Можете оставить свои данные, мы сообщим вам о выходе приложения.</div>
            <FeedBackForm fill="green" title="Подписаться на уведомления" titleButton="Отправить заявку" fullWidth messageOff />
        </div>
    )
}