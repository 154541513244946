import React from "react";
import Title from "./common/title";
import CardMedia from "./common/cardMedia";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  news__cards: {},
  content: {
    // height: "80%",
    alignContent: "start"
    // paddingTop: "70px"
  },
  content__block_subtitle: {
    fontFamily: "Open Sans"
  },
  content__block_title: {
    fontFamily: "Russo One",
    fontSize: "1.8rem",
    marginBottom: "5px"
  },
  block_title_font_size: {
    fontSize: "1.4rem",
    marginBottom: "5px"
  },
  content__block_img_size: {
    width: "440px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

export default function StagesTraining() {
  const classes = useStyles();
  return (
    <Grid container style={{ paddingTop: "70px" }}>
      <Title title="Этапы обучения" />
      <Grid item container wrap="nowrap" className={classes.news__cards}>
        <Grid item container className={classes.content}>
          <CardMedia
            imgUrl={
              <img
                src="/static/img/stagesTraining1.png"
                alt="онлайн обучение"
                className={classes.content__block_img_size}
              />
            }
          >
            <Typography
              variant="h5"
              className={clsx(
                classes.content__block_title,
                classes.block_title_font_size
              )}
            >
              1 этап
            </Typography>
            <Typography
              variant="h4"
              className={clsx(classes.content__block_title)}
            >
              онлайн обучение
            </Typography>
            <Typography
              variant="subtitle1"
              className={clsx(classes.content__block_subtitle)}
            >
              Обучение проводят сертифицированные инструктора из числа
              действующих офицеров и ветеранов ВС РФ. В их "арсенале"
              современные, продвинутые методики обучения и интерактивное
              взаимодействие с обучающимися, онлайн-тренажер с заданиями и
              играми.
            </Typography>
          </CardMedia>
          <CardMedia
            side="right"
            imgUrl={
              <img
                src="/static/img/stagesTraining2.png"
                alt="Практические тренировки"
                className={classes.content__block_img_size}
              />
            }
          >
            <Typography
              variant="h5"
              className={clsx(
                classes.content__block_title,
                classes.block_title_font_size
              )}
            >
              2 этап
            </Typography>
            <Typography
              variant="h4"
              className={clsx(classes.content__block_title)}
            >
              практические тренировки
            </Typography>
            <Typography
              variant="subtitle1"
              className={clsx(classes.content__block_subtitle)}
            >
              Прошедшие теоретическую онлайн программу, обучающиеся отправляются
              на полигон для практической тренировки на тренажерах Министерства
              обороны, управление военной техникой, стрельба из танка,
              развертывание радиостанций и много другое.
            </Typography>
          </CardMedia>
          <CardMedia
            imgUrl={
              <img
                src="/static/img/stagesTraining3.png"
                alt="Сдача экзаменов и нормативов"
                className={classes.content__block_img_size}
              />
            }
          >
            <Typography
              variant="h5"
              className={clsx(
                classes.content__block_title,
                classes.block_title_font_size
              )}
            >
              3 этап
            </Typography>
            <Typography
              variant="h4"
              className={clsx(classes.content__block_title)}
            >
              итоговая аттестация
            </Typography>
            <Typography
              variant="subtitle1"
              className={clsx(classes.content__block_subtitle)}
            >
              Заключительный этап - сдача экзаменов (вождение, стрельбы и др.)
              на реальных образцах Вооружения и Военной Техники, средствах
              связи, преодолении полос препятствий и т.д.
            </Typography>
          </CardMedia>
        </Grid>
      </Grid>
    </Grid>
  );
}
