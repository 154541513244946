import React from "react";
import Title from "./title";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: "100px"
  },
  section__cards: {
    // overflow: "auto",
    paddingBottom: "20px",
    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#99a63c",
      height: "10px",
      borderRadius: "10px"
    }
  },
  section__button: {
    paddingTop: "35px",
    margin: "0 auto"
  }
}));

export default function Main({title, children}){
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <Title title={title} />
            <div className={classes.section__cards}>
                {children}
            </div>
        </div>
    )
}