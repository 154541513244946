import React from "react";
import CardMedia2 from "./common/cardMedia2";
import Section from "./common/section";
import CardContent2 from "./common/cardContent2";

import data from "../api/dataSpecialCourses.json";

import {Link, useRouteMatch} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ChildCareIcon from '@material-ui/icons/ChildCare';
import {ReactComponent as AgeIcon} from "../static/svg/ageIcon.svg";
import {ReactComponent as ChildrenIcon} from "../static/svg/ageChildrenIcon.svg";
import {ReactComponent as ChildrenAdultIcon} from "../static/svg/childrenAdult.svg";

const useStyles = makeStyles((theme) => ({
  styleIcon: {
    width: "35px",
    float: "right",
    fill: "#99a63c",
  },
}));

const DefineIconVariant = ({age, className}) => {
  if (age.length === 2) {
    return (<div className={className} >для всех</div>)
  } else if (age[0] === "adults") {
    return (<AgeIcon className={className} />)
  } else {
    return (<ChildrenIcon className={className} />)
  }
}

export default function StudyPrograms() {

  const classes = useStyles();
  let {url} = useRouteMatch();
  return (
    <Section title="Программы обучения">
      {
        data.map(el => (
          <CardMedia2 sizeСorners="small" titleButton={<Link to={`${url}/${el.url}`}>Подробнее</Link>}>
            <DefineIconVariant age={el.age} className={classes.styleIcon} />
            <CardContent2
              title={el.name}
              description={el.description}
              impotent={el?.impotent || ''}
            />
          </CardMedia2>
        ))
      }
    </Section>
  );
}
