export const endTest = {
    testEnd: [
        {
            nameQuestion: "question1",
            labelQuestion: "Дата освобождения советских войск от блокады немецко-фашистских войск города Ленинград? ",
            questions: [
                {
                    id: '1',
                    answer: true,
                    label: "27 января 1944 года",
                },
                {
                    id: '2',
                    answer: false,
                    label: "27 января 1943 года"
                },
                {
                    id: '3',
                    answer: false,
                    label: "27 февраля 1944 года"
                },
                {
                    id: '4',
                    answer: false,
                    label: "27 февраля 1943 года"
                },
            ]
        },
        {
            nameQuestion: "question2",
            labelQuestion: "С чем связан праздник 23 февраля «День защитника отечества»?",
            questions: [
                {
                    id: '5',
                    answer: false,
                    label: "С победой красной Армии над фашистами",
                },
                {
                    id: '6',
                    answer: true,
                    label: "C созданием Рабоче-Крестьянской Красной армии"
                },
                {
                    id: '7',
                    answer: false,
                    label: "С днем создания партии КПСС"
                },
            ]
        },
        {
            nameQuestion: "question5",
            labelQuestion: "Первый Герой Советского Союза?",
            questions: [
                {
                    id: '16',
                    answer: false,
                    label: "снайпер Василий Зайцев",
                },
                {
                    id: '17',
                    answer: true,
                    label: "лётчики Анатолий Ляпидевский"
                },
                {
                    id: '18',
                    answer: false,
                    label: "полководец Василевский Александр Михайлович"
                },
                {
                    id: '19',
                    answer: false,
                    label: "Брежнев Леонид Ильич"
                },
            ]
        },
        {
            nameQuestion: "question13",
            labelQuestion: "В каком году утвердили новую редакцию военной доктрины России?",
            questions: [
                {
                    id: '48',
                    answer: false,
                    label: "1 сентября 1993 году"
                },
                {
                    id: '49',
                    answer: false,
                    label: "26 декабря 1991 году"
                },
                {
                    id: '50',
                    answer: true,
                    label: "25 декабря 2014 году"
                },
                {
                    id: '51',
                    answer: false,
                    label: "25 декабря 1991 году"
                },
            ]
        },
        {
            nameQuestion: "question14",
            labelQuestion: "Для чего предназначены Вооруженные Силы РФ?",
            questions: [
                {
                    id: '52',
                    answer: false,
                    label: "Для поддержания внутреннего порядка в РФ"
                },
                {
                    id: '53',
                    answer: true,
                    label: " Для отражения агрессии, направленной против РФ"
                },
                {
                    id: '54',
                    answer: false,
                    label: "Для борьбы с терроризмом"
                },
            ]
        },
        {
            nameQuestion: "question15",
            labelQuestion: "Когда была принята и вступила в действие конституция РФ?",
            questions: [
                {
                    id: '56',
                    answer: true,
                    label: "25 декабря 1993 года"
                },
                {
                    id: '57',
                    answer: false,
                    label: "26 декабря 1991 году"
                },
                {
                    id: '58',
                    answer: false,
                    label: "25 декабря 1991 году"
                },
                {
                    id: '59',
                    answer: false,
                    label: "1 декабря 1993 году"
                },
            ]
        },
        {
            nameQuestion: "question22",
            labelQuestion: "Рода войск, входящие в структуру ВС?",
            questions: [
                {
                    id: '84',
                    answer: false,
                    label: "Мотострелковые войска, танковые войска, воска ПВО."
                },
                {
                    id: '85',
                    answer: true,
                    label: "Ракетные войска стратегического назначения, воздушно-десантные войска."
                },
                {
                    id: '86',
                    answer: false,
                    label: "Ракетные войска и артиллерия, специальные войска, соединения, части и учреждения тыла."
                },
            ]
        },
        {
            nameQuestion: "question23",
            labelQuestion: "Руководство ВС осуществляет?",
            questions: [
                {
                    id: '88',
                    answer: false,
                    label: "Начальник генерального штаба ВС РФ."
                },
                {
                    id: '89',
                    answer: true,
                    label: "Президент Российской Федерации."
                },
                {
                    id: '90',
                    answer: false,
                    label: "Главнокомандующие видами ВС РФ."
                }
            ]
        },
        {
            nameQuestion: "question24",
            labelQuestion: "В настоящее время Вооруженные Силы РФ структурно включают в себя:",
            questions: [
                {
                    id: '92',
                    answer: true,
                    label: "3 вида и 2 рода войск."
                },
                {
                    id: '93',
                    answer: false,
                    label: "2 вида и 3 рода войск."
                },
                {
                    id: '94',
                    answer: false,
                    label: "3 вида и 3 рода войск."
                }
            ]
        },
        {
            nameQuestion: "question27",
            labelQuestion: "Какие основные формы тактических действий вы можете назвать?",
            questions: [
                {
                    id: '104',
                    answer: false,
                    label: "Оборона, наступление."
                },
                {
                    id: '105',
                    answer: false,
                    label: "Передвижение, марш."
                },
                {
                    id: '106',
                    answer: true,
                    label: "Удар, маневр и бой."
                },
                {
                    id: '107',
                    answer: false,
                    label: "Отход, действие в тактическом воздушном десанте, разведка."
                },
            ]
        },
        {
            nameQuestion: "question29",
            labelQuestion: "Основные виды общевойскового боя является:",
            questions: [
                {
                    id: '112',
                    answer: false,
                    label: "оборона и маневр."
                },
                {
                    id: '113',
                    answer: true,
                    label: "оборона и наступление."
                },
                {
                    id: '114',
                    answer: false,
                    label: "наступление и маневр."
                },
            ]
        },
        {
            nameQuestion: "question34",
            labelQuestion: "При каком ядерном взрыве поражающее действие ЭМИ достигает максимальных значений:",
            questions: [
                {
                    id: '128',
                    answer: false,
                    label: "наземном."
                },
                {
                    id: '129',
                    answer: false,
                    label: "подземном."
                },
                {
                    id: '130',
                    answer: false,
                    label: "подводным."
                },
                {
                    id: '131',
                    answer: true,
                    label: "высотном."
                },
            ]
        },
        {
            nameQuestion: "question36",
            labelQuestion: "При лучевой болезни IV степени (крайне тяжелой) смерть наступает через:",
            questions: [
                {
                    id: '136',
                    answer: true,
                    label: "5 – 12 дней."
                },
                {
                    id: '137',
                    answer: false,
                    label: "•	1 день."
                },
                {
                    id: '138',
                    answer: false,
                    label: "1 час."
                },
                {
                    id: '138',
                    answer: false,
                    label: "1 месяц."
                },
            ]
        },
        {
            nameQuestion: "question37",
            labelQuestion: "При каком ядерном взрыве наиболее сильное радиоактивное заражение местности:",
            questions: [
                {
                    id: '136',
                    answer: true,
                    label: "наземном."
                },
                {
                    id: '137',
                    answer: false,
                    label: "подземным."
                },
                {
                    id: '138',
                    answer: false,
                    label: "подводным."
                },
                {
                    id: '139',
                    answer: false,
                    label: "воздушном."
                },
            ]
        },
        {
            nameQuestion: "question39",
            labelQuestion: "Какими материалами лучше всего ослабляется поток нейтронов:",
            questions: [
                {
                    id: '140',
                    answer: false,
                    label: "свинец, сталь."
                },
                {
                    id: '141',
                    answer: true,
                    label: "вода, полиэтилен."
                },
                {
                    id: '142',
                    answer: false,
                    label: "грунт, дерево."
                },
                {
                    id: '143',
                    answer: false,
                    label: "бетон, стекло."
                },
            ]
        },
        {
            nameQuestion: "question41",
            labelQuestion: "Какими материалами сильнее всего ослабляется γ- излучение:",
            questions: [
                {
                    id: '148',
                    answer: true,
                    label: "свинец, сталь, бетон."
                },
                {
                    id: '149',
                    answer: false,
                    label: "грунт, дерево, стекло."
                },
                {
                    id: '150',
                    answer: false,
                    label: "алюминий, медь."
                },
                {
                    id: '151',
                    answer: false,
                    label: "вода, полиэтилен."
                },
            ]
        },
        {
            nameQuestion: "question43",
            labelQuestion: "К коллективным средствам защиты относятся:",
            questions: [
                {
                    id: '148',
                    answer: false,
                    label: "противогазы"
                },
                {
                    id: '149',
                    answer: false,
                    label: "респираторы."
                },
                {
                    id: '150',
                    answer: true,
                    label: "убежища."
                },
                {
                    id: '151',
                    answer: false,
                    label: "средства защиты кожи."
                },
            ]
        },
        {
            nameQuestion: "question45",
            labelQuestion: "Назовите наиболее сильный поражающий фактор ядерного взрыва:",
            questions: [
                {
                    id: '156',
                    answer: true,
                    label: "Ударная волна."
                },
                {
                    id: '157',
                    answer: false,
                    label: "Световое излучение."
                },
                {
                    id: '158',
                    answer: false,
                    label: "Радиоактивное заражение."
                },
                {
                    id: '159',
                    answer: false,
                    label: "Проникающая радиация."
                },
            ]
        },
        {
            nameQuestion: "question46",
            labelQuestion: "Стрелку при разряжении оружия необходимо:",
            questions: [
                {
                    id: '160',
                    answer: false,
                    label: "передернуть затвор, проверяя наличие патрона в патронни­ке, извлечь магазин."
                },
                {
                    id: '161',
                    answer: false,
                    label: "сделать контрольный спуск."
                },
                {
                    id: '162',
                    answer: false,
                    label: "извлечь магазин, поставить на предохранитель."
                },
                {
                    id: '163',
                    answer: true,
                    label: "извлечь магазин, передернуть затвор, сделать контрольный спуск, поставить на предохранитель."
                },
            ]
        },
        {
            nameQuestion: "question47",
            labelQuestion: "Каков темп стрельбы (выстрелов в минуту) у автомата АКМ:",
            questions: [
                {
                    id: '164',
                    answer: false,
                    label: "около 500 выстрелов"
                },
                {
                    id: '165',
                    answer: true,
                    label: "около 600 выстрелов."
                },
                {
                    id: '166',
                    answer: false,
                    label: "около 700 выстрелов"
                },
                {
                    id: '167',
                    answer: false,
                    label: "около 800 выстрелов"
                },
            ]
        },
        {
            nameQuestion: "question49",
            labelQuestion: "Какова прицельная дальность стрельбы автомата АКМ?",
            questions: [
                {
                    id: '171',
                    answer: false,
                    label: "1500 метров"
                },
                {
                    id: '172',
                    answer: false,
                    label: "1000 метров"
                },
                {
                    id: '173',
                    answer: true,
                    label: "500 метров"
                },
                {
                    id: '174',
                    answer: false,
                    label: "300 метров"
                },
            ]
        },
        {
            nameQuestion: "question53",
            labelQuestion: "Что не является основной частью АК-74?",
            questions: [
                {
                    id: '184',
                    answer: true,
                    label: "крышки ствольной коробки"
                },
                {
                    id: '185',
                    answer: false,
                    label: "Магазин"
                },
                {
                    id: '186',
                    answer: false,
                    label: "Возвратный механизм"
                },
                {
                    id: '187',
                    answer: false,
                    label: "Ударно-спусковой механизм"
                },
            ]
        },
        {
            nameQuestion: "question56",
            labelQuestion: "Когда отмечается день военного связиста?",
            questions: [
                {
                    id: '195',
                    answer: true,
                    label: "20 октября"
                },
                {
                    id: '196',
                    answer: false,
                    label: "6 августа"
                },
                {
                    id: '197',
                    answer: false,
                    label: "7 мая"
                },
            ]
        },
        {
            nameQuestion: "question61",
            labelQuestion: "Что такое общевоинские уставы?",
            questions: [
                {
                    id: '214',
                    answer: true,
                    label: "Это нормативно-правовые акты, которые регламентируют жизнь и быт военнослужащих, их взаимоотношения между собой и повседневную деятельность"
                },
                {
                    id: '215',
                    answer: false,
                    label: "Это распорядок дня внутри военной части"
                },
                {
                    id: '216',
                    answer: false,
                    label: "Свод негласных правил поведения и норм общения на территории воинской части"
                }
            ]
        },
        {
            nameQuestion: "question63",
            labelQuestion: "Для каких войск действуют общевоинские уставы?",
            questions: [
                {
                    id: '220',
                    answer: false,
                    label: "Для всех видов и родов ВС РФ"
                },
                {
                    id: '221',
                    answer: true,
                    label: "Для всех кроме ВВС и ВМФ РФ"
                },
                {
                    id: '222',
                    answer: false,
                    label: "Для всех кроме Миротворческих сил"
                },
            ]
        },
        {
            nameQuestion: "question64",
            labelQuestion: "Когда был принят Указ Президента Российской Федерации об «Утверждении общевоинских уставов Вооружённых Сил Российской Федерации»?",
            questions: [
                {
                    id: '223',
                    answer: false,
                    label: "3 октября 1998 года"
                },
                {
                    id: '224',
                    answer: false,
                    label: "21 июля 2001 года"
                },
                {
                    id: '225',
                    answer: true,
                    label: "10 ноября 2007 года"
                }
            ]
        },
        {
            nameQuestion: "question84",
            labelQuestion: "Фронт является",
            questions: [
                {
                    id: '289',
                    answer: false,
                    label: "сторона противоположная тылу"
                },
                {
                    id: '290',
                    answer: true,
                    label: "сторона строя, в которую военнослужащие обращены лицом (машины—лобовой частью)"
                },
                {
                    id: '291',
                    answer: false,
                    label: "сторона строя противоположна флангу"
                },
                {
                    id: '292',
                    answer: false,
                    label: "построение военнослужащих при котором они обращены в одну сторону"
                }
            ]
        },
        {
            nameQuestion: "question89",
            labelQuestion: "Топографические карты это",
            questions: [
                {
                    id: '309',
                    answer: false,
                    label: "карты местности"
                },
                {
                    id: '310',
                    answer: false,
                    label: "специальные обзорно-географические карты"
                },
                {
                    id: '311',
                    answer: false,
                    label: "измерительные документы"
                },
                {
                    id: '312',
                    answer: true,
                    label: "измерительные документы и основные источники информации о местности, служащие одним из важнейших средств для управления войсками"
                }
            ]
        },
        {
            nameQuestion: "question93",
            labelQuestion: "Что такое потерянная ориентировка?",
            questions: [
                {
                    id: '325',
                    answer: true,
                    label: "когда на местности не находят объектов, обозначенных на карте, и не могут определить на карте свое местонахождение"
                },
                {
                    id: '326',
                    answer: false,
                    label: "когда на карте не находят объектов местности"
                },
                {
                    id: '327',
                    answer: false,
                    label: "когда потеряны ориентировочные принадлежности"
                },
                {
                    id: '328',
                    answer: false,
                    label: "когда не могут определить местонахождение"
                }
            ]
        },
        {
            nameQuestion: "question97",
            labelQuestion: "При глазомерном определении расстояния видны отдельные деревья, трубы на крышах домов. Ориентировочная дальность объектов составляет:",
            questions: [
                {
                    id: '341',
                    answer: false,
                    label: "4км"
                },
                {
                    id: '342',
                    answer: true,
                    label: "3км"
                },
                {
                    id: '343',
                    answer: false,
                    label: "2км"
                }
            ]
        },
        {
            nameQuestion: "question106",
            labelQuestion: "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            questions: [
                {
                    id: '367',
                    answer: false,
                    label: "Поднести зеркальце или птичье перо к носу пострадавшего"
                },
                {
                    id: '368',
                    answer: true,
                    label: "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку"
                },
                {
                    id: '369',
                    answer: false,
                    label: "Приложить ухо к груди пострадавшего и прослушать дыхание"
                },
            ]
        },
        {
            nameQuestion: "question107",
            labelQuestion: "Правильная транспортировка пострадавшего, находящегося без сознания (за исключением случаев, когда в связи с подозрением на травму позвоночника менять положение тела не рекомендуется) производится:",
            questions: [
                {
                    id: '370',
                    answer: true,
                    label: "В положении на боку"
                },
                {
                    id: '371',
                    answer: false,
                    label: "В положении на спине"
                },
                {
                    id: '372',
                    answer: false,
                    label: "В положении с приподнятыми нижними конечностями."
                },
            ]
        },
        {
            nameQuestion: "question108",
            labelQuestion: "Если пострадавший находится без сознания, в какое положение до прибытия скорой помощи он должен быть переведен",
            questions: [
                {
                    id: '373',
                    answer: false,
                    label: "В положении на спине"
                },
                {
                    id: '374',
                    answer: false,
                    label: "В положении полусидя"
                },
                {
                    id: '375',
                    answer: true,
                    label: "В устойчивое боковое положение"
                }
            ]
        },
        {
            nameQuestion: "question109",
            labelQuestion: "При повреждении костей плеча или бедра шину накладывают:",
            questions: [
                {
                    id: '376',
                    answer: true,
                    label: "С захватом трех суставов (двух ниже и одного выше места перелома)."
                },
                {
                    id: '377',
                    answer: false,
                    label: "С захватом только верхнего (по отношению к месту перелома) сустава."
                },
                {
                    id: '378',
                    answer: false,
                    label: "С захватом только двух суставов (выше и ниже места перелома)."
                }
            ]
        },
        {
            nameQuestion: "question110",
            labelQuestion: "Порядок оказания первой помощи при открытых переломах:",
            questions: [
                {
                    id: '379',
                    answer: false,
                    label: "Наложить шину и обезболить (по возможности)."
                },
                {
                    id: '380',
                    answer: true,
                    label: "Обезболить (по возможности), наложить повязку, наложить шину."
                },
                {
                    id: '381',
                    answer: false,
                    label: "Наложить шину, наложить повязку на рану."
                },
            ]
        },
        {
            nameQuestion: "question111",
            labelQuestion: "Какие правила оказания первой помощи соблюдаются при проникающем ранении в брюшную полость?",
            questions: [
                {
                    id: '382',
                    answer: false,
                    label: "Приподнять голову, дать сладкое теплое питье, накрыть стерильной салфеткой и положить холод на рану."
                },
                {
                    id: '383',
                    answer: true,
                    label: "Не давать пострадавшему пить жидкость, не извлекать инородное тело, прикрыть рану стерильным перевязочным материалом."
                },
                {
                    id: '384',
                    answer: false,
                    label: "Не давать пострадавшему пить жидкость, извлечь инородное тело, накрыть рану стерильной салфеткой."
                },
            ]
        },
        {
            nameQuestion: "question112",
            labelQuestion: "Какова правильная последовательность действий при остановке артериального кровотечения?",
            questions: [
                {
                    id: '385',
                    answer: true,
                    label: "Проводится пальцевая остановка кровотечения, накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута"
                },
                {
                    id: '386',
                    answer: false,
                    label: "Проводится пальцевая остановка кровотечения, накладывается чистая повяз­ка, накладывается жгут (скрутка, ремень), указывается время наложения жгута"
                },
                {
                    id: '387',
                    answer: false,
                    label: "Накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута."
                },
            ]
        },
        {
            nameQuestion: "question113",
            labelQuestion: "Промывание желудка при отравлении в порядке первой помощи (немедицинским персоналом и без желудочного зонда) запрещено:",
            questions: [
                {
                    id: '388',
                    answer: true,
                    label: "При отравлениях у несовершеннолетних детей"
                },
                {
                    id: '389',
                    answer: false,
                    label: "При отравлениях у лиц, не имеющих при себе документов, удостоверяющих личность"
                },
                {
                    id: '390',
                    answer: false,
                    label: "При отравлениях кислотами, щелочами, нефтепродуктами, при судорогах, в случае потери сознания пострадавшим"
                }
            ]
        },
        {
            nameQuestion: "question114",
            labelQuestion: "При укусах змей запрещается:",
            questions: [
                {
                    id: '391',
                    answer: true,
                    label: "Прижигать место укуса"
                },
                {
                    id: '392',
                    answer: false,
                    label: "Прикладывать холод"
                },
                {
                    id: '393',
                    answer: false,
                    label: "Обеспечить пострадавшему покой"
                },
            ]
        },
        {
            nameQuestion: "question115",
            labelQuestion: "В течении какого времени симптомы укуса змей достигают максимума?",
            questions: [
                {
                    id: '394',
                    answer: true,
                    label: "8 – 36 часов"
                },
                {
                    id: '395',
                    answer: false,
                    label: "7 – 10 часов"
                },
                {
                    id: '396',
                    answer: false,
                    label: "10 – 15 часов"
                }
            ]
        },
    ]
}