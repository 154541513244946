import React from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";
import TextareaAutosize from '@mui/material/TextareaAutosize';


const useStyles = makeStyles((theme) => ({
    textField: {
        display: 'grid',
        position: 'relative',
        margin: '15px 0px',
        alignItems: 'baseline',
        gridTemplateColumns: '1fr auto auto',

        '&:focus-within > label': {
            color: 'green',
            transform: 'translate(0px, -15px) scale(0.7)',
        },

        '& textarea, input': {
            '&:required ~ label': {
                color: 'green',
                transform: 'translate(0px, -15px) scale(0.7)',
            },
            '&:invalid ~ label': {
                color: '#00000078',
                transform: 'translate(0px, 5px) scale(1)',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:focus ~ label': {
                transform: 'translate(0px, -15px) scale(0.8)',
            },
        },
    },

    textField__textarea_close: {
        //height: '45px!important',
    },
    textField__textarea: {
        width: '100%',
        resize: 'none',
        border: 'none',
        padding: '8px',
        fontSize: '0.9rem',
        lineHeight: '17px',
        background: 'transparent',
        transition: 'height 0.6s',
        boxSizing: 'border-box',
        border: '1px solid #c3c3c3;',
        fontFamily: 'Play, Open Sans, sans-serif',
    },
    textField__label: {
        top: '0',
        zIndex: '1',
        display: 'block',
        fontSize: '0.7rem',
        color: '#00000078',
        position: 'absolute',
        padding: '0px 10px',
        padding: '0px 10px',
        pointerEvents: 'none',
        transformOrigin: 'top left',
        fontFamily: 'Open Sans, sans-serif',
        fontFamily: ' Open Sans, sans-serif',
        transform: 'translate(0px, 5px) scale(1)',
        transition: 'color 200ms, transform 200ms',
    },
}));

const TextField = (props) => {
    const {
        id,
        onChange,
        required = false,
        numberElement = false,
        label,
        area,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.textField}>
            {
                area ? (
                    <>
                        <TextareaAutosize
                            id={id}
                            className={clsx(classes.textField__textarea)}
                            onChange={onChange}
                            required={required}
                        />
                        <label className={classes.textField__label} htmlFor={id}>{label} {numberElement >= 0 && numberElement}</label>
                    </>
                ) :
                    <>
                        <input id={id} className={clsx(classes.textField__textarea)} onChange={onChange} required />
                        <label className={classes.textField__label} htmlFor={id}>{label} {numberElement >= 0 && numberElement}</label>
                    </>
            }

        </div>
    )
}

export default TextField;
