import React from 'react';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";

const MenuItemCustom = withStyles({
    root: {
        fontFamily: "Open Sans, Segoe UI, Helvetica, Arial, sans-serif"
    }
})(MenuItem);

const useStyles = makeStyles((theme) => ({
    menuProfile_list: {
        listStyleType: "none",
        fontSize: "1rem",
        textTransform: "uppercase",
        fontFamily: "Segoe UI, Open Sans"
    },
    menuProfile_listItem: {
        paddingTop: "7px"
    }
}));

export default function MenuProfileTeacher() {
    const classes = useStyles();
    return (
        <MenuList className={classes.menuProfile}>
            <Link to="/profile/dashboard-courses"><MenuItemCustom>Главная</MenuItemCustom></Link>
            <Link to="/profile/courses"><MenuItemCustom>Мои курсы</MenuItemCustom></Link>
            <Link to="/profile/student-list"><MenuItemCustom>Ученики</MenuItemCustom></Link>
            {/* <Link to="/profile/calendar"><MenuItemCustom>Календарь</MenuItemCustom></Link>
            <Link to="/profile/progress"><MenuItemCustom>Прогресс</MenuItemCustom></Link>
            <Link to="/profile/achievements"><MenuItemCustom>Достижения</MenuItemCustom></Link> */}
            {/* <Link to="/chat"><MenuItemCustom>Чат</MenuItemCustom></Link> */}
        </MenuList>
    )
}