import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menu: {
    margin: "0px 0px 30px 0px"
  },
  menu_item: {
    marginLeft: "30px",
    fontSize: "1rem"
  }
}));
export default function Menu() {
  const classes = useStyles();
  return (
    <Grid container justify="flex-end" className={classes.menu}>
      <Grid item className={classes.menu_item}>
        <Link to="/">Главная</Link>
      </Grid>
      <Grid item className={classes.menu_item}>
        <Link to="/special-courses">Спецкурсы</Link>
      </Grid>
      <Grid item className={classes.menu_item}>
        <Link to="/courses">Курсы</Link>
      </Grid>
      <Grid item className={classes.menu_item}>
        <Link to="/library">Библиотека</Link>
      </Grid>
      <Grid item className={classes.menu_item}>
        <Link to="/news">Новости</Link>
      </Grid>
    </Grid>
  );
}
