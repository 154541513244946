import React, {useState, useRef} from "react";
import BorderContent from "./common/borderContent";
import ButtonComp from "./common/button";
import Title from "./common/title";
import CheckboxStyle from "../components/common/checkbox";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {Link, useHistory} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#E2E2E2",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);


const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        // padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        margin: "10px"
    },
    form: {
        flex: "1 0 100%",
    },
    button: {
        textAlign: "center"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }

    },
    form__left: {
        margin: "10px 0px 10px 0px",
        // paddingRight: "20px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        }
    },
    inputStyle_size: {
        minWidth: "150px",
    },
    form__consent: {
        marginTop: "20px"
    },
    form__padding: {
        padding: "0px 25px 0px 25px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px 0px 10px",
        }
    },
    label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: "100",
        lineHeight: "1",
    },
}))

export default function Registration({noRedirect}) {
    const classes = useStyles();
    const [data, setData] = useState({firstName: "", lastName: "", email: "", password: "", сonditions: "false", });
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error2, setError2] = useState(false);
    const {signup} = useAuth();
    const history = useHistory();

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            setData({...data, [e.target.name]: e.target.checked.toString()});
        } else {
            setData({...data, [e.target.name]: e.target.value});
        }
    };
    const handleSignup = (e) => {
        e.preventDefault();
        const {firstName, lastName, email, password} = data;
        setError('');
        setLoading(true);
        if (data.сonditions != "false") {
            setError2(false)
            signup(email, password, firstName, lastName)
                .then((ref) => {
                    setLoading(false);
                    if (!noRedirect) {
                        history.push('/profile/dashboard');
                    }

                    // if (ref.user && ref.user.emailVerified === false) {
                    //     ref.user.sendEmailVerification().then(function () {
                    //     }).catch((error) => {
                    //         // Handle Errors here.
                    //         let errorCode = error.code;
                    //         let errorMessage = error.message;

                    //     });
                    // }
                })
                .catch((err) => {
                    setError(err.message);
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setError2(true)
        }

    }
    return (
        <form autoComplete="off" className={classes.form} onSubmit={handleSignup}>
            <div className={classes.form__padding}>
                <Grid container className={classes.form__left}>
                    <Grid item xs={12} className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Имя"
                            type="text"
                            name="firstName"
                            value={data.firstName}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Фамилия"
                            type="text"
                            name="lastName"
                            value={data.lastName}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                    <Grid item xs className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                    <Grid item xs className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Пароль"
                            type="password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs={12} sm>
                        <FormControlLabel
                            control={
                                <CheckboxStyle
                                    name="сonditions"
                                    cheacked={data.сonditions}
                                    onChange={handleChange}
                                />
                            }
                            label={<div className={classes.label}>Я принимаю условия <Link to="/official-info/general"><span style={{color: "blue"}}>соглашения</span></Link></div>}
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm className={classes.button}>
                        <ButtonComp disabled={loading} type="submit" title="Зарегистрироваться" />
                    </Grid>
                    {error2 && <div>Необходимо принять условия соглашения</div>}
                </Grid>
            </div>
        </form>
    )
}