import React, {useState} from "react";
import BorderContent from "../components/common/borderContent";
import ButtonComp from "../components/common/button";
import {useAuth} from '../contexts/AuthContext';
import {enrollCourse} from '../adapters/getProfile';

import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import Title from "./common/title";
import {useHistory} from 'react-router-dom';


const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#bbc764",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);


const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("sm")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        margin: "20px 0px 20px 0px"
    },
    form__size: {
        width: "100%",
    },
    button: {
        textAlign: "center"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }
    }

}))

export default function RegisterCourseForm({price, courseName}) {
    const {currentUser} = useAuth();
    const classes = useStyles();
    const history = useHistory();
    const [formLoading, setFormLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [finish, setFinish] = useState(false);

    const [data, setData] = useState({firstName: "", email: "", tel: "", course: courseName});

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const handleProfileUpdate = (e) => {
        setFormLoading(true);
        e.preventDefault();
        enrollCourse(currentUser.uid, data).then((msg) => {
            setMessage(msg);
            setData({})
            setFinish(true)
            location.assign('/profile/dashboard');
        })
            .catch((error) => {
                setFormLoading(false);
                setFinish(false)
                setMessage(error.message);
            });
    }
    return (
        <>
            <Grid item container sm={12} md className={classes.course_formBlock}>
                {finish ?
                    <Grid className={classes.course__title}>Вы успешно записались на курс, мы скоро с Вами свяжемся</Grid>
                    :
                    <>
                        <Grid className={classes.course__title}>Записаться на курс или получить бесплатную консультацию</Grid>
                        <form autoComplete="off" className={classes.form__size} onSubmit={handleProfileUpdate}>
                            <Grid className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.margin}
                                    placeholder="Имя"
                                    type="text"
                                    name="firstName"
                                    value={data.firstName}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <Grid className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.margin}
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    required
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <Grid className={classes.inputStyle}>
                                <InputBaseCustom
                                    className={classes.margin}
                                    placeholder="Телефон"
                                    type="tel"
                                    name="tel"
                                    value={data.tel}
                                    onChange={handleChange}
                                // inputProps={{ 'aria-label': 'naked' }}
                                />
                            </Grid>
                            <div className={classes.button}>
                                <ButtonComp disabled={formLoading} type="submit" title="Записаться на курс" />
                            </div>
                        </form>
                    </>
                }
            </Grid>
        </>
    )
}