import React, {useEffect} from "react";
import SkillsCoursesAll from "../../components/skillsCoursesAll";
import CardContent2 from "../../components/common/cardContent2";
import CardContentNoCorners from "../../components/common/cardMediaNoCorners";
import HistoryCourse from "../../components/historyCourse";
import ProgramCourse from "../../components/programCourse";
import RegisterCourse from "../../components/registerCourse";

import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    titleCourse: {
        fontSize: "2rem",
        fontFamily: 'Yeseva One'
    }

}))

export default function DescriptionСourses({data}) {
    const classes = useStyles();
    const {url} = useParams();
    function get(url) {
        const isItem = (p) => p.url === url;
        return data.find(isItem);
    }
    const course = get(url);
    if (!course) {
        return <div>Страница не найдена</div>;
    }
    return (
        <>
            <CardContentNoCorners imgUrl={course.urlImg} fullWidth>
                <CardContent2 title={course.name} description={course.history} />
            </CardContentNoCorners>
            <SkillsCoursesAll text={course.skills} />
            {/* <HistoryCourse history={course.history} /> */}
            <ProgramCourse data={course.programCourse} />
            <RegisterCourse price={course.price} courseName={course.name} publicCourse={data.public} />
        </>
    )
}
