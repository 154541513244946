import React from 'react';
import BorderContent from "../../../../components/common/borderContent";
import TasksItem from "./tasksItem";
import {tasks_lesson} from "../../api/data";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import {EditLocationOutlined} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    tasks: {
        padding: "20px"
    },
    tasks_content: {
        margin: "0px 20px 0px 20px"
    },
    tasks_subtopic: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One',
        // margin: "10px 0px 10px 20px",
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    tasks_subtopic_title: {
        fontFamily: 'Open Sans',
        fontSize: "0.9rem",
        margin: "8px auto",
        padding: "7px 0px 7px 0px",
        borderBottom: "3px solid rgb(153, 166, 60)",
    }
}))

export default function Tasks({dataTask, type = "test"}) {
    const classes = useStyles();
    return (
        <BorderContent classGrid={classes.tasks}>
            {
                type === 'test' ?
                    <>
                        <Grid item className={classes.tasks_subtopic}>Задания</Grid>
                        {
                            dataTask?.map((el) => {
                                return <TasksItem data={el} type={type} />
                            })
                        }
                    </>
                    :
                    <>
                        <Grid item xs={12} className={classes.tasks_subtopic}>Домашнее задание</Grid>
                        <Grid item xs={12} className={classes.tasks_subtopic_title}>Тема: {dataTask?.nameLesson}</Grid>
                        {
                            dataTask?.task.map((el) => {
                                return <TasksItem data={el} type={type} />
                            })
                        }
                    </>
            }

        </BorderContent>
    )
}