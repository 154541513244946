import React from 'react';
import {styled, Box} from '@mui/system';
import ModalUnstyled from '@mui/core/ModalUnstyled';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

`;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
    background: rgb(255 255 255 / 30%);
    //backdrop-filter: blur( 4px );
    //-webkit-backdrop-filter: blur( 4px );
`;

const style = {
    width: 400,
    //border: '2px solid #000',
    p: 2,
    px: 4,
    pb: 3,
    background: 'rgb(255 255 255 / 70%)',
    backdropFilter: 'blur( 4px )',
    '-webkit-backdrop-filter': 'blur( 4px )',
    border: '3px solid rgb(255 255 255)',
    boxShadow: '0 8px 32px 0 #66676f5e;',
};

const Modal = (props) => {
    const {open = true, handleClose, children} = props;

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
        >
            <Box sx={style}>
                {/*<h2 id="unstyled-modal-title">Добавить ошибку</h2>*/}
                {children}
            </Box>
        </StyledModal>
    )
}

export default Modal;
