import React, { useState } from "react";
import BorderContent from "../../../components/common/borderContent";
import ButtonComp from "../../../components/common/button";
import Title from "../../../components/common/title";
import InputBaseCustom from "../../../components/input";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import { HeightTwoTone } from "@material-ui/icons";
import Previews from "../../../components/uploadFile";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    form: {
        textAlign: "center"
    },
    section__form: {
        padding: "20px"
    },
    form__title: {
        fontSize: "2rem",
        fontFamily: 'Yeseva One, arial, sans-serif',
        margin: "0px 0px 20px 50px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    form__subtitle: {
        fontSize: "1.5rem",
        fontFamily: 'Russo One, arial, sans-serif',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem"
        }
    },
    formBlock: {
        // padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    inputStyle: {
        padding: "10px 10px 10px 0px",
        width: "100%"
    },
    button: {
        textAlign: "center"
    },
    form__left: {
        margin: "10px 0px 10px 0px",
        // paddingRight: "20px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        }
    },
    inputStyle_size: {
        minWidth: "150px",
    },
    form__consent: {
        marginTop: "20px"
    },
    paddingMain: {
        padding: "0px 50px 0px 50px",
        [theme.breakpoints.down("sm")]: {
            padding: "0px 20px 0px 20px",
        }
    },
    uploadAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        border: "1px dashed #7f838a",
        borderRadius: "5px",
        height: "100%"
    }
}))

export default function ProfileSetting() {
    const classes = useStyles();
    const [data, setData] = useState({ firstName: "", lastName: "", middleName: "", dateBirth: "", tel: "", tel2: "", address: "", email: "", oldPassword: "", newPassword: "", newRepeatPassword: "" });
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    return (
        <Grid item container xs={12} className={classes.formBlock}>
            <Grid className={classes.form__title}>Досье</Grid>
            <form autoComplete="off" className={classes.form}>
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item sm={12} md={6} className={classes.section__form}>
                        <BorderContent classMain={classes.paddingMain} fullWidth fill="white">
                            <Grid className={classes.form__subtitle}>Аватар</Grid>
                            <Previews imgAvatar="/static/img/avatar/avatar1.jpg" />
                        </BorderContent>
                    </Grid>
                    <Grid item sm={12} md={6} className={classes.section__form}>
                        <BorderContent classMain={classes.paddingMain} fullWidth>
                            <Grid className={classes.form__subtitle}>Личная информация</Grid>
                            <Grid item xs={12} container className={classes.form__left}>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Имя"
                                        type="text"
                                        name="firstName"
                                        value={data.firstName}
                                        onChange={handleChange}
                                        required
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Фамилия"
                                        type="text"
                                        name="lastName"
                                        value={data.lastName}
                                        onChange={handleChange}
                                        required
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Отчество"
                                        type="text"
                                        name="middleName"
                                        value={data.middleName}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Дата рождения"
                                        type="date"
                                        name="dateBirth"
                                        value={data.dateBirth}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                            </Grid>
                        </BorderContent>
                    </Grid>
                    <Grid item sm={12} md={6} className={classes.section__form}>
                        <BorderContent classMain={classes.paddingMain} fullWidth fill="white">
                            <Grid className={classes.form__subtitle}>Смена пароля</Grid>
                            <Grid item xs={12} container className={classes.form__left}>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Старый пароль"
                                        type="password"
                                        name="oldPassword"
                                        value={data.oldPassword}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Новый пароль"
                                        type="password"
                                        name="newPassword"
                                        value={data.tel}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Повторить пароль"
                                        type="password"
                                        name="newRepeatPassword"
                                        value={data.newRepeatPassword}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                            </Grid>
                        </BorderContent>
                    </Grid>
                    <Grid item sm={12} md={6} className={classes.section__form}>
                        <BorderContent classMain={classes.paddingMain} fullWidth fill="white">
                            <Grid className={classes.form__subtitle}>Контакты</Grid>
                            <Grid item xs={12} container className={classes.form__left}>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Телефон"
                                        type="tel"
                                        name="tel"
                                        value={data.tel}
                                        onChange={handleChange}
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Контакты родителя"
                                        type="tel"
                                        name="tel2"
                                        value={data.tel2}
                                        onChange={handleChange}
                                        required
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        value={data.email}
                                        onChange={handleChange}
                                        required
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                                <Grid item xs className={classes.inputStyle}>
                                    <InputBaseCustom
                                        className={classes.inputStyle_size}
                                        placeholder="Адрес"
                                        type="text"
                                        name="address"
                                        value={data.address}
                                        onChange={handleChange}
                                        required
                                    // inputProps={{ 'aria-label': 'naked' }}
                                    />
                                </Grid>
                            </Grid>
                        </BorderContent>
                    </Grid>
                </Grid>
                <ButtonComp type="submit" title="Сохранить изменения" />
            </form>
        </Grid >

    )
}