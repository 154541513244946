import React, {useState, useEffect} from 'react';
import EditCourses from "./editCourses";
import {useAuth} from '../../contexts/AuthContext';
import {getAllCourse} from '../../adapters/getProfile';
import BorderContent from "../../components/common/borderContent";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    lesson: {
        padding: "20px"
    },
}))
// getUserCourse
export default function DashboardCourses() {
    const classes = useStyles();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const {currentUser} = useAuth();

    useEffect(() => {
        setLoading(true);
        getAllCourse(currentUser.uid).then((result) => {
            setData(result)
        }).catch((error) => {
            console.error(error);
        });

    }, [])
    return (
        <>
            <EditCourses data={data} />
            {data && data.map((el, index) => {
                const ent = Object.entries(el.tests);
                return ent.map((test) => (
                    <BorderContent classGrid={classes.lesson} fullWidth>
                        <div>{test[0]} пройден</div>
                    </BorderContent>

                ))
            })}
        </>
    )
}