import React from "react";
import Title from "./common/title";
import CardMedia from "./common/cardMedia";
import { ReactComponent as CertificateIcon } from "../static/svg/certificate.svg";
import { ReactComponent as CertificateTwoIcon } from "../static/svg/certificateTwo.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  news__cards: {},
  content: {
    // height: "80%",
    alignContent: "start"
    // paddingTop: "70px"
  },
  content__block_subtitle: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1.2rem"
  },
  content__block_title: {
    fontFamily: "Russo One, sans-serif",
    fontSize: "2rem"
  },
  content__block_img_size: {
    width: "150px"
  }
}));

export default function Advantage() {
  const classes = useStyles();
  return (
    <Grid container style={{ paddingTop: "70px" }}>
      <Title title="Преимущества" />
      <Grid item container className={classes.content}>
        <CardMedia
          imgUrl={
            <CertificateIcon className={classes.content__block_img_size} />
          }
        >
          <Typography
            variant="subtitle1"
            className={clsx(classes.content__block_subtitle)}
          >
            Обучающийся, прошедший обучение в «Учебном центре», успешно сдавший
            тесты и экзамены на всех трех этапах, является
            <Typography
              variant="h4"
              component="span"
              className={clsx(classes.content__block_title)}
            >
            {" "}специалистом III класса{" "}
            </Typography>
          </Typography>
        </CardMedia>
        <CardMedia
          imgUrl={
            <CertificateTwoIcon className={classes.content__block_img_size} />
          }
        >
          <Typography
            variant="subtitle1"
            className={clsx(classes.content__block_subtitle)}
          >
            При поступлении в УрФУ, выпускники Учебного центра получают
            <Typography
              variant="h4"
              component="span"
              className={clsx(classes.content__block_title)}
            >
              {" "}
              дополнительные баллы,{" "}
            </Typography>
            а ставь студентом продолжают дальнейшее обучение в Военном учебном
            центре по выбранной военной специальности.
          </Typography>
        </CardMedia>
      </Grid>
    </Grid>
  );
}
