import React from 'react';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    main: {
        flex: "1 1 0",
        maxWidth: "1400px",
        padding: "60px 80px 30px 80px",
        // width: "70%",
        width: "calc(100% - 280px)",
        marginLeft: "280px",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "100px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "10px 25px 10px 25px",
            marginLeft: "0px"
        }
        // width: "700px"
    },
    content: {
        // height: "80%",
        alignContent: "start"
    },
    content__block: {
        width: '100%',
        backgroundColor: "#F7F7F7",
        padding: "0px 45px 0px 45px",
        position: "relative",
        "&::before": {
            left: "0",
            width: "8%",
            height: "100%",
            content: "''",
            position: "absolute",
            zIndex: "-1",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        },
        "&::after": {
            top: "0",
            right: "0",
            width: "8%",
            height: "100%",
            content: "''",
            position: "absolute",
            zIndex: "-1",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 25px 0px 25px"
        }
    },
    content__block_left: {
        [theme.breakpoints.down("xs")]: {
            order: "2"
        }
    },
    content__block_right: {
        textAlign: "center"
    },
    content__block_position: {
        // position: "absolute"
    },
    content__block_title: {
        fontFamily: "Yeseva One",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.5rem"
        }
    },
    content__block_subtitle: {
        fontFamily: "Open Sans",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem"
        }
    },
    content__block_padding: {
        padding: "0px 0px 20px 0px"
    },
    content__block_right_size: {
        maxWidth: "300px",
        minWidth: "200px"
    },
    content__block_size: {
        height: "30px",
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            height: "25px"
        }
    },
    content__block__leftTop: {
        transform: "skewX(-50deg)",
        transformOrigin: "bottom"
    },
    block__leftTop_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            left: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    block__rightTop_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            right: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    content__block__rightTop: {
        transformOrigin: "bottom"
    },
    content__block__leftBottom: {
        // transform: "skewX(-50deg)",
        transformOrigin: "top",
        [theme.breakpoints.down("xs")]: {
            height: "35px"
        }
    },
    block__leftBottom_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            left: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    content__block__rightBottom: {
        transform: "skewX(-50deg)",
        transformOrigin: "top",
        zIndex: "-1",
        [theme.breakpoints.down("xs")]: {
            height: "35px"
        }
    },
    block__rightBottom_shadow: {
        position: "relative",
        height: "100%",
        background: "#F7F7F7",
        "&::before": {
            right: 0,
            width: "89%",
            height: "100%",
            content: "''",
            position: "absolute",
            boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)",
            zIndex: -1
        }
    },
    buttonBack: {
        cursor: "pointer",
        fontFamily: "Russo One, Open Sans",
        fontSize: "0.9rem",
        textAlign: "center",
        margin: "20px 0px 0px 0px",
    },
    buttonNext: {
        cursor: "pointer",
        fontFamily: "Russo One, Open Sans",
        fontSize: "1.2rem",
        textAlign: "center",
        margin: "20px 0px 0px 0px",
    },
    titlePage: {
        fontFamily: 'Yeseva One, Open Sans',
        fontSize: '2rem',
        margin: '0px 0px 20px 0px',
    },
    listQuestions: {
        fontFamily: "Russo One, Open Sans",
        fontSize: "1rem",
        display: 'flex',
        alignItems: 'center',
        margin: '0px 0px 20px 0px',
        fontSize: '0.8rem',
    },
    listQuestions__list: {
        display: 'flex',
        listStyleType: 'none',
    },
    listQuestions__number: {
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 10px',
    },
}));


const Tests = ({children, answers, handleReset, steps, handleBack, handleNext, activeStep}) => {
    const classes = useStyles();

    return (
        <div>
            {/*<div className={classes.titlePage}>Тест: "Вспомнить все!"</div>*/}
            <div className={classes.listQuestions}>
            </div>
            <Grid item container className={classes.content}>
                <Grid
                    item
                    className={clsx(
                        classes.content__block__leftTop,
                        classes.content__block_size
                    )}
                >
                    <div className={classes.block__leftTop_shadow}></div>
                </Grid>
                <Grid
                    item
                    className={clsx(
                        classes.content__block__rightTop,
                        classes.content__block_size
                    )}
                >
                    <div className={classes.block__rightTop_shadow}></div>
                </Grid>
                <div
                    className={classes.content__block}
                >
                    {children}
                </div>
                <Grid
                    item
                    className={clsx(
                        classes.content__block__leftBottom,
                        classes.content__block_size
                    )}
                >
                    <div className={classes.block__leftBottom_shadow}></div>
                </Grid>
                <Grid
                    item
                    className={clsx(
                        classes.content__block__rightBottom,
                        classes.content__block_size
                    )}
                >
                    <div className={classes.block__rightBottom_shadow}></div>
                </Grid>
            </Grid>
            {activeStep === steps.length ? (
                <>
                    <div className={classes.buttonNext} onClick={handleReset}>Повторить</div>
                </>
            ) : (
                <>
                    <div className={classes.buttonNext} onClick={handleNext}>Следующий вопрос</div>
                </>
            )}

            {activeStep !== 0 && <div className={classes.buttonBack} onClick={handleBack}>Назад</div>}

        </div>
    )
}

export default Tests;