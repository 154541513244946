import React from "react";
import BorderContent from "../components/common/borderContent";
import ListItemDescription from "../components/listItemDescription";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Title from "./common/title";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "2rem",
        fontFamily: 'Russo One',
        // margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course__numberBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
        }
    }

}))

export default function ProgramCourse({data}) {
    const classes = useStyles();
    return (
        <>
            <Title title="Программа курса" />
            {
                data.map((el) => {
                    const number = parseInt(el.id) + 1
                    return (
                        <BorderContent fullWidth classGrid={classes.main}>
                            <Grid item xs={12} sm={3} className={classes.course__numberBlock}>Блок №{number}</Grid>
                            <Grid item xs={12} sm={9} container>
                                <Grid className={classes.course__title}>{el.titleBlock}</Grid>
                                <ListItemDescription data={el.description} />
                            </Grid>
                        </BorderContent>
                    )

                }
                )
            }
        </>
    )
}