import React, { useEffect, useState } from "react";
import ButtonComp from "../../components/common/button";
import AvatarProfile from "../../components/avatar";
import PanelListIcon from "../../components/profile/panelListIcon";
import { useAuth } from '../../contexts/AuthContext';
import { getUserProfile } from '../../adapters/getProfile';

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    aside: {
        flex: "0 1 280px",
        width: "280px",
        height: "100%",
        backgroundColor: "#F7F7F7",
        position: "fixed",
        zIndex: "1",
        [theme.breakpoints.down("sm")]: {
            width: "100px",
            flex: "0 1 100px"
        },
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    aside__block: {
        padding: "30px 40px 30px 40px",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: "30px 5px 30px 5px"
        }
        // textAlign: "center"
    },
    aside__button: {
        textAlign: "center"
    },
    aside__block__item_padding: {
        padding: "40px 0px 0px 0px",
    },
    aside__partners: {
        fontSize: "1rem",
        fontFamily: "Open Sans"
    },
    contant__item: {
        paddingRight: "10px"
    },
    item__icon: {
        width: "50px",
        margin: "10px"
    },
    item__iconSocial: {
        width: "25px",
        margin: "10px"
    },
    aside_icon: {
        marginBottom: "20px",
        color: "#99a63c"
    },
    nameProfile: {
        textAlign: "center",
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: "bold",
        fontSize: "1.4rem",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        }
    }
}));

export default function Aside({ img, menuStudent, menuTeacher, footer }) {
    const classes = useStyles();
    const [role, setRole] = useState();
    const { signout, currentUser } = useAuth();
    const { displayName } = currentUser;
    const fullName = displayName && displayName.split(" ");
    const history = useHistory();


    const handleSignout = () => {
        signout();
        // setShowDrawer((prev) => !prev);
        history.push('/auth');
    };

    useEffect(() => {
        getUserProfile(currentUser.uid)
            .then((doc) => {
                setRole(doc.data().role);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    const nav = role === "student" ? menuStudent : menuTeacher;
    return (
        <Grid item container className={classes.aside}>
            <Grid item xs className={classes.aside__block}>
                <Grid item container justify="center">
                    <PanelListIcon />
                    <AvatarProfile img={img} />
                    <p className={classes.nameProfile}>{fullName && fullName[0]}</p>
                    <p className={classes.nameProfile}>{fullName && fullName[1]}</p>
                    {/* <Link to="/"><Logo2Icon /></Link> */}
                </Grid>
                <Grid item className={clsx(classes.aside__block__item_padding, classes.aside__button)}>
                    <ButtonComp
                        onClick={handleSignout}
                        title="Выход"
                        viewButton="signIn"
                        startIcon={<AccountCircleIcon style={{ color: "#99A63C" }} />}
                    />
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    className={classes.aside__block__item_padding}
                >
                    {nav}
                </Grid>
                {footer}
            </Grid>
        </Grid >
    );
}
