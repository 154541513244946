import React from "react";
import Aside from "../../layout/profile/aside";
import MenuProfile from "./menu";
import MenuProfileTeacher from "./menu/navTeacher";
import Wrapper from "../../layout/profile/wrapper";
import RouterProfile from "./router";

export default function Profile() {
    return (
        <div>
            <Aside img="/static/img/avatar/avatar1.jpg"
                menuStudent={<MenuProfile />}
                menuTeacher={<MenuProfileTeacher />}
            />
            <Wrapper content={<RouterProfile />} />
        </div>
    )
}