import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import Section from '../../components/ui/Section';
import FolderIconTitle from '../../components/ui/FolderIconTitle';
import CardFolder from '../../components/common/cardFolder';
import ListMedia from './ListMedia';
import MediaItem from './MediaItem';

import {ReactComponent as Read} from '../../static/svg/read_big.svg';
import {ReactComponent as Listen} from '../../static/svg/listen_big.svg';
import {ReactComponent as PlayVideo} from '../../static/svg/play_big.svg';

import {dataMedia} from './dataMedia';

const useStyles = makeStyles((theme) => ({
    search: {
        borderBottom: '1px solid #000000',
        marginBottom: '35px',
    },
    main: {
        //display: 'grid',
        //gridTemplateColumns: 'repeat(auto-fit, minmax(200px, auto))',
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: '25px',
    },
    main__item: {
        flex: '1 1 0',
    },
    popular: {
        margin: '20px 0px'
    },
    popular__title: {
        fontSize: '1rem',
        fontFamily: 'Russo One, Open Sans',
        marginBottom: '10px',
    },
    popular__item: {
        listStyleType: 'none',
        padding: '10px 0px'
    },
    category: {

        //whiteSpace: 'nowrap',
        //textAlign: 'center',
        //border: '0px',
    },
    category__header: {
        //display: 'inline-block',
        //padding: '10px 0px',
        width: '100%',
        margin: '35px 0px',
        flexShrink: '0',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        boxSizing: 'border-box',
        "&::after": {
            content: "''",
            position: 'relative',
            borderTop: 'thin solid rgba(0, 0, 0, 0.22)',
            WebkitTransform: ' translateY(50%)',
            MozTransform: 'translateY(50%)',
            MsTransform: 'translateY(50%)',
            transform: 'translateY(50%)',
        }
    },
    title_padding: {
        fontSize: '1.2rem',
        fontFamily: 'Russo One, Open Sans',
        paddingRight: '15px',
        textTransform: 'uppercase',
    },
    category__list: {
        display: 'flex',
        gridGap: '25px',
    },
    category__item: {
        textAlign: 'center',
    },
    category__title_h1: {
        fontSize: '1rem',
        fontFamily: 'Play, Open Sans',
    },
    category__title_h2: {
        fontSize: '1.2rem',
        fontFamily: 'Russo One, Open Sans',
    },
    list__media: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 200px)',
        gridGap: '20px',
        margin: '35px 0px',
    }
}));

const LibraryItemPage = () => {
    const classes = useStyles();
    const {path} = useRouteMatch();
    return (
        <>
            <Section title="Библиотека">
                {/*<div className={classes.search}>Search</div>*/}
                <div className={classes.main}>
                    <div className={classes.main__item}>
                        <FolderIconTitle
                            title="Почитать"
                            icon={<Read style={{fontSize: '3rem'}} />}
                        />
                    </div>
                    <div className={classes.main__item}>
                        <FolderIconTitle
                            title="Посмотреть"
                            icon={<PlayVideo style={{fontSize: '3rem'}} />}
                        />
                    </div>
                    <div className={classes.main__item}>
                        <FolderIconTitle
                            title="Послушать"
                            icon={<Listen style={{fontSize: '3rem'}} />}
                        />
                    </div>
                </div>
                <div className={classes.category}>
                    <div className={classes.category__header}>
                        <span className={classes.title_padding}>
                            Медиатека
                        </span>
                    </div>
                    {/*<div className={classes.category__list}>
                        <CardFolder>
                            <div className={classes.category__item}>
                                <div className={classes.category__title_h1}>Огневая подготовка</div>
                                <div className={classes.category__title_h2}>23</div>
                            </div>
                        </CardFolder>
                        <CardFolder>
                            <div className={classes.category__item}>
                                <div className={classes.category__title_h1}>История</div>
                                <div className={classes.category__title_h2}>42</div>
                            </div>
                        </CardFolder>
                        <CardFolder>
                            <div className={classes.category__item}>
                                <div className={classes.category__title_h1}>Боевые традиции</div>
                                <div className={classes.category__title_h2}>15</div>
                            </div>
                        </CardFolder>
                        <CardFolder>
                            <div className={classes.category__item}>
                                <div className={classes.category__title_h1}>Другое</div>
                                <div className={classes.category__title_h2}>84</div>
                            </div>
                        </CardFolder>
                    </div>*/}

                    <Switch>
                        <Route exact path={path}>
                            <ListMedia />
                        </Route>
                        <Route path={`${path}/media/:url`}>
                            <MediaItem data={dataMedia} />
                        </Route>
                    </Switch>

                </div>
            </Section>
        </>
    )
}

export default LibraryItemPage;