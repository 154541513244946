import React, {useState, useRef, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";
import clsx from 'clsx';
import CardFolder from '../../components/common/cardFolder';
import ScrollDialog from '../../components/ui/Dialog';
import ItemDescription from './item_description3';
import CardItem from './item3';
import {dataCourse} from './dataCourse';
import LessonDescription from './lessonDescription';
import {getStudent, getLessons, setLessons} from '../../adapters/getProfile';
import './style.css';


const useStyles = makeStyles((theme) => ({
    main: {
        display: 'grid',
        gridGap: '50px',
    },
    title__wrapper: {
        fontSize: '1.8rem',
        margin: '20px 0px 20px 0px',
        fontFamily: 'Yeseva One, Open Sans',
    },
    block__modal: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '1300',
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        //backdropFilter: 'blur(13px)',
    },
    modal__content: {
        width: '100%',
        height: '100%',
        background: 'white',
        //backdropFilter: 'blur(10px)',
        position: 'absolute',
        zIndex: '1400',

        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
    },
    modalCloneHidden: {
        display: 'none',
    },
    modal__content_visite: {
        animation: '$slideDown2 0.5s forwards',
        overflow: 'hidden',
        boxSizing: 'border-box',
        padding: '30px',
    },
    block__course: {
        margin: '10px 0px',

    },
    course__name: {
        fontFamily: 'Open Sans',
    },
    course__title: {
        fontSize: '2rem',
        fontFamily: 'Play, Open Sans',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4rem',
        },
    },
    block__course__lesson: {
        //margin: '35px 0px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRow: '1fr 1fr',
            gridGap: '0px',
        },
    },
    info__lesson: {
        padding: '10px',
        margin: '10px 0px',
        //background: 'white',
        border: '1px solid #c4c4c4'
    },
    data__lesson: {
        padding: '10px 0px'
    },
    "@keyframes slideDown2": {
        from: {
            left: '0',
            top: '0',
        },
        to: {
            transform: 'translate3d(-50%, -50%, 0px)',
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            width: '80%',
            height: '80%',
        }
    },
    bg__modal: {
        //background: '#d2d2d291',
        background: '#ffffff38',
        backdropFilter: 'blur(10px)',
        position: 'fixed',
        width: '100%',
        height: '100%',
        animation: '$to_come_in 0.4s forwards',
    },
    "@keyframes to_come_in": {
        from: {
            opacity: '0',
        },
        to: {
            opacity: '1',
        }
    },
    enterClassItem: {
        animation: '$enter 0.5s forwards',
        transformOrigin: 'left top',
    },
    "@keyframes enter": {
        from: {
            transform: 'scale(0.7)',
        },
        to: {
            transform: 'scale(1)',
        }
    },
    outputClassItem: {
        animation: '$output 0.5s forwards',
        transformOrigin: 'left top',
    },
    "@keyframes output": {
        from: {
            transform: 'scale(1)',
        },
        to: {
            transform: 'scale(0.7)',
        }
    },
}));

const DashboardPage = () => {
    const classes = useStyles();
    const [openDialogStart, setOpenDialogStart] = useState(false);
    const [activeBlock, setActiveBlock] = useState('нет');

    const {path} = useRouteMatch();

    useEffect(() => {
        //getStudent('123').then((result) => {
        //    console.log(result)
        //}).catch((error) => {
        //    console.error(error);
        //});

        //getLessons().then((result) => {
        //    //console.log(result)
        //}).catch((error) => {
        //    console.error(error);
        //});

        //numberLesson, id, status, titleLesson, urlLesson, material

        /*
        let startData = new Date();
        for (let b = 0; b < dataCourse.length; b++) {
            const {sections} = dataCourse[b];
            for (let s = 0; s < sections.length; s++) {
                const {lessons} = sections[s];
                for (let l = 0; l < lessons.length; l++) {
                    const lesson = lessons[l];
                    console.log(lesson);
                    const {idLesson, title} = lesson;
                    startData.setDate(startData.getDate() + 3);
                    setLessons(
                        'lesson' + (idLesson + 1),
                        idLesson + 1,
                        startData,
                        'не начато',
                        title,
                        'url',
                        [{name: 'Презентация', url: 'ссылка для скачивания'}]
                    ).then((result) => {

                    }).catch((error) => {
                        console.log('error', error)
                    })
                }
            }
        }
        */

    }, [])

    return (
        <div className={classes.wrapper}>
            <div className={classes.main}></div>
            <Switch>
                <Route exact path={path}>
                    <CardItem
                        activeBlock={activeBlock}
                        enterClass={classes.enterClassItem}
                        outputClass={openDialogStart && classes.outputClassItem}
                    />
                </Route>
                <Route path={`${path}/:block/:section/:id`}>
                    <div>
                        <LessonDescription
                            setActiveBlock={setActiveBlock}
                        />
                    </div>
                </Route>
            </Switch>
        </div>
    )
}

export default DashboardPage;
