import React from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import {ReactComponent as DelAnswerIcon} from "../../../static/svg/del.svg";
import {ReactComponent as ExpandIcon} from "../../../static/svg/expand_more.svg";


const useStyles = makeStyles((theme) => ({
    content__header: {
        display: 'grid',
        gridGap: '20px',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr auto',
    },
    header__title: {
        fontSize: '1rem',
        fontFamily: 'Russo One, Open Sans',
    },
    rollButton: {

    },
    icon_fill: {
        fill: '#C5C5C5',
        '&:hover': {
            fill: '#787977'
        }
    },
    rollIcon: {
        transform: 'rotate(0deg)',
        transition: 'transform 0.6s',
    },
    rollIcon__close: {
        transform: 'rotate(180deg)',
    },
}));

const HeaderModule = (props) => {
    const {
        numberElement = false,
        title,
        roll,
        rollUp,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.content__header}>
            <div className={classes.header__title}>{title} {numberElement >= 0 && numberElement}</div>
            <div className={classes.rollButton}><IconButton className={classes.icon_fill} onClick={rollUp}><ExpandIcon className={clsx(classes.rollIcon, roll && classes.rollIcon__close)} /></IconButton></div>
            <div><IconButton><DelAnswerIcon /></IconButton></div>
        </div>
    )
}

export default HeaderModule;
