import React, {useState} from "react";
import BorderContent from "./common/borderContent";
import ButtonComp from "./common/button";
import Title from "./common/title";
import CheckboxStyle from "../components/common/checkbox";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {useHistory} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

const InputBaseCustom = withStyles({
    root: {
        backgroundColor: "#E2E2E2",
        transition: "hover 0.4s",
        width: "100%",
        '&:after': {
            right: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderRight: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.25s ease",
            opacity: "0",
        },
        '&:before': {
            left: "-6px",
            width: "7px",
            content: "''",
            height: "110%",
            position: "absolute",
            borderTop: "2px solid #000",
            borderLeft: "2px solid #000",
            borderBottom: "2px solid #000",
            transition: "opacity 0.1s",
            opacity: "0",
        },
        '&:hover': {
            '&:after': {
                opacity: "1",
            },
            '&:before': {
                opacity: "1",
            },
        },
    },
    input: {

        //   '&:focus': {
        //     backgroundColor: "#f7f7f7" ,
        //   },

        padding: "10px",

    }
})(InputBase);

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "50px 0px 50px 0px"
    },
    course__title: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
        }
    },
    course_priceBlock: {
        fontSize: "1.2rem",
        fontFamily: 'Russo One',
        margin: '0px 0px 10px 0px',
        padding: "35px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "30px 0px 30px 0px",
        }
    },
    course_formBlock: {
        // padding: "35px 0px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
        }
    },
    course_priceBlock_amount: {
        fontSize: "2rem"
    },
    inputStyle: {
        padding: "10px 0px 10px 0px"
    },
    form: {
        flex: "1 0 100%",
    },
    button: {
        textAlign: "right"
    },
    course_priceBlock__border: {
        [theme.breakpoints.up("sm")]: {
            borderRight: "1px solid #bbc764",
            width: "100%",
            height: "100%",
        }

    },
    form__left: {
        margin: "10px 0px 10px 0px",
        // paddingRight: "20px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        }
    },
    inputStyle_size: {
        minWidth: "150px",
    },
    form__padding: {
        padding: "0px 25px 0px 25px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px 0px 10px",
        }
    },
    rememberCheckbox: {

    },
    label: {
        fontSize: "0.9rem",
        fontFamily: "Open Sans",
        fontWeight: "100",
        lineHeight: "1",
    },
    forgot_password_link: {
        marginTop: "15px",
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: "100",
        lineHeight: "1",
        color: "#0061FF",
        'a': {
            color: "#0061FF",
        }
    },
    buttonLink: {
        cursor: "pointer"
    }
}))

export default function SignIn({goResetPassword, noRedirect}) {
    const classes = useStyles();
    const [data, setData] = useState({email: "", password: ""});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const {signin} = useAuth();
    const history = useHistory();

    const handleChange = (e) => {
        e.preventDefault();
        setData({...data, [e.target.name]: e.target.value});
    };

    const handleSignin = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        const {email, password} = data;
        signin(email, password)
            .then((ref) => {
                setLoading(false);
                if (!noRedirect) {
                    history.push('/profile/dashboard');
                }

            })
            .catch((error) => {
                if (error.code === "auth/wrong-password") {
                    setError('Проверьте введенные данные');
                } else if ("auth/user-not-found") {
                    setError('Пользователь с таким email не найден');
                }
                setLoading(false);
            });
    };

    const goPageReresetPassword = () => {
        goResetPassword()
    }

    return (
        <form autoComplete="off" className={classes.form} onSubmit={handleSignin}>
            <div className={classes.form__padding}>
                <Grid container className={classes.form__left}>
                    <Grid item xs={12} className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputStyle}>
                        <InputBaseCustom
                            className={classes.inputStyle_size}
                            placeholder="Пароль"
                            type="password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            required
                        // inputProps={{ 'aria-label': 'naked' }}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs={6} className={classes.rememberCheckbox}>
                        <FormControlLabel
                            value="end"
                            control={<CheckboxStyle />}
                            label={<div className={classes.label}>Запомнить</div>}
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.button}>
                        <ButtonComp disabled={loading} type="submit" title="Войти" />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.forgot_password_link}>
                    <div className={classes.buttonLink} onClick={goPageReresetPassword}>Забыли пароль?</div>
                </Grid>
                {error && error}
            </div>
        </form>
    )
}