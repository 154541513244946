import React from "react";
import Header from "../../components/header";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Route, Switch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    main: {
        flex: "1 1 0",
        maxWidth: "1400px",
        padding: "60px 80px 30px 80px",
        // width: "70%",
        marginLeft: "280px",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "100px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px 25px 10px 25px",
            marginLeft: "0px",
            width: "100%",
        }
    }
}));

export default function Wrapper({ content }) {
    const classes = useStyles();
    return (
        <Grid item className={classes.main}>
            <Header />
            {content}
        </Grid>
    );
}
