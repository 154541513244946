import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    listItem__topic: {
        width:"100%",
        fontSize:"1rem",
        fontFamily: "Open Sans",
        fontWeight: "900",
        borderBottom: "1px solid black",
        margin: "20px 0px 10px 0px",
    }

}))

const Accordion = withStyles({
    root: {
    //   border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      background: "none",
      width:"100%",
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0',
      },
      '&$Mui-disabled':{
        
      }
    },
    disabled:{
      backgroundColor: "none",
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'red',
    //   borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      padding:"0",
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
        
      padding: "0px 16px 16px 16px",
    },
  }))(MuiAccordionDetails);
  

export default function аListItemDescription({data}){
    const classes = useStyles();
    return(
        <>
            {data.map(el => (
                <>
                <Accordion>
                    <AccordionSummary
                    expandIcon={el.listItem.length !== 0 ? <ExpandMoreIcon /> : undefined }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <div className={classes.listItem__topic}>{el.topic}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>{el.listItem.map((text,id) => (
                        <div>{++id}. {text}</div>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
                </>                  
            ))}
        </>
    )
}
