import React from "react";
import BorderContent from "./common/borderContent";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import clsx from "clsx";

const AvatarCustom = withStyles({
    root: {
        width: "120px",
        height: "120px",

    },
    "@media (max-width: 960px)": {
        root: {
            width: "70px",
            height: "70px",
        }
    },
    input: {

    }
})(Avatar);

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: "70%",
        clipPath: `polygon(20% 0%, 100% 0%, 100% 85%, 80% 100%, 0% 100%,  0% 15%);`,
    },
    avatar__border: {
        border: "1px solid red",
    },
    small: {
        width: "70px",
        height: "70px",
    },
    avatar_img_box: {

        // "&::before": {
        //     left: "0",
        //     width: "8%",
        //     height: "100%",
        //     content: "''",
        //     position: "absolute",
        //     zIndex: "1",
        //     boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        // },
        // "&::after": {
        //     right: "0",
        //     width: "8%",
        //     height: "100%",
        //     content: "''",
        //     position: "absolute",
        //     zIndex: "1",
        //     boxShadow: "1px 1px 4px 0px rgb(50 50 50 / 12%)"
        // },
    }
}));


export default function AvatarProfile({ img, small }) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.avatar_img_box}>
                <AvatarCustom src={img} className={clsx(small && classes.small)} />
                {/* <img src={img} alt=" " className={classes.avatar} /> */}
            </div>
        </>
    )
}