import React from "react";

import { ReactComponent as LogoGruIcon } from "../../static/svg/logo-gru.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    about__main: {
        fontSize: "1rem"
    },
    about_title: {
        fontFamily: "Play, Open Sans, sans-serif",
        // margin: "20px 0px 10px 0px"
    },
    about: {
        margin: "20px 0px 30px 0px"
    },
    about_its: {
        fontFamily: "Play, Open Sans, sans-serif",
        fontSize: "2rem"
    },
    about_h1: {
        float: "left"
    },
    about_h2: {
        margin: "0px 0px 15px 0px"
    },
    about__item__title: {
        padding: "40px",
        marginBottom: "40px",
        // border: "3px solid #f5dd12",
        boxShadow: "1px 1px 14px 0px rgb(50 50 50 / 12%)",
        borderRadius: "4px",
    },
    about__item__one: {
        padding: "20px",
        border: "3px solid #9eaa45",
        margin: "20px",
        borderRadius: "4px",
    },
    about__item__two: {
        padding: "20p;"
    },
    about__item__block: {
        marginTop: "20px"
    },
    about__item__block__bottom: {
        textAlign: "center"
    },
    about__bottom__item: {
        fontFamily: "Play",
        fontSize: "1.5rem"
    }
}));

export default function AboutPage() {
    const classes = useStyles();
    return (
        <div className={classes.about__main}>
            <div className={classes.about}><h1 className={clsx(classes.about_title, classes.about_h1)}>Учебный центр ГРУ</h1><span className={classes.about_title, classes.about_its}>{" "}- это</span></div>
            <Grid container>
                <Grid item className={classes.about__item__title}>
                    <Grid container>
                        <h2 className={clsx(classes.about_title, classes.about_h2)}>учебная платформа</h2>
                        <p>Площадка для программ дистанционного обучения, позволяющая освоить большой объем информации в формате интерактивных занятий, видео уроков и тестирований.</p>
                    </Grid>
                    <Grid container className={classes.about__item__block}>
                        <Grid item xs className={classes.about__item__one}>Многопрофильные курсы для детей и взрослых, курсы переподготовки.</Grid>
                        <Grid item xs className={classes.about__item__one}>Система мониторинга успеваемости.</Grid>
                        <Grid item xs className={classes.about__item__one}>Наставники из числа действующих офицеров и ветеранов спецназа.Преподаватели профильных вузов</Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.about__item__title}>
                    <h2 className={clsx(classes.about_title, classes.about_h2)}>учебный полигон</h2>
                    <p >Площадка для закрепления теоретических знаний на практике с использованием тренажеров, радиостанций, боевых машин и т.д.</p>
                    <Grid container className={classes.about__item__block}>
                        <Grid item xs className={classes.about__item__one}>Учебные тренажеры, полоса препятствий</Grid>
                        <Grid item xs className={classes.about__item__one}>Стрельбы, воздушно-десантная подготовка</Grid>
                        <Grid item xs className={classes.about__item__one}>Полевая практика, курс выживания</Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.about__item__block__bottom}>
                <p className={clsx(classes.about, classes.about__bottom__item)}>"Учебный центр ГРУ" был учрежден при поддержке Союза ветеранов спецназа ГРУ</p>
                <LogoGruIcon width="100px" />
            </div>
        </div>
    )
}