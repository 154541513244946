import React from "react";
import ButtonComp from "./button";
import { ReactComponent as CertificateIcon } from "../../static/svg/certificate.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  content__block: {
    // backgroundColor: "#F7F7F7",
    marginBottom: "20px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      margin: "25px 0px 25px 0px"
    }
  },
  content__block_text: {
    [theme.breakpoints.down("xs")]: {
      order: "2"
    }
  },
  content__block_img: {
    textAlign: "center",
    margin: "0px 40px 40px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px 20px 0px"
    }
  },
  content__block_padding: {
    padding: "0px 0px 20px 0px"
  },
  side: {
    order: 2,
    marginLeft: "40px",
    marginRight: "0px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 0px 20px 0px"
    }
  }
}));

const CardContent = ({
  side = "left",
  imgUrl,
  titleButton,
  onClick,
  children
}) => {
  const classes = useStyles();
  return (
    <Grid item container alignItems="center" className={classes.content__block}>
      <Grid
        item
        className={clsx(
          classes.content__block_img,
          side === "right" && classes.side
        )}
      >
        {imgUrl}
      </Grid>
      <Grid item xs className={clsx(classes.content__block_text)}>
        {children}

        {titleButton && (
          <ButtonComp
            title={titleButton}
            endIcon={<ArrowRightAltIcon onClick={onClick} />}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default CardContent;
