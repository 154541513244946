import React from "react";
import {ReactComponent as GoogleIcon} from "../static/svg/googleDowload.svg";
import {ReactComponent as ApplyIcon} from "../static/svg/applyDowload.svg";
import {config} from "../config";

import {Link} from "react-router-dom";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "60px 0px 60px 0px",
    position: "absolute",
    zIndex: "3",
    background: "#efefef",
    borderTop: "1px solid white"
  },
  containerPadding: {
    padding: "40px 0px 0px 0px"
  },
  grid__logo: {
    flex: "0 0 280px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
      flex: "0 1 100px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flex: "0 1 100%"
    }
  },
  grid__text: {
    flex: "1",
    color: "#707070",
    fontFamily: "Segoe UI",
    padding: "0px 80px 0px 80px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 20px 10px 20px",
      flex: "none"
    }
  },
  grid__text__phone: {
    fontSize: "2rem",
    padding: "0px 0px 20px 0px"
  },
  grid__text__menu: {
    fontSize: "1.1rem"
  },
  grid__text_size: {
    fontSize: "0.8rem"
  },
  menu__item: {
    padding: "10px 0px 10px 0px"
  }
}));
export default function Footer() {
  const classes = useStyles();
  return (
    <Grid container className={classes.footer}>
      <Grid item className={classes.grid__logo}>
        {/* logo */}
      </Grid>
      <Grid item container className={classes.grid__text}>
        <Grid item className={classes.grid__text__phone}>
          {config.phone}
        </Grid>
        <Grid item container>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.grid__text__menu}
          >
            <Grid item className={classes.menu__item}>
              <Link to="/about">Об Учебном центре</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <Link to="/#">Наши преподаватели</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <Link to="/official-info">Правовая информация</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <a href='/static/pdf/license.pdf' target="_blank" className={classes.learn_program_link}>Лицензия</a>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.grid__text__menu}
          >
            <Grid item className={classes.menu__item}>
              <Link to="/special-courses">Спецкурсы</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <Link to="/courses">Курсы</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <Link to="/contact">Контакты</Link>
            </Grid>
            <Grid item className={classes.menu__item}>
              <Link to="/#">Помощь</Link>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.grid__text__menu}
          >
            <Grid item>
              <Link to="/mobile-application-download"><GoogleIcon width="150px" /></Link>
            </Grid>
            <Grid item>
              <Link to="/mobile-application-download"><ApplyIcon width="150px" /></Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={classes.containerPadding}
        >
          <Grid item className={classes.grid__text_size}>
            620017, Свердловская область, город Екатеринбург, улица Лобкова, дом
            2, офис 30, ИНН 6686114095
          </Grid>
          <Grid item className={classes.grid__text_size}>
            © Учебный центр 2021
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
