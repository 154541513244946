import React from "react";
import Title from "./common/title";
import CardMediaNews from "./cardMediaNews";
import ButtonComp from "./common/button";

import { dataNews } from "../dataNews";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  news__cards: {
    overflow: "auto",
    padding: "0px 0px 30px 0px",
    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#99a63c",
      height: "10px",
      borderRadius: "10px"
    }
  },
  news__button: {
    paddingTop: "35px",
    margin: "0 auto"
  }
}));

export default function News() {
  const classes = useStyles();
  return (
    <Grid container style={{ paddingTop: "70px" }}>
      <Title title="Новости" />
      <Grid item container wrap="nowrap" className={classes.news__cards}>
        {dataNews.map((el, id) => (
          <Link to={`news/${el.url}`}>
            <CardMediaNews
              img={el.img}
              tag={el.tag}
              views={el.views}
              date={el.date}
              title={el.title}
              text={el.text}
              key={id}
            />
          </Link>
        ))}
      </Grid>
      <Grid item className={classes.news__button}>
        <ButtonComp link="news" title="Все новости" endIcon={<ArrowRightAltIcon />} />
      </Grid>
    </Grid>
  );
}
