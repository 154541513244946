import { useContext, useState, useEffect, createContext } from 'react';
import { auth, db } from '../adapters/firebase';
import firebase from 'firebase';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const roles = ["student", "admin", "teacher"];

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    const signup = (email, password, firstName, lastName) => {
        const fullName = firstName + " " + lastName;
        let promise = new Promise(function (resolve, reject) {
            auth
                .createUserWithEmailAndPassword(email, password)
                .then((ref) => {
                    const { user } = ref;
                    user.updateProfile({
                        displayName: fullName,
                        photoURL:
                            'https://firebasestorage.googleapis.com/v0/b/instagram-clone-66f7a.appspot.com/o/BlankImage.jpg?alt=media&token=c4d05e11-5df1-4a8a-ba8a-9a6f0cd36c4b',
                    });
                    db.collection('userProfile')
                        .doc(user.uid)
                        .set({
                            id: user.uid,
                            role: roles[0],
                            address: { city: "", country: "", number: "", region: "", street: "" },
                            birthday: "",
                            courses: "",
                            createDate: firebase.firestore.FieldValue.serverTimestamp(),
                            email,
                            firstName,
                            lastName,
                            photoURL:
                                'https://firebasestorage.googleapis.com/v0/b/instagram-clone-66f7a.appspot.com/o/BlankImage.jpg?alt=media&token=c4d05e11-5df1-4a8a-ba8a-9a6f0cd36c4b',
                            bio: '',
                            website: '',
                            phone: '',
                        }).then(() => {
                            resolve(ref);
                        })
                })
                .catch((error) => reject(error));
        });
        return promise;
    };


    const signin = (email, password) => {
        let promise = new Promise(function (resolve, reject) {
            auth
                .signInWithEmailAndPassword(email, password)
                .then((ref) => {
                    resolve(ref);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return promise;
    };
    const signout = () => {
        return auth.signOut();
    };
    const passwordReset = (email) => {
        let promise = new Promise(function (resolve, reject) {
            auth
                .sendPasswordResetEmail(email)
                .then(() => {
                    resolve(`Password Reset Email sent to ${email}`);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return promise;
    };
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, [currentUser]);
    const value = {
        currentUser,
        signup,
        signin,
        signout,
        passwordReset
    };
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}