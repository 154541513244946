import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {moduleListData2} from './dataModuleList';

const useStyles = makeStyles((theme) => ({
    module: {
        margin: '20px 0px',
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 1fr) )',
        gridGap: '40px',
    }
}));

const ModuleList = ({moduleList, modulesRollUp, addOptionsModule}) => {
    const classes = useStyles();

    //if (moduleList.length === 1) {
    //    return <div>Нет модулей</div>
    //}

    return (
        <>
            {
                moduleList.map((item, id) => {
                    const {component} = item.name && moduleListData2[item.name];
                    const moduleRollUp = modulesRollUp[id] || 0;
                    return (
                        <div key={item.id} className={classes.module}>
                            {item.name === '' ? '0 модуль' : component({id, moduleRollUp, item, addOptionsModule})}
                        </div>
                    )
                })
            }
        </>
    )
}

export default ModuleList;
