import React from "react";
import ButtonComp from "./button";
import AsideFooter from "../asideFooter";
import AvatarProfile from "../avatar";
import { ReactComponent as Logo2Icon } from "../../static/svg/logo2.svg";
import { ReactComponent as LogoUrfuIcon } from "../../static/svg/logoUrfu.svg";
import { ReactComponent as LogoGruIcon } from "../../static/svg/logo-gru.svg";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    aside__block__item_padding: {
        padding: "40px 0px 0px 0px",
        textAlign: "center"
    },
    aside__partners: {
        fontSize: "1rem",
        fontFamily: "Open Sans"
    },
    item__icon: {
        width: "50px",
        margin: "10px"
    }
}));

export default function AsideNavigation({ logoProfile }) {
    const classes = useStyles();
    return (
        <>
            <Grid item>
                <Link to="/"><Logo2Icon /></Link>
                {logoProfile &&
                    <Grid item container justify="center">
                        <AvatarProfile small img={logoProfile} />
                    </Grid>

                }

            </Grid>
            <Grid item className={classes.aside__block__item_padding}>
                {logoProfile ?
                    <ButtonComp
                        link="/profile/dashboard"
                        title="Вход"
                        viewButton="signIn"
                        startIcon={<AccountCircleIcon style={{ color: "#99A63C" }} />}
                    />
                    :
                    <ButtonComp
                        link="/auth"
                        title="Вход"
                        viewButton="signIn"
                        startIcon={<AccountCircleIcon style={{ color: "#99A63C" }} />}
                    />

                }

            </Grid>
            <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.aside__block__item_padding}
            >
                <span className={classes.aside__partners}>Партнеры</span>
                <Grid item className={classes.item__icon}>
                    <LogoGruIcon />
                </Grid>
                <Grid item className={classes.item__icon}>
                    <LogoUrfuIcon />
                </Grid>
            </Grid>
            <AsideFooter />
        </>
    )
}
