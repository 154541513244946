export const testData = {
    test1: [
        {
            nameQuestion: "question1",
            labelQuestion: "Дата освобождения советских войск от блокады немецко-фашистских войск города Ленинград? ",
            questions: [
                {
                    id: '1',
                    answer: true,
                    label: "27 января 1944 года",
                },
                {
                    id: '2',
                    answer: false,
                    label: "27 января 1943 года"
                },
                {
                    id: '3',
                    answer: false,
                    label: "27 февраля 1944 года"
                },
                {
                    id: '4',
                    answer: false,
                    label: "27 февраля 1943 года"
                },
            ]
        },
        {
            nameQuestion: "question2",
            labelQuestion: "С чем связан праздник 23 февраля «День защитника отечества»?",
            questions: [
                {
                    id: '5',
                    answer: false,
                    label: "С победой красной Армии над фашистами",
                },
                {
                    id: '6',
                    answer: true,
                    label: "C созданием Рабоче-Крестьянской Красной армии"
                },
                {
                    id: '7',
                    answer: false,
                    label: "С днем создания партии КПСС"
                },
            ]
        },
        {
            nameQuestion: "question3",
            labelQuestion: "Даты жизни полководца Василевского Александра Михайловича?",
            questions: [
                {
                    id: '8',
                    answer: false,
                    label: "18 сентября 1845 - 5 декабря 1977",
                },
                {
                    id: '9',
                    answer: true,
                    label: "18 сентября 1895 - 5 декабря 1977"
                },
                {
                    id: '10',
                    answer: false,
                    label: "18 сентября 1895 - 5 декабря 1945"
                },
                {
                    id: '11',
                    answer: false,
                    label: "18 сентября 1885 - 5 декабря 1945"
                },
            ]
        },
        {
            nameQuestion: "question4",
            labelQuestion: "Какое было звание у Сталина Иосиф Виссарионовича? ",
            questions: [
                {
                    id: '12',
                    answer: true,
                    label: "Генералиссимус",
                },
                {
                    id: '13',
                    answer: false,
                    label: "Лейтенант"
                },
                {
                    id: '14',
                    answer: false,
                    label: "Генера"
                },
                {
                    id: '15',
                    answer: false,
                    label: "Не было звания"
                },
            ]
        },
        {
            nameQuestion: "question5",
            labelQuestion: "Первый Герой Советского Союза?",
            questions: [
                {
                    id: '16',
                    answer: false,
                    label: "снайпер Василий Зайцев",
                },
                {
                    id: '17',
                    answer: true,
                    label: "лётчики Анатолий Ляпидевский"
                },
                {
                    id: '18',
                    answer: false,
                    label: "полководец Василевский Александр Михайлович"
                },
                {
                    id: '19',
                    answer: false,
                    label: "Брежнев Леонид Ильич"
                },
            ]
        },
        {
            nameQuestion: "question6",
            labelQuestion: "Сколько дней длилась блокада Ленинграда?",
            questions: [
                {
                    id: '20',
                    answer: false,
                    label: "1233",
                },
                {
                    id: '21',
                    answer: false,
                    label: "90"
                },
                {
                    id: '22',
                    answer: false,
                    label: "5"
                },
                {
                    id: '23',
                    answer: true,
                    label: "872"
                },
            ]
        },
        {
            nameQuestion: "question7",
            labelQuestion: "Герой Советского Союза, уничтожил более 240 солдат и офицеров германской армии и их союзников, включая 11 снайперов? ",
            questions: [
                {
                    id: '24',
                    answer: false,
                    label: "Будённый Семён Михайлович",
                },
                {
                    id: '25',
                    answer: true,
                    label: "Василий Зайцев"
                },
                {
                    id: '26',
                    answer: false,
                    label: "Анатолий Ляпидевский"
                },
                {
                    id: '27',
                    answer: false,
                    label: "Василевский Александр Михайлович"
                },
            ]
        },
        {
            nameQuestion: "question8",
            labelQuestion: "Высшее воинское звание в России? ",
            questions: [
                {
                    id: '28',
                    answer: true,
                    label: "Генерал Армии",
                },
                {
                    id: '29',
                    answer: false,
                    label: "Маршал"
                },
                {
                    id: '30',
                    answer: false,
                    label: "Генералиссимус"
                },
                {
                    id: '31',
                    answer: false,
                    label: "Президент"
                },
            ]
        },
        {
            nameQuestion: "question9",
            labelQuestion: "В каком городе расположен Памятник «Родина-мать зовет!»?",
            questions: [
                {
                    id: '32',
                    answer: false,
                    label: "Москва",
                },
                {
                    id: '33',
                    answer: false,
                    label: "Санк-Петербург"
                },
                {
                    id: '34',
                    answer: false,
                    label: "Новосибирск"
                },
                {
                    id: '35',
                    answer: true,
                    label: "Волгоград"
                },
            ]
        },
        {
            nameQuestion: "question10",
            labelQuestion: "Сколько всего было присвоено званий Героя Советского Союза? ",
            questions: [
                {
                    id: '36',
                    answer: false,
                    label: "50 222",
                },
                {
                    id: '37',
                    answer: false,
                    label: "1 777"
                },
                {
                    id: '38',
                    answer: true,
                    label: "12 777"
                },
                {
                    id: '39',
                    answer: false,
                    label: "100 777"
                },
            ]
        },
        {
            nameQuestion: "question11",
            labelQuestion: "Кем присваивается первое офицерское звание?",
            questions: [
                {
                    id: '40',
                    answer: true,
                    label: "Министром Обороны РФ",
                },
                {
                    id: '41',
                    answer: false,
                    label: "Президентом РФ"
                },
                {
                    id: '42',
                    answer: false,
                    label: "Командующим ВВО"
                },
                {
                    id: '43',
                    answer: false,
                    label: "Командиром в/ч"
                },
            ]
        },
        {
            nameQuestion: "question12",
            labelQuestion: "Сколько форм одежды для проведения утренней физзарядки?",
            questions: [
                {
                    id: '44',
                    answer: false,
                    label: "1",
                },
                {
                    id: '45',
                    answer: true,
                    label: "2"
                },
                {
                    id: '46',
                    answer: false,
                    label: "3"
                },
                {
                    id: '47',
                    answer: false,
                    label: "4"
                },
            ]
        },
    ],
    test2: [
        {
            nameQuestion: "question13",
            labelQuestion: "В каком году утвердили новую редакцию военной доктрины России?",
            questions: [
                {
                    id: '48',
                    answer: false,
                    label: "1 сентября 1993 году"
                },
                {
                    id: '49',
                    answer: false,
                    label: "26 декабря 1991 году"
                },
                {
                    id: '50',
                    answer: true,
                    label: "25 декабря 2014 году"
                },
                {
                    id: '51',
                    answer: false,
                    label: "25 декабря 1991 году"
                },
            ]
        },
        {
            nameQuestion: "question14",
            labelQuestion: "Для чего предназначены Вооруженные Силы РФ?",
            questions: [
                {
                    id: '52',
                    answer: false,
                    label: "Для поддержания внутреннего порядка в РФ"
                },
                {
                    id: '53',
                    answer: true,
                    label: " Для отражения агрессии, направленной против РФ"
                },
                {
                    id: '54',
                    answer: false,
                    label: "Для борьбы с терроризмом"
                },
            ]
        },
        {
            nameQuestion: "question15",
            labelQuestion: "Когда была принята и вступила в действие конституция РФ?",
            questions: [
                {
                    id: '56',
                    answer: true,
                    label: "25 декабря 1993 года"
                },
                {
                    id: '57',
                    answer: false,
                    label: "26 декабря 1991 году"
                },
                {
                    id: '58',
                    answer: false,
                    label: "25 декабря 1991 году"
                },
                {
                    id: '59',
                    answer: false,
                    label: "1 декабря 1993 году"
                },
            ]
        },
        {
            nameQuestion: "question16",
            labelQuestion: "Какова численность военнослужащих согласно указу №555 президента от 17 ноября 2017 года? ",
            questions: [
                {
                    id: '60',
                    answer: true,
                    label: "1 013 628"
                },
                {
                    id: '61',
                    answer: false,
                    label: "2 015 678"
                },
                {
                    id: '62',
                    answer: false,
                    label: "1 124 000"
                },
                {
                    id: '63',
                    answer: false,
                    label: "545 000"
                },
            ]
        },
        {
            nameQuestion: "question17",
            labelQuestion: "Когда были основаны Вооруженные силы РФ?",
            questions: [
                {
                    id: '64',
                    answer: false,
                    label: "26 декабря 1991г"
                },
                {
                    id: '65',
                    answer: false,
                    label: "26 декабря 1992г"
                },
                {
                    id: '66',
                    answer: false,
                    label: "25 декабря 1993г"
                },
                {
                    id: '67',
                    answer: true,
                    label: "7 мая 1992г"
                },
            ]
        },
        {
            nameQuestion: "question18",
            labelQuestion: "Какое правовое значение имеет приведение к военной присяге?",
            questions: [
                {
                    id: '68',
                    answer: false,
                    label: "До приведения к военной присяге военнослужащий не может  получать оружие."
                },
                {
                    id: '69',
                    answer: false,
                    label: "До приведения к присяге военнослужащий не может выходить за  территорию воинской части."
                },
                {
                    id: '70',
                    answer: true,
                    label: "До приведения к присяге военнослужащий не может привлекаться к выполнению боевых задач и задач при введении режима чрезвычайного положения и в условиях вооруженных конфликтов."
                },
                {
                    id: '71',
                    answer: false,
                    label: "До приведения к присяге военнослужащий не может проводить  военную подготовку."
                },
            ]
        },
        {
            nameQuestion: "question19",
            labelQuestion: "7)	Когда может быть принято решение о призыве граждан на военную службу?",
            questions: [
                {
                    id: '72',
                    answer: false,
                    label: "По достижении гражданами 14 лет."
                },
                {
                    id: '73',
                    answer: false,
                    label: "По достижении гражданами 16 лет."
                },
                {
                    id: '74',
                    answer: false,
                    label: "По достижении гражданами 17 лет."
                },
                {
                    id: '75',
                    answer: true,
                    label: "По достижении гражданами 18 лет."
                },
            ]
        },
        {
            nameQuestion: "question20",
            labelQuestion: "Что составляет основу вооруженной защиты России?",
            questions: [
                {
                    id: '76',
                    answer: false,
                    label: "Комитет по обороне Государственной Думы Российской Федерации."
                },
                {
                    id: '77',
                    answer: false,
                    label: "Конституция Российской Федерации."
                },
                {
                    id: '78',
                    answer: true,
                    label: "Вооруженные Силы Российской Федерации, другие войска.  воинские формирования и органы."
                },
            ]
        },
    ],
    test3: [
        {
            nameQuestion: "question21",
            labelQuestion: "Самый многочисленный род войск РФ?",
            questions: [
                {
                    id: '80',
                    answer: true,
                    label: "мотострелковые войска"
                },
                {
                    id: '81',
                    answer: false,
                    label: "Танковые войска"
                },
                {
                    id: '82',
                    answer: false,
                    label: "Войска ПВО"
                },
                {
                    id: '83',
                    answer: false,
                    label: "Инженерные войска"
                },
            ]
        },
        {
            nameQuestion: "question22",
            labelQuestion: "Рода войск, входящие в структуру ВС?",
            questions: [
                {
                    id: '84',
                    answer: false,
                    label: "Мотострелковые войска, танковые войска, воска ПВО."
                },
                {
                    id: '85',
                    answer: true,
                    label: "Ракетные войска стратегического назначения, воздушно-десантные войска."
                },
                {
                    id: '86',
                    answer: false,
                    label: "Ракетные войска и артиллерия, специальные войска, соединения, части и учреждения тыла."
                },
            ]
        },
        {
            nameQuestion: "question23",
            labelQuestion: "Руководство ВС осуществляет?",
            questions: [
                {
                    id: '88',
                    answer: false,
                    label: "Начальник генерального штаба ВС РФ."
                },
                {
                    id: '89',
                    answer: true,
                    label: "Президент Российской Федерации."
                },
                {
                    id: '90',
                    answer: false,
                    label: "Главнокомандующие видами ВС РФ."
                }
            ]
        },
        {
            nameQuestion: "question24",
            labelQuestion: "В настоящее время Вооруженные Силы РФ структурно включают в себя:",
            questions: [
                {
                    id: '92',
                    answer: true,
                    label: "3 вида и 2 рода войск."
                },
                {
                    id: '93',
                    answer: false,
                    label: "2 вида и 3 рода войск."
                },
                {
                    id: '94',
                    answer: false,
                    label: "3 вида и 3 рода войск."
                }
            ]
        },
        {
            nameQuestion: "question25",
            labelQuestion: "Какие войска являются одним из видов ВС?",
            questions: [
                {
                    id: '96',
                    answer: false,
                    label: "Инженерные войска."
                },
                {
                    id: '97',
                    answer: false,
                    label: "Войска гражданской обороны."
                },
                {
                    id: '98',
                    answer: false,
                    label: "Войска связи."
                },
                {
                    id: '99',
                    answer: true,
                    label: "Сухопутные войска."
                },
            ]
        },
        {
            nameQuestion: "question26",
            labelQuestion: "Как называется сторона строя, в которую военнослужащие обращены лицом?",
            questions: [
                {
                    id: '100',
                    answer: false,
                    label: "Передовая."
                },
                {
                    id: '101',
                    answer: true,
                    label: "Фронт."
                },
                {
                    id: '102',
                    answer: false,
                    label: "Линия."
                },
                {
                    id: '103',
                    answer: false,
                    label: "Фланг."
                },
            ]
        },
        {
            nameQuestion: "question27",
            labelQuestion: "Какие основные формы тактических действий вы можете назвать?",
            questions: [
                {
                    id: '104',
                    answer: false,
                    label: "Оборона, наступление."
                },
                {
                    id: '105',
                    answer: false,
                    label: "Передвижение, марш."
                },
                {
                    id: '106',
                    answer: true,
                    label: "Удар, маневр и бой."
                },
                {
                    id: '107',
                    answer: false,
                    label: "Отход, действие в тактическом воздушном десанте, разведка."
                },
            ]
        },
        {
            nameQuestion: "question28",
            labelQuestion: "Как называется строй, в котором военнослужащие размещены один возле другого на одной линии?",
            questions: [
                {
                    id: '108',
                    answer: false,
                    label: "Ряд."
                },
                {
                    id: '109',
                    answer: true,
                    label: "Шеренга."
                },
                {
                    id: '110',
                    answer: false,
                    label: "Колонна."
                },
                {
                    id: '111',
                    answer: false,
                    label: "Фронт."
                },
            ]
        },
        {
            nameQuestion: "question29",
            labelQuestion: "Основные виды общевойскового боя является:",
            questions: [
                {
                    id: '112',
                    answer: false,
                    label: "оборона и маневр."
                },
                {
                    id: '113',
                    answer: true,
                    label: "оборона и наступление."
                },
                {
                    id: '114',
                    answer: false,
                    label: "наступление и маневр."
                },
            ]
        },
        {
            nameQuestion: "question30",
            labelQuestion: "Способы передвижения солдата на поле боя в пешем порядке:",
            questions: [
                {
                    id: '112',
                    answer: true,
                    label: "ускоренным шагом (бегом), перебежками и переползанием."
                },
                {
                    id: '113',
                    answer: false,
                    label: "ускоренным шагом, бегом и по-пластунски."
                },
                {
                    id: '114',
                    answer: false,
                    label: "перебежками, шагом и на боку."
                },
                {
                    id: '115',
                    answer: false,
                    label: "Фронт."
                },
            ]
        },
        {
            nameQuestion: "question31",
            labelQuestion: "Видами маневра являются:",
            questions: [
                {
                    id: '116',
                    answer: true,
                    label: "охват, обход и отход."
                },
                {
                    id: '117',
                    answer: false,
                    label: "атака, наступление с ходу."
                },
                {
                    id: '118',
                    answer: false,
                    label: "оборона, встречный бой."
                },
            ]
        },
        {
            nameQuestion: "question32",
            labelQuestion: "Походный порядок МСО, взвода, роты это:",
            questions: [
                {
                    id: '120',
                    answer: true,
                    label: "шеренга."
                },
                {
                    id: '121',
                    answer: false,
                    label: "колонна."
                },
                {
                    id: '122',
                    answer: false,
                    label: "фронт."
                },
            ]
        },
        {
            nameQuestion: "question33",
            labelQuestion: "В наступлении при действии в пешем порядке боевой порядок МСО это:",
            questions: [
                {
                    id: '124',
                    answer: true,
                    label: "колонна."
                },
                {
                    id: '125',
                    answer: false,
                    label: "цепь."
                },
                {
                    id: '126',
                    answer: false,
                    label: "фронт."
                },
            ]
        },
    ],
    test4: [
        {
            nameQuestion: "question34",
            labelQuestion: "При каком ядерном взрыве поражающее действие ЭМИ достигает максимальных значений:",
            questions: [
                {
                    id: '128',
                    answer: false,
                    label: "наземном."
                },
                {
                    id: '129',
                    answer: false,
                    label: "подземном."
                },
                {
                    id: '130',
                    answer: false,
                    label: "подводным."
                },
                {
                    id: '131',
                    answer: true,
                    label: "высотном."
                },
            ]
        },
        {
            nameQuestion: "question35",
            labelQuestion: "К группам физиологический свойств отравляющий веществ относятся:",
            questions: [
                {
                    id: '132',
                    answer: true,
                    label: "нервнопаралитические."
                },
                {
                    id: '133',
                    answer: false,
                    label: "стойкие."
                },
                {
                    id: '134',
                    answer: false,
                    label: "нестойкие."
                },
                {
                    id: '135',
                    answer: false,
                    label: "ядовитодымные."
                },
            ]
        },
        {
            nameQuestion: "question36",
            labelQuestion: "При лучевой болезни IV степени (крайне тяжелой) смерть наступает через:",
            questions: [
                {
                    id: '136',
                    answer: true,
                    label: "5 – 12 дней."
                },
                {
                    id: '137',
                    answer: false,
                    label: "•	1 день."
                },
                {
                    id: '138',
                    answer: false,
                    label: "1 час."
                },
                {
                    id: '138',
                    answer: false,
                    label: "1 месяц."
                },
            ]
        },
        {
            nameQuestion: "question37",
            labelQuestion: "При каком ядерном взрыве наиболее сильное радиоактивное заражение местности:",
            questions: [
                {
                    id: '136',
                    answer: true,
                    label: "наземном."
                },
                {
                    id: '137',
                    answer: false,
                    label: "подземным."
                },
                {
                    id: '138',
                    answer: false,
                    label: "подводным."
                },
                {
                    id: '139',
                    answer: false,
                    label: "воздушном."
                },
            ]
        },
        {
            nameQuestion: "question38",
            labelQuestion: "При каком ядерном взрыве наиболее сильное радиоактивное заражение местности:",
            questions: [
                {
                    id: '136-1',
                    answer: true,
                    label: "наземном."
                },
                {
                    id: '137-1',
                    answer: false,
                    label: "подземным."
                },
                {
                    id: '138-1',
                    answer: false,
                    label: "подводным."
                },
                {
                    id: '139-1',
                    answer: false,
                    label: "воздушном."
                },
            ]
        },
        {
            nameQuestion: "question39",
            labelQuestion: "Какими материалами лучше всего ослабляется поток нейтронов:",
            questions: [
                {
                    id: '140',
                    answer: false,
                    label: "свинец, сталь."
                },
                {
                    id: '141',
                    answer: true,
                    label: "вода, полиэтилен."
                },
                {
                    id: '142',
                    answer: false,
                    label: "грунт, дерево."
                },
                {
                    id: '143',
                    answer: false,
                    label: "бетон, стекло."
                },
            ]
        },
        {
            nameQuestion: "question40",
            labelQuestion: "Лучевая болезнь IV степени (крайне тяжелая) наступает при дозе излучения:",
            questions: [
                {
                    id: '144',
                    answer: true,
                    label: "700 рад."
                },
                {
                    id: '145',
                    answer: false,
                    label: "100 рад."
                },
                {
                    id: '146',
                    answer: false,
                    label: "50 рад."
                },
                {
                    id: '147',
                    answer: false,
                    label: "500 рад."
                },
            ]
        },
        {
            nameQuestion: "question41",
            labelQuestion: "Какими материалами сильнее всего ослабляется γ- излучение:",
            questions: [
                {
                    id: '148',
                    answer: true,
                    label: "свинец, сталь, бетон."
                },
                {
                    id: '149',
                    answer: false,
                    label: "грунт, дерево, стекло."
                },
                {
                    id: '150',
                    answer: false,
                    label: "алюминий, медь."
                },
                {
                    id: '151',
                    answer: false,
                    label: "вода, полиэтилен."
                },
            ]
        },
        {
            nameQuestion: "question42",
            labelQuestion: "Что может служить защитой от светового излучения?",
            questions: [
                {
                    id: '148',
                    answer: false,
                    label: "Простейшие средства защиты кожи и органов дыхания."
                },
                {
                    id: '149',
                    answer: true,
                    label: "Любые преграды, не пропускающие свет: укрытия, забор и т.п."
                },
                {
                    id: '150',
                    answer: false,
                    label: "Защиты не существует."
                },
            ]
        },
        {
            nameQuestion: "question43",
            labelQuestion: "К коллективным средствам защиты относятся:",
            questions: [
                {
                    id: '148',
                    answer: false,
                    label: "противогазы"
                },
                {
                    id: '149',
                    answer: false,
                    label: "респираторы."
                },
                {
                    id: '150',
                    answer: true,
                    label: "убежища."
                },
                {
                    id: '151',
                    answer: false,
                    label: "средства защиты кожи."
                },
            ]
        },
        {
            nameQuestion: "question44",
            labelQuestion: "Проникающая радиация - это поток:",
            questions: [
                {
                    id: '152',
                    answer: true,
                    label: "гамма-лучей и нейтронов"
                },
                {
                    id: '153',
                    answer: false,
                    label: "невидимых нейтронов."
                },
                {
                    id: '154',
                    answer: false,
                    label: "радиоактивных протонов."
                },
            ]
        },
        {
            nameQuestion: "question45",
            labelQuestion: "Назовите наиболее сильный поражающий фактор ядерного взрыва:",
            questions: [
                {
                    id: '156',
                    answer: true,
                    label: "Ударная волна."
                },
                {
                    id: '157',
                    answer: false,
                    label: "Световое излучение."
                },
                {
                    id: '158',
                    answer: false,
                    label: "Радиоактивное заражение."
                },
                {
                    id: '159',
                    answer: false,
                    label: "Проникающая радиация."
                },
            ]
        },
    ],
    test5: [
        {
            nameQuestion: "question46",
            labelQuestion: "Стрелку при разряжении оружия необходимо:",
            questions: [
                {
                    id: '160',
                    answer: false,
                    label: "передернуть затвор, проверяя наличие патрона в патронни­ке, извлечь магазин."
                },
                {
                    id: '161',
                    answer: false,
                    label: "сделать контрольный спуск."
                },
                {
                    id: '162',
                    answer: false,
                    label: "извлечь магазин, поставить на предохранитель."
                },
                {
                    id: '163',
                    answer: true,
                    label: "извлечь магазин, передернуть затвор, сделать контрольный спуск, поставить на предохранитель."
                },
            ]
        },
        {
            nameQuestion: "question47",
            labelQuestion: "Каков темп стрельбы (выстрелов в минуту) у автомата АКМ:",
            questions: [
                {
                    id: '164',
                    answer: false,
                    label: "около 500 выстрелов"
                },
                {
                    id: '165',
                    answer: true,
                    label: "около 600 выстрелов."
                },
                {
                    id: '166',
                    answer: false,
                    label: "около 700 выстрелов"
                },
                {
                    id: '167',
                    answer: false,
                    label: "около 800 выстрелов"
                },
            ]
        },
        {
            nameQuestion: "question48",
            labelQuestion: "Для временного прекращения стрельбы в ходе выполнения учебных стрельб подаётся команда:",
            questions: [
                {
                    id: '168',
                    answer: true,
                    label: "«Стой!» при стрельбе в движении - «Прекратить огонь»"
                },
                {
                    id: '169',
                    answer: false,
                    label: "«Разряжай!»"
                },
                {
                    id: '170',
                    answer: false,
                    label: "«Оружие к осмотру»"
                },
            ]
        },
        {
            nameQuestion: "question49",
            labelQuestion: "Какова прицельная дальность стрельбы автомата АКМ?",
            questions: [
                {
                    id: '171',
                    answer: false,
                    label: "1500 метров"
                },
                {
                    id: '172',
                    answer: false,
                    label: "1000 метров"
                },
                {
                    id: '173',
                    answer: true,
                    label: "500 метров"
                },
                {
                    id: '174',
                    answer: false,
                    label: "300 метров"
                },
            ]
        },
        {
            nameQuestion: "question50",
            labelQuestion: "Создателем первого в мире автомата, сконструированного в 1913 году, является:",
            questions: [
                {
                    id: '175',
                    answer: true,
                    label: "Дегтярев В.А."
                },
                {
                    id: '176',
                    answer: false,
                    label: "Калашников М.Т."
                },
                {
                    id: '177',
                    answer: false,
                    label: "Федоров В.Г"
                },
            ]
        },
        {
            nameQuestion: "question51",
            labelQuestion: "Расположите в правильной последовательности действия по сборке автомата Калашникова после его неполной разбор­ки:",
            questions: [
                {
                    id: '178',
                    answer: false,
                    label: "Затвор к затворной раме. Газовую трубку,Затворную раму с затвором к ствольной коробке. Возвратный механизм Крышку ствольной коробки. Спустить курок, на предохранитель. Шомпол. Пенал в гнездо. Магазин к автомату."
                },
                {
                    id: '179',
                    answer: true,
                    label: "Газовую трубку, Затвор к затворной раме. Затворную раму с затвором к ствольной коробке. Возвратный механизм Крышку ствольной коробки. Спустить курок, на предохранитель. Шомпол. Пенал в гнездо. Магазин к автомату."
                },
                {
                    id: '180',
                    answer: false,
                    label: "Газовую трубку, Затвор к затворной раме. Затворную раму с затвором к ствольной коробке. Возвратный механизм Крышку ствольной коробки.Пенал в гнездо. Шомпол.. Спустить курок, на предохранитель.. Шомпол. Магазин к автомату."
                }
            ]
        },
        {
            nameQuestion: "question52",
            labelQuestion: "Как называется деталь автомата АК-74 предназначенная для запирания канала ствола?",
            questions: [
                {
                    id: '181',
                    answer: false,
                    label: "Газовый поршень"
                },
                {
                    id: '182',
                    answer: true,
                    label: "Затвор"
                },
                {
                    id: '183',
                    answer: false,
                    label: "Газовая трубка"
                }
            ]
        },
        {
            nameQuestion: "question53",
            labelQuestion: "Что не является основной частью АК-74?",
            questions: [
                {
                    id: '184',
                    answer: true,
                    label: "крышки ствольной коробки"
                },
                {
                    id: '185',
                    answer: false,
                    label: "Магазин"
                },
                {
                    id: '186',
                    answer: false,
                    label: "Возвратный механизм"
                },
                {
                    id: '187',
                    answer: false,
                    label: "Ударно-спусковой механизм"
                },
            ]
        },
        {
            nameQuestion: "question54",
            labelQuestion: "Что такое прямой выстрел?",
            questions: [
                {
                    id: '188',
                    answer: false,
                    label: "попадание в цель"
                },
                {
                    id: '189',
                    answer: false,
                    label: "выстрел по цели"
                },
                {
                    id: '190',
                    answer: true,
                    label: "траектория не поднимается выше цели на всем протяжении"
                },
            ]
        },
        {
            nameQuestion: "question55",
            labelQuestion: "10)	В чем заключается подготовка АК-74 к стрельбе?",
            questions: [
                {
                    id: '191',
                    answer: false,
                    label: "в подсоединении магазина к АК"
                },
                {
                    id: '192',
                    answer: false,
                    label: "в присоединении ремня к АК"
                },
                {
                    id: '193',
                    answer: false,
                    label: "в присоединении штык-ножа к АК"
                },
                {
                    id: '194',
                    answer: true,
                    label: "в подсоединении снаряженного магазина к АК, досыла патрона в патронник, переводе “огня” на предохранитель, докладе о готовности к стрельбе"
                },
            ]
        },
    ],
    test6: [
        {
            nameQuestion: "question56",
            labelQuestion: "Когда отмечается день военного связиста?",
            questions: [
                {
                    id: '195',
                    answer: true,
                    label: "20 октября"
                },
                {
                    id: '196',
                    answer: false,
                    label: "6 августа"
                },
                {
                    id: '197',
                    answer: false,
                    label: "7 мая"
                },
            ]
        },
        {
            nameQuestion: "question57",
            labelQuestion: "ТАИ-43 — один из самых известных полевых военных телефонных аппаратов. По воспоминаниям военных связистов, «надежный, но тяжеленный аппарат». Сколько весит ТАИ-43 вместе с батареями питания?",
            questions: [
                {
                    id: '198',
                    answer: false,
                    label: "4,2кг"
                },
                {
                    id: '199',
                    answer: false,
                    label: "5,8"
                },
                {
                    id: '200',
                    answer: true,
                    label: "4,6 кг"
                },
                {
                    id: '201',
                    answer: false,
                    label: "3 кг"
                },
            ]
        },
        {
            nameQuestion: "question58",
            labelQuestion: "На какое расстояние обеспечивает связь на открытой местности радиостанция Р-392",
            questions: [
                {
                    id: '202',
                    answer: true,
                    label: "не менее 10 км"
                },
                {
                    id: '203',
                    answer: false,
                    label: "не менее 50 км"
                },
                {
                    id: '204',
                    answer: false,
                    label: "не менее 5 км"
                },
                {
                    id: '205',
                    answer: false,
                    label: "не менее 65 км"
                },
            ]
        },
        {
            nameQuestion: "question59",
            labelQuestion: "Время непрерывной работы радиостанции Р-392 на прием в нормальных климатических условиях?",
            questions: [
                {
                    id: '206',
                    answer: false,
                    label: "не менее 100 часов"
                },
                {
                    id: '207',
                    answer: false,
                    label: "не менее 60 часов"
                },
                {
                    id: '208',
                    answer: false,
                    label: "не менее 5 часов"
                },
                {
                    id: '209',
                    answer: true,
                    label: "не менее 10 часов"
                },
            ]
        },
        {
            nameQuestion: "question60",
            labelQuestion: "На какое расстояние обеспечивает связь радиостанция Северок -1К? ",
            questions: [
                {
                    id: '210',
                    answer: true,
                    label: "до 200 км"
                },
                {
                    id: '211',
                    answer: false,
                    label: "до 50 км"
                },
                {
                    id: '212',
                    answer: false,
                    label: "до 100 км"
                },
                {
                    id: '213',
                    answer: false,
                    label: "до 360 км"
                },
            ]
        },
    ],
    test7: [
        {
            nameQuestion: "question61",
            labelQuestion: "Что такое общевоинские уставы?",
            questions: [
                {
                    id: '214',
                    answer: true,
                    label: "Это нормативно-правовые акты, которые регламентируют жизнь и быт военнослужащих, их взаимоотношения между собой и повседневную деятельность"
                },
                {
                    id: '215',
                    answer: false,
                    label: "Это распорядок дня внутри военной части"
                },
                {
                    id: '216',
                    answer: false,
                    label: "Свод негласных правил поведения и норм общения на территории воинской части"
                }
            ]
        },
        {
            nameQuestion: "question62",
            labelQuestion: "Какой устав определяет сущность воинской дисциплины, обязанности военнослужащих по её соблюдению?",
            questions: [
                {
                    id: '217',
                    answer: false,
                    label: "Устав гарнизонной службы"
                },
                {
                    id: '218',
                    answer: false,
                    label: "Устав внутренней службы"
                },
                {
                    id: '219',
                    answer: true,
                    label: "Дисциплинарный устав"
                },
            ]
        },
        {
            nameQuestion: "question63",
            labelQuestion: "Для каких войск действуют общевоинские уставы?",
            questions: [
                {
                    id: '220',
                    answer: false,
                    label: "Для всех видов и родов ВС РФ"
                },
                {
                    id: '221',
                    answer: true,
                    label: "Для всех кроме ВВС и ВМФ РФ"
                },
                {
                    id: '222',
                    answer: false,
                    label: "Для всех кроме Миротворческих сил"
                },
            ]
        },
        {
            nameQuestion: "question64",
            labelQuestion: "Когда был принят Указ Президента Российской Федерации об «Утверждении общевоинских уставов Вооружённых Сил Российской Федерации»?",
            questions: [
                {
                    id: '223',
                    answer: false,
                    label: "3 октября 1998 года"
                },
                {
                    id: '224',
                    answer: false,
                    label: "21 июля 2001 года"
                },
                {
                    id: '225',
                    answer: true,
                    label: "10 ноября 2007 года"
                }
            ]
        },
        {
            nameQuestion: "question65",
            labelQuestion: "Какие бывают караулы?",
            questions: [
                {
                    id: '226',
                    answer: false,
                    label: "наружные"
                },
                {
                    id: '227',
                    answer: false,
                    label: "внутренние"
                },
                {
                    id: '228',
                    answer: false,
                    label: "гарнизонные"
                },
                {
                    id: '229',
                    answer: true,
                    label: "гарнизонные и внутренние"
                }
            ]
        },
        {
            nameQuestion: "question66",
            labelQuestion: "6)	Военнослужащим отводится время для сна:",
            questions: [
                {
                    id: '230',
                    answer: false,
                    label: "6 часов"
                },
                {
                    id: '231',
                    answer: false,
                    label: "8 часов"
                },
                {
                    id: '232',
                    answer: false,
                    label: "10 часов"
                }
            ]
        },
        {
            nameQuestion: "question67",
            labelQuestion: "Какой военнослужащий называется часовым?",
            questions: [
                {
                    id: '233',
                    answer: false,
                    label: "Вооруженный караульный, выполняющий боевую задачу по охране и обороне порученного ему поста"
                },
                {
                    id: '234',
                    answer: false,
                    label: "Вооруженный солдат, выполняющий боевую задачу по охране порученного ему объекта"
                },
                {
                    id: '235',
                    answer: false,
                    label: "Вооруженный караульный, выполняющий боевую задачу по охране боевой техники и оружия"
                },
                {
                    id: '236',
                    answer: true,
                    label: "Вооруженный военнослужащий, выполняющий боевую задачу по охране складов с военным имуществом"
                }
            ]
        },
        {
            nameQuestion: "question68",
            labelQuestion: "Какой отличительный знак должен иметь дежурный по роте?",
            questions: [
                {
                    id: '237',
                    answer: false,
                    label: "Специальный штык-нож и головной убор."
                },
                {
                    id: '238',
                    answer: false,
                    label: "Специальную форму одежды"
                },
                {
                    id: '239',
                    answer: false,
                    label: "Нарукавную повязку из белой ткани и нагрудный знак "
                },
                {
                    id: '240',
                    answer: true,
                    label: "На левой стороне груди (левом рукаве) нагрудный знак (нарукавную повязку из красной ткани) с соответствующей надписью"
                }
            ]
        },
        {
            nameQuestion: "question69",
            labelQuestion: "Кто имеет право сменить или снять часового с поста?",
            questions: [
                {
                    id: '241',
                    answer: false,
                    label: "Только разводящий, которому часовой подчинен"
                },
                {
                    id: '242',
                    answer: false,
                    label: "Помощник начальника караула и разводящие караула"
                },
                {
                    id: '243',
                    answer: true,
                    label: "Начальник караула, помощник начальника караула и свой разводящий"
                },
                {
                    id: '244',
                    answer: false,
                    label: "Начальник караула, командир роты (взвода)"
                }
            ]
        },
        {
            nameQuestion: "question70",
            labelQuestion: "Что запрещается в караульном помещении согласно требованиям Устава гарнизонной и караульной службы?",
            questions: [
                {
                    id: '245',
                    answer: false,
                    label: "Смотреть телевизор, слушать радиотрансляцию через головные телефоны"
                },
                {
                    id: '246',
                    answer: true,
                    label: "Петь и играть на музыкальных инструментах"
                },
                {
                    id: '247',
                    answer: false,
                    label: "Оформлять памятный альбом о службе и рисовать"
                },
                {
                    id: '248',
                    answer: false,
                    label: "Играть в шахматы, шашки и нарды"
                }
            ]
        },
        {
            nameQuestion: "question71",
            labelQuestion: "Кто из военнослужащих может назначаться дежурным по роте?",
            questions: [
                {
                    id: '249',
                    answer: false,
                    label: "Из числа сержантов"
                },
                {
                    id: '250',
                    answer: false,
                    label: "Из числа солдат первого года службы"
                },
                {
                    id: '251',
                    answer: false,
                    label: "Из числа солдат второго года службы"
                },
                {
                    id: '252',
                    answer: true,
                    label: "Из числа сержантов и, как исключение, из числа наиболее подготовленных солдат"
                }
            ]
        },
        {
            nameQuestion: "question72",
            labelQuestion: "По прибытии в роту каких начальников дежурный по роте подает команду «Смирно»?",
            questions: [
                {
                    id: '253',
                    answer: true,
                    label: "Прямых начальников от командира роты и выше, дежурного по полку, а также инспектирующих (проверяющих) лиц"
                },
                {
                    id: '254',
                    answer: false,
                    label: "Командира взвода и выше"
                },
                {
                    id: '255',
                    answer: false,
                    label: "Всех старших офицеров"
                },
                {
                    id: '256',
                    answer: false,
                    label: "Только генералов и адмиралов"
                }
            ]
        },
        {
            nameQuestion: "question73",
            labelQuestion: "На чем основываются взаимоотношения между военнослужащими?",
            questions: [
                {
                    id: '257',
                    answer: false,
                    label: "на дружбе"
                },
                {
                    id: '258',
                    answer: true,
                    label: "на товариществе"
                },
                {
                    id: '259',
                    answer: false,
                    label: "на вере в силу армии РФ"
                },
                {
                    id: '260',
                    answer: false,
                    label: "на чувстве взаимного уважения"
                }
            ]
        },
        {
            nameQuestion: "question74",
            labelQuestion: "Какие взыскания могут накладываться на мл. офицеров?",
            questions: [
                {
                    id: '261',
                    answer: false,
                    label: "до 3 нарядов вне очереди на службу"
                },
                {
                    id: '262',
                    answer: false,
                    label: "отлучение от офицерского собрания"
                },
                {
                    id: '263',
                    answer: true,
                    label: "выговор, строгий выговор, снятие с должности, понижение в в/звании"
                },
                {
                    id: '264',
                    answer: false,
                    label: "лишение увольн"
                }
            ]
        },
    ],
    test8: [
        {
            nameQuestion: "question75",
            labelQuestion: "Что такое строй?",
            questions: [
                {
                    id: '265',
                    answer: false,
                    label: "Установленное уставом размещение военнослужащих для их совместных действий в пешем порядке"
                },
                {
                    id: '266',
                    answer: false,
                    label: "Установленное уставом размещение подразделений и частей для их совместных действий в пешем порядке и на боевой технике"
                },
                {
                    id: '267',
                    answer: true,
                    label: "Установленное уставом размещение военнослужащих, подразделений и воинских частей для их совместных действий в пешем порядке и на машинах"
                },
                {
                    id: '268',
                    answer: false,
                    label: "Установленное приказом размещение военнослужащих для их совместных действий на машинах"
                }
            ]
        },
        {
            nameQuestion: "question76",
            labelQuestion: "В каких случаях строевая стойка на месте принимается без команды?",
            questions: [
                {
                    id: '269',
                    answer: false,
                    label: "При общении военнослужащих друг с другом"
                },
                {
                    id: '270',
                    answer: true,
                    label: "При отдании и получении приказа, во время исполнения Государственного гимна Российской Федерации, при выполнении воинского приветствия"
                },
                {
                    id: '271',
                    answer: false,
                    label: "Дневальным по роте при разговоре по телефону с командиром роты"
                },
                {
                    id: '272',
                    answer: false,
                    label: "При получении оружия во время подготовки к несению службы в составе Караула"
                }
            ]
        },
        {
            nameQuestion: "question77",
            labelQuestion: "На какую высоту нужно выносить ногу с оттянутым вперед носком при движении строевым шагом?",
            questions: [
                {
                    id: '273',
                    answer: false,
                    label: "На высоту 10-15 см от земли"
                },
                {
                    id: '274',
                    answer: false,
                    label: "На высоту 10-20 см от земли"
                },
                {
                    id: '275',
                    answer: true,
                    label: "На высоту 15-20 см от земли"
                },
                {
                    id: '276',
                    answer: false,
                    label: "На высоту 15-25 см от земли"
                }
            ]
        },
        {
            nameQuestion: "question78",
            labelQuestion: "Как называется строй, в котором военнослужащие размещены один возле другого на одной линии?",
            questions: [
                {
                    id: '277',
                    answer: false,
                    label: "Ряд"
                },
                {
                    id: '278',
                    answer: true,
                    label: "Ряд"
                },
                {
                    id: '279',
                    answer: false,
                    label: "Колонна"
                },
                {
                    id: '280',
                    answer: false,
                    label: "Фронт"
                }
            ]
        },
        {
            nameQuestion: "question79",
            labelQuestion: "Какие виды строевых команд вы можете назвать?",
            questions: [
                {
                    id: '281',
                    answer: false,
                    label: "Предварительная и обязательная"
                },
                {
                    id: '282',
                    answer: false,
                    label: "Исполнительная и громкая"
                },
                {
                    id: '283',
                    answer: false,
                    label: "Прямая и исполнительная"
                },
                {
                    id: '284',
                    answer: true,
                    label: "Предварительная и исполнительная"
                }
            ]
        },
        {
            nameQuestion: "question80",
            labelQuestion: "Шеренгой является:",
            questions: [
                {
                    id: '285',
                    answer: false,
                    label: "строй, в котором военнослужащие размещены на установленных интервалах"
                },
                {
                    id: '286',
                    answer: true,
                    label: "строй, в котором военнослужащие размещены один возле другого на одной линии на установленных интервалах"
                },
                {
                    id: '287',
                    answer: false,
                    label: "построение, в котором военнослужащие размещены в затылок друг другу, а подразделения одно за другим на дистанции установленной Уставом или командиром"
                },
                {
                    id: '288',
                    answer: false,
                    label: "строй, в котором военнослужащие размещены один возле другого на одной линии, а подразделения одно возле другого на дистанции установленной Уставом или командиром"
                }
            ]
        },
        {
            nameQuestion: "question81",
            labelQuestion: "Флангом называется:",
            questions: [
                {
                    id: '289',
                    answer: true,
                    label: "правая (левая) оконечность строя. При поворотах строя названия флангов не изменяются"
                },
                {
                    id: '290',
                    answer: false,
                    label: "сторона строя в которую военнослужащие обращены лицом"
                },
                {
                    id: '291',
                    answer: false,
                    label: "сторона строя противоположна тылу"
                },
                {
                    id: '292',
                    answer: false,
                    label: "сторона строя, в которую военнослужащие обращены лицом, а машины лобовой частью"
                }
            ]
        },
        {
            nameQuestion: "question82",
            labelQuestion: "Интервалом называется: ",
            questions: [
                {
                    id: '293',
                    answer: true,
                    label: "расстояние по фронту между военнослужащими (машинами), подразделениями и частями"
                },
                {
                    id: '294',
                    answer: false,
                    label: "расстояние между флангами"
                },
                {
                    id: '295',
                    answer: false,
                    label: "расстояние в глубину между шеренгами"
                },
                {
                    id: '296',
                    answer: false,
                    label: "расстояние между колоннами"
                }
            ]
        },
        {
            nameQuestion: "question83",
            labelQuestion: "Тыльной стороной строя называется:",
            questions: [
                {
                    id: '285',
                    answer: true,
                    label: "сторона, противоположная фронту"
                },
                {
                    id: '286',
                    answer: false,
                    label: "правая (левая) оконечность строя"
                },
                {
                    id: '287',
                    answer: false,
                    label: "сторона строя, в которую военнослужащие обращены лицом (машины—лобовой частью)."
                },
                {
                    id: '288',
                    answer: false,
                    label: "сторона строя противоположна флангу"
                }
            ]
        },
        {
            nameQuestion: "question84",
            labelQuestion: "Фронт является",
            questions: [
                {
                    id: '289',
                    answer: false,
                    label: "сторона противоположная тылу"
                },
                {
                    id: '290',
                    answer: true,
                    label: "сторона строя, в которую военнослужащие обращены лицом (машины—лобовой частью)"
                },
                {
                    id: '291',
                    answer: false,
                    label: "сторона строя противоположна флангу"
                },
                {
                    id: '292',
                    answer: false,
                    label: "построение военнослужащих при котором они обращены в одну сторону"
                }
            ]
        },
        {
            nameQuestion: "question85",
            labelQuestion: "Поворот на лево осуществляется:",
            questions: [
                {
                    id: '293',
                    answer: false,
                    label: "В сторону правой руки на правом каблуке и на левом носке"
                },
                {
                    id: '294',
                    answer: false,
                    label: "В сторону левой руки на левом каблуке и на правом носке"
                },
                {
                    id: '295',
                    answer: false,
                    label: "В сторону правой руки на левом каблуке"
                },
                {
                    id: '296',
                    answer: true,
                    label: "Производятся в по часовой стрелки на правом каблуке и на левом носке"
                }
            ]
        },
        {
            nameQuestion: "question86",
            labelQuestion: "Темп строевого шага составляет",
            questions: [
                {
                    id: '297',
                    answer: true,
                    label: "110- 120 шагов в минуту"
                },
                {
                    id: '298',
                    answer: false,
                    label: "120- 130 шагов в минуту"
                },
                {
                    id: '299',
                    answer: false,
                    label: "90-100 шагов в минуту"
                },
                {
                    id: '300',
                    answer: false,
                    label: "110- 130 шагов в минуту"
                }
            ]
        },
        {
            nameQuestion: "question87",
            labelQuestion: "Поворот кругом на месте осуществляется:",
            questions: [
                {
                    id: '301',
                    answer: true,
                    label: "Повороты кругом производятся в сторону левой руки на левом каблуке и на правом носке"
                },
                {
                    id: '302',
                    answer: false,
                    label: "Повороты кругом производятся против часовой стрелки на правом каблуке и на левом носке"
                },
                {
                    id: '303',
                    answer: false,
                    label: "Повороты кругом производятся в сторону правой руки на левом каблуке и на правом носке"
                },
                {
                    id: '304',
                    answer: false,
                    label: "Повороты кругом производятся в по часовой стрелки на правом каблуке и на левом носке"
                }
            ]
        },
        {
            nameQuestion: "question88",
            labelQuestion: "Положение “ВОЛЬНО”:",
            questions: [
                {
                    id: '305',
                    answer: true,
                    label: "Стать свободно, ослабить в колене правую или левую ногу, но не сходить с места, не ослаблять внимания и не разговаривать"
                },
                {
                    id: '306',
                    answer: false,
                    label: "Ослабить в колене правую или левую ногу, но не сходить с места и не разговаривать"
                },
                {
                    id: '307',
                    answer: false,
                    label: "Ослабить в колене правую или левую ногу, не ослаблять внимания и не разговаривать"
                },
                {
                    id: '308',
                    answer: false,
                    label: "Стать свободно, не ослаблять внимания и не разговаривать"
                }
            ]
        },
    ],
    test9: [
        {
            nameQuestion: "question89",
            labelQuestion: "Топографические карты это",
            questions: [
                {
                    id: '309',
                    answer: false,
                    label: "карты местности"
                },
                {
                    id: '310',
                    answer: false,
                    label: "специальные обзорно-географические карты"
                },
                {
                    id: '311',
                    answer: false,
                    label: "измерительные документы"
                },
                {
                    id: '312',
                    answer: true,
                    label: "измерительные документы и основные источники информации о местности, служащие одним из важнейших средств для управления войсками"
                }
            ]
        },
        {
            nameQuestion: "question90",
            labelQuestion: "Что не относится к способам ориентирования на местности?",
            questions: [
                {
                    id: '313',
                    answer: false,
                    label: "определение сторон света по полярной звезде"
                },
                {
                    id: '314',
                    answer: false,
                    label: "определение расстояния глазомером"
                },
                {
                    id: '315',
                    answer: true,
                    label: "по толщине снежного покрова"
                },
                {
                    id: '316',
                    answer: false,
                    label: "по карте"
                }
            ]
        },
        {
            nameQuestion: "question91",
            labelQuestion: "Задача ориентирования на местности",
            questions: [
                {
                    id: '317',
                    answer: false,
                    label: "определение своего местонахождения"
                },
                {
                    id: '318',
                    answer: true,
                    label: "определение выдержки заданного направления движения"
                },
                {
                    id: '319',
                    answer: false,
                    label: "определение сторон света"
                },
                {
                    id: '320',
                    answer: false,
                    label: "знать ближайший маршрут населенного пункта"
                }
            ]
        },
        {
            nameQuestion: "question92",
            labelQuestion: "Какой способ определения расстояний при ориентировании на местности не входит в число принятых?",
            questions: [
                {
                    id: '321',
                    answer: false,
                    label: "глазомер"
                },
                {
                    id: '322',
                    answer: false,
                    label: "по спидометру"
                },
                {
                    id: '323',
                    answer: true,
                    label: "замер линейкой"
                },
                {
                    id: '324',
                    answer: false,
                    label: "по времени движения"
                }
            ]
        },
        {
            nameQuestion: "question93",
            labelQuestion: "Что такое потерянная ориентировка?",
            questions: [
                {
                    id: '325',
                    answer: true,
                    label: "когда на местности не находят объектов, обозначенных на карте, и не могут определить на карте свое местонахождение"
                },
                {
                    id: '326',
                    answer: false,
                    label: "когда на карте не находят объектов местности"
                },
                {
                    id: '327',
                    answer: false,
                    label: "когда потеряны ориентировочные принадлежности"
                },
                {
                    id: '328',
                    answer: false,
                    label: "когда не могут определить местонахождение"
                }
            ]
        },
        {
            nameQuestion: "question94",
            labelQuestion: "Какого масштаба топографические карты относятся к крупномасштабным тактическим?",
            questions: [
                {
                    id: '329',
                    answer: false,
                    label: "1:1 000 000"
                },
                {
                    id: '330',
                    answer: false,
                    label: "1:500 000"
                },
                {
                    id: '331',
                    answer: false,
                    label: "1:100 000"
                },
                {
                    id: '332',
                    answer: true,
                    label: "1:50 000"
                }
            ]
        },
        {
            nameQuestion: "question95",
            labelQuestion: "Каким цветом отображается на топографических картах автострада",
            questions: [
                {
                    id: '333',
                    answer: false,
                    label: "желтым"
                },
                {
                    id: '334',
                    answer: false,
                    label: "черным"
                },
                {
                    id: '335',
                    answer: false,
                    label: "коричневым"
                },
                {
                    id: '336',
                    answer: true,
                    label: "оранжевым"
                }
            ]
        },
        {
            nameQuestion: "question96",
            labelQuestion: "Какие системы координат, применяющиеся в топографии, не относятся к топографическим?",
            questions: [
                {
                    id: '337',
                    answer: false,
                    label: "географические"
                },
                {
                    id: '338',
                    answer: false,
                    label: "плоские прямоугольные"
                },
                {
                    id: '339',
                    answer: true,
                    label: "биполярные"
                },
                {
                    id: '340',
                    answer: false,
                    label: "полярные"
                }
            ]
        },
        {
            nameQuestion: "question97",
            labelQuestion: "При глазомерном определении расстояния видны отдельные деревья, трубы на крышах домов. Ориентировочная дальность объектов составляет:",
            questions: [
                {
                    id: '341',
                    answer: false,
                    label: "4км"
                },
                {
                    id: '342',
                    answer: true,
                    label: "3км"
                },
                {
                    id: '343',
                    answer: false,
                    label: "2км"
                }
            ]
        },
        {
            nameQuestion: "question98",
            labelQuestion: "Когда была официально принята в эксплуатацию система ГЛОНАСС?",
            questions: [
                {
                    id: '344',
                    answer: true,
                    label: "24 сентября 1993 года"
                },
                {
                    id: '345',
                    answer: false,
                    label: "12 октября 1982 года"
                },
                {
                    id: '346',
                    answer: false,
                    label: "7 августа 1998 года"
                }
            ]
        },
        {
            nameQuestion: "question99",
            labelQuestion: "Луна в первой четверти в 01-00 находится на:",
            questions: [
                {
                    id: '347',
                    answer: false,
                    label: "Востоке"
                },
                {
                    id: '348',
                    answer: false,
                    label: "Юге"
                },
                {
                    id: '349',
                    answer: true,
                    label: "Западе"
                },
            ]
        },
        {
            nameQuestion: "question100",
            labelQuestion: "Что такое экватор?",
            questions: [
                {
                    id: '350',
                    answer: true,
                    label: "это линия, по которой плоскость, перпендикулярная к оси вращения Земли, проходящая через ее центр, пересекает земную поверхность"
                },
                {
                    id: '351',
                    answer: false,
                    label: "это плоскость, перпендикулярная к оси вращения Земли, проходящая через ее центр"
                },
                {
                    id: '352',
                    answer: false,
                    label: "это линия, по которой плоскость, перпендикулярная к оси вращения Земли, пересекает земную поверхность"
                }
            ]
        },
    ],
    test10: [
        {
            nameQuestion: "question101",
            labelQuestion: "Как проверяется пульс при бессознательном состоянии пострадавшего и при травмах?",
            questions: [
                {
                    id: '353',
                    answer: true,
                    label: "Пульс проверяется на сонной артерии"
                },
                {
                    id: '354',
                    answer: false,
                    label: "Пульс проверяется на запястье"
                },
                {
                    id: '355',
                    answer: false,
                    label: "Приложив ухо к груди прослушивается сердцебиение"
                }
            ]
        },
        {
            nameQuestion: "question102",
            labelQuestion: "При вынужденном длительном наложении кровоостанав­ли­вающий жгут необходимо:",
            questions: [
                {
                    id: '357',
                    answer: false,
                    label: "Периодически ослаблять, применяя на это время пальцевое прижатие, затем накладывать на прежнее место"
                },
                {
                    id: '358',
                    answer: true,
                    label: "Периодически ослаблять, применяя на это время пальцевое прижатие, затем переносить выше прежнего места наложения"
                },
                {
                    id: '359',
                    answer: false,
                    label: "Периодически ослаблять, и затем переносить ниже прежнего места наложения"
                }
            ]
        },
        {
            nameQuestion: "question103",
            labelQuestion: "Что необходимо сделать при ожоговой ране?",
            questions: [
                {
                    id: '359',
                    answer: true,
                    label: "Наложить чистую увлажненную повязку"
                },
                {
                    id: '360',
                    answer: false,
                    label: "Очистить рану и промыть ее холодной водой"
                },
                {
                    id: '361',
                    answer: false,
                    label: "Смазать рану маслом, наложить повязку"
                }
            ]
        },
        {
            nameQuestion: "question104",
            labelQuestion: "Техника наложения кровоостанавливающего жгута предусматривает:",
            questions: [
                {
                    id: '362',
                    answer: false,
                    label: "Наложение жгута под одежду выше места кровотечения"
                },
                {
                    id: '363',
                    answer: false,
                    label: "Наложение жгута на одежду ниже места кровотечения (с указанием времени наложения в записке)."
                },
                {
                    id: '364',
                    answer: false,
                    label: "Наложение жгута на одежду выше места кровотечения (с указанием времени наложения в записке)."
                },
            ]
        },
        {
            nameQuestion: "question105",
            labelQuestion: "Способы временной остановки кровотечения:",
            questions: [
                {
                    id: '365',
                    answer: false,
                    label: "Частичное сгибание конечности, наложение пластыря, наложение давящей повязки. Придание возвышенного положения конечности, наложение асептической повязки"
                },
                {
                    id: '366',
                    answer: true,
                    label: "Пальцевое прижатие, максимальное сгибание конечности, наложение жгута (закрутки), наложение давящей повязки"
                }
            ]
        },
        {
            nameQuestion: "question106",
            labelQuestion: "Что надо сделать для определения наличия дыхания при бессознательном состоянии пострадавшего?",
            questions: [
                {
                    id: '367',
                    answer: false,
                    label: "Поднести зеркальце или птичье перо к носу пострадавшего"
                },
                {
                    id: '368',
                    answer: true,
                    label: "Поднести к носу пострадавшего внутреннюю сторону своего запястья или щеку"
                },
                {
                    id: '369',
                    answer: false,
                    label: "Приложить ухо к груди пострадавшего и прослушать дыхание"
                },
            ]
        },
        {
            nameQuestion: "question107",
            labelQuestion: "Правильная транспортировка пострадавшего, находящегося без сознания (за исключением случаев, когда в связи с подозрением на травму позвоночника менять положение тела не рекомендуется) производится:",
            questions: [
                {
                    id: '370',
                    answer: true,
                    label: "В положении на боку"
                },
                {
                    id: '371',
                    answer: false,
                    label: "В положении на спине"
                },
                {
                    id: '372',
                    answer: false,
                    label: "В положении с приподнятыми нижними конечностями."
                },
            ]
        },
        {
            nameQuestion: "question108",
            labelQuestion: "Если пострадавший находится без сознания, в какое положение до прибытия скорой помощи он должен быть переведен",
            questions: [
                {
                    id: '373',
                    answer: false,
                    label: "В положении на спине"
                },
                {
                    id: '374',
                    answer: false,
                    label: "В положении полусидя"
                },
                {
                    id: '375',
                    answer: true,
                    label: "В устойчивое боковое положение"
                }
            ]
        },
        {
            nameQuestion: "question109",
            labelQuestion: "При повреждении костей плеча или бедра шину накладывают:",
            questions: [
                {
                    id: '376',
                    answer: true,
                    label: "С захватом трех суставов (двух ниже и одного выше места перелома)."
                },
                {
                    id: '377',
                    answer: false,
                    label: "С захватом только верхнего (по отношению к месту перелома) сустава."
                },
                {
                    id: '378',
                    answer: false,
                    label: "С захватом только двух суставов (выше и ниже места перелома)."
                }
            ]
        },
        {
            nameQuestion: "question110",
            labelQuestion: "Порядок оказания первой помощи при открытых переломах:",
            questions: [
                {
                    id: '379',
                    answer: false,
                    label: "Наложить шину и обезболить (по возможности)."
                },
                {
                    id: '380',
                    answer: true,
                    label: "Обезболить (по возможности), наложить повязку, наложить шину."
                },
                {
                    id: '381',
                    answer: false,
                    label: "Наложить шину, наложить повязку на рану."
                },
            ]
        },
        {
            nameQuestion: "question111",
            labelQuestion: "Какие правила оказания первой помощи соблюдаются при проникающем ранении в брюшную полость?",
            questions: [
                {
                    id: '382',
                    answer: false,
                    label: "Приподнять голову, дать сладкое теплое питье, накрыть стерильной салфеткой и положить холод на рану."
                },
                {
                    id: '383',
                    answer: true,
                    label: "Не давать пострадавшему пить жидкость, не извлекать инородное тело, прикрыть рану стерильным перевязочным материалом."
                },
                {
                    id: '384',
                    answer: false,
                    label: "Не давать пострадавшему пить жидкость, извлечь инородное тело, накрыть рану стерильной салфеткой."
                },
            ]
        },
        {
            nameQuestion: "question112",
            labelQuestion: "Какова правильная последовательность действий при остановке артериального кровотечения?",
            questions: [
                {
                    id: '385',
                    answer: true,
                    label: "Проводится пальцевая остановка кровотечения, накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута"
                },
                {
                    id: '386',
                    answer: false,
                    label: "Проводится пальцевая остановка кровотечения, накладывается чистая повяз­ка, накладывается жгут (скрутка, ремень), указывается время наложения жгута"
                },
                {
                    id: '387',
                    answer: false,
                    label: "Накладывается жгут (скрутка, ремень), накладывается чистая повязка, указывается время наложения жгута."
                },
            ]
        },
        {
            nameQuestion: "question113",
            labelQuestion: "Промывание желудка при отравлении в порядке первой помощи (немедицинским персоналом и без желудочного зонда) запрещено:",
            questions: [
                {
                    id: '388',
                    answer: true,
                    label: "При отравлениях у несовершеннолетних детей"
                },
                {
                    id: '389',
                    answer: false,
                    label: "При отравлениях у лиц, не имеющих при себе документов, удостоверяющих личность"
                },
                {
                    id: '390',
                    answer: false,
                    label: "При отравлениях кислотами, щелочами, нефтепродуктами, при судорогах, в случае потери сознания пострадавшим"
                }
            ]
        },
        {
            nameQuestion: "question114",
            labelQuestion: "При укусах змей запрещается:",
            questions: [
                {
                    id: '391',
                    answer: true,
                    label: "Прижигать место укуса"
                },
                {
                    id: '392',
                    answer: false,
                    label: "Прикладывать холод"
                },
                {
                    id: '393',
                    answer: false,
                    label: "Обеспечить пострадавшему покой"
                },
            ]
        },
        {
            nameQuestion: "question115",
            labelQuestion: "В течении какого времени симптомы укуса змей достигают максимума?",
            questions: [
                {
                    id: '394',
                    answer: true,
                    label: "8 – 36 часов"
                },
                {
                    id: '395',
                    answer: false,
                    label: "7 – 10 часов"
                },
                {
                    id: '396',
                    answer: false,
                    label: "10 – 15 часов"
                }
            ]
        },
    ]
}