export const dataTests = [
    {
        id: 0,
        question: ' Прочтите отрывок из сочинения историка Н.М.Карамзина и определите, о ком идет речь в приведенном отрывке: «Сей святой старец... предсказал Димитрию кровопролитие ужасное, но победу... окропил святою водою всех бывших с ним военачальников и дал ему двух иноков в сподвижники, именем Александра Пересвета и Ослябю...».',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'Феофане Греке',
                answer: false,
            },
            {
                id: 2,
                text: 'Данииле Черном',
                answer: false,
            },
            {
                id: 3,
                text: 'Иосифе Волоцком',
                answer: false,
            },
            {
                id: 4,
                text: 'Сергии Радонежском',
                answer: true,
            }
        ]
    },
    {
        id: 1,
        question: 'Какое войско в середине XVI в. стало первым постоянным войском в России?',
        answer: 3,
        options: [
            {
                id: 1,
                text: 'Посошная рать',
                answer: false,
            },
            {
                id: 2,
                text: 'Поместная дворянская конница',
                answer: false,
            },
            {
                id: 3,
                text: 'Стрелецкое войско',
                answer: true,
            },
            {
                id: 4,
                text: 'Полки «иноземного строя»',
                answer: false,
            }
        ]
    },
    {
        id: 2,
        question: 'Кто был первым русским царем династии Романовых?',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'Иван IV Васильевич Грозный (1530–1584).',
                answer: false,
            },
            {
                id: 2,
                text: 'Михаил Федорович (1596–1645).',
                answer: true,
            },
            {
                id: 3,
                text: 'Алексей Михайлович (1629–1676).',
                answer: false,
            },
            {
                id: 4,
                text: 'Федор Алексеевич (1661–1682).',
                answer: false,
            }
        ]
    },
    {
        id: 3,
        question: 'С каким событием XVII в. связан государственный праздник Российской Федерации – День народного единства 4 ноября?',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'С принятием в 1649 г. свода законодательства – Соборного уложения.',
                answer: false,
            },
            {
                id: 2,
                text: 'С изгнанием в 1612 г. ополчением К. Минина и Д. Пожарского польских интервентов из Москвы.',
                answer: true,
            },
            {
                id: 3,
                text: 'С принятием в 1654 г. Земским собором решения удовлетворить просьбу украинской Переяславской рады о воссоединении Левобережной Украины с Россией',
                answer: false,
            },
            {
                id: 4,
                text: 'С окончанием в 1671 г. крестьянского восстания под предводительством С. Разина.',
                answer: false,
            }
        ]
    },
    {
        id: 4,
        question: 'Какое сражение с участием русских войск получило название «Битва народов»?',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'Аустерлицкое сражение 1805 г.',
                answer: false,
            },
            {
                id: 2,
                text: 'Сражение под Прейсиш-Эйлау 1807 г.',
                answer: false,
            },
            {
                id: 3,
                text: 'Бородинское сражение 1812 г.',
                answer: false,
            },
            {
                id: 4,
                text: 'Лейпцигское сражение 1813 г.',
                answer: true,
            }
        ]
    },
    {
        id: 5,
        question: 'В 1769 г. Екатерина II учредила орден Святого Георгия. Кто и за какие заслуги его удостаивался в соответствии со статутом ордена?',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'Только генералы за отличие в военной службе.',
                answer: false,
            },
            {
                id: 2,
                text: 'Генералы и офицеры за боевые и другие военные заслуги.',
                answer: true,
            },
            {
                id: 3,
                text: 'Любой гражданин России за заслуги перед государством.',
                answer: false,
            },
            {
                id: 4,
                text: 'Только дворяне-чиновники за отличие в государственной службе.',
                answer: false,
            }
        ]
    },
    {
        id: 6,
        question: 'Кто из перечисленных ниже советских военачальников относится к числу полководцев Великой Отечественной войны:',
        answer: 4,
        options: [
            {
                id: 1,
                text: 'Жуков Г.К.',
                answer: false,
            },
            {
                id: 2,
                text: 'Блюхер В.К.',
                answer: true,
            },
            {
                id: 3,
                text: 'Фрунзе М.В.',
                answer: false,
            },
            {
                id: 4,
                text: 'Рокоссовский К.К.',
                answer: true,
            }
        ]
    },
    {
        id: 7,
        question: 'США вступили во Вторую мировую войну:',
        answer: 2,
        options: [
            {
                id: 1,
                text: 'В сентябре 1939 г. после нападения Германии на Польшу.',
                answer: false,
            },
            {
                id: 2,
                text: 'В декабре 1941 г. после японской атаки на Перл-Харбор.',
                answer: true,
            },
            {
                id: 3,
                text: 'В январе 1942 г. после разгрома немецко-фашистских войск под Москвой.',
                answer: false,
            },
            {
                id: 4,
                text: 'Летом 1943 г. после Курской битвы.',
                answer: false,
            }
        ]
    }
]