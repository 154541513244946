import React, {useState, useEffect} from 'react';
import {Lesson} from "./lesson";
import CoursesList from "./courses";
import Tasks from "./tasks";
import SelfPreparation from "./self-preparation";

import {lesson} from "./lesson/api/data";
import {workHome} from "./lesson/api/workHomeData";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({

}))
// getUserCourse
export default function Dashboard({data}) {
    const classes = useStyles();

    const activeLessonNumber = data !== undefined && data.length > 0 ? data[0]?.lessons.activeLessonNumber : 1;
    return (
        <>
            <CoursesList data={data} />
            {data && data.length === 0 ? null :
                <>
                    <Tasks dataTask={workHome[activeLessonNumber - 1]} type="homeTask" />
                    <Lesson lesson={lesson[activeLessonNumber - 1]} />
                </>
            }

        </>
    )
}