import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Link,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";
import {ReactComponent as DoneIcon} from '../../static/svg/done.svg';
import {ReactComponent as LockIcon} from '../../static/svg/lock.svg';
import clsx from 'clsx';
import CardFolder from '../../components/common/cardFolder';
import ItemDescription from './item_description3';

import {dataCourse} from './dataCourse';
import './style.css';

const useStyles = makeStyles((theme) => ({
    listItem__topic: {
        width: "100%",
        fontSize: "1rem",
        fontFamily: "Open Sans",
        fontWeight: "900",
        borderBottom: "1px solid black",
        margin: "20px 0px 10px 0px",
    },
    main: {
        height: '100%',
        //overflow: 'hidden',
        width: '100%',

    },
    main__content: {
        padding: '30px',
    },
    block__header: {
        display: 'grid',
        gridTemplateColumns: 'auto 0fr',
        gridGap: '20px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr auto',
        },
    },
    block__number: {
        fontFamily: 'Open Sans, Russo One',
        fontSize: '1.1rem',
        borderBottom: '1px solid #c4c4c4',
        padding: '0px 0px 5px 0px',
    },
    block__progress: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '0.7rem'
    },
    block__content: {
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 250px) auto',
        gridGap: '20px',
        margin: '30px 0px',
        background: 'white',
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr auto',
        },
    },
    progress__value: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '3rem'
    },
    block__content__title: {
        fontFamily: 'Play, Russo One, Open Sans',
        fontSize: '1.1rem',
        fontWeight: '100',
        margin: '5px 0px',
    },
    section__header: {
        //display: 'grid',
        //gridTemplateColumns: 'auto auto',
        //justifyContent: 'space-between',
        //display: 'grid',
        //gridTemplateColumns: '1fr auto',
    },
    header__text: {
        display: 'flex',
        borderBottom: '1px solid black',
        justifyContent: 'space-between',
    },
    header__number: {
        fontSize: '0.9rem',
        fontFamily: 'Russo One, Open Sans',
        borderBottom: '1px solid #000000',
    },
    header__title: {
        fontFamily: 'Play, Open Sans',
        fontSize: '1rem',
        fontWeight: '100',
        margin: '10px 0px 40px 0px',
    },
    header__progress: {
        fontSize: '0.7rem',
        fontFamily: 'Yeseva One, Open Sans',
        //borderBottom: '1px solid black',
    },
    header__value: {
        fontFamily: 'Russo One, Open Sans',
        fontSize: '3rem'
    },
    main__list: {
        //margin: '10px 0px 40px 20px',
    },
    listItem: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '5px 10px',
        gridGap: '5px',
        '&:hover': {
            background: '#e6e6e6'
        },
        '&:hover svg': {
            opacity: '1',
        }
    },
    item__icon: {
        '& svg': {
            //color: '#99a63c',
            opacity: '0.5',
            fill: 'currentColor',
        }
    },

    block__course: {
        margin: '10px 0px',

    },
    course__name: {
        fontFamily: 'Open Sans',
    },
    course__title: {
        fontSize: '1.5rem',
        fontFamily: 'Play, Open Sans',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4rem',
        },
    },
    block__course__lesson: {
        //margin: '35px 0px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRow: '1fr 1fr',
            gridGap: '0px',
        },
    },
    info__lesson: {
        padding: '10px',
        margin: '10px 0px',
        //background: 'white',
        border: '1px solid #c4c4c4'
    },
    data__lesson: {
        padding: '10px 0px'
    },
    title__wrapper: {
        fontSize: '1.8rem',
        margin: '20px 0px 20px 0px',
        fontFamily: 'Yeseva One, Open Sans',
    },
}));


const Accordion = withStyles({
    root: {
        //   border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        background: "none",
        width: "100%",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0',
        },
        '&$Mui-disabled': {

        }
    },
    disabled: {
        backgroundColor: "none",
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'red',
        //   borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        padding: "0",
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {

        padding: "0px",
    },
}))(MuiAccordionDetails);

const CardItem = ({enterClass, outputClass, activeBlock}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(activeBlock && 'block-0');
    const scroll = useRef(null);

    //const handleChange =
    //    (panel) => (event, newExpanded) => {
    //        console.log(panel, newExpanded)
    //    };
    //useEffect(() => {
    //    console.log(scroll)
    //    debugger
    //    if (scroll) {
    //        console.log('scroll')

    //        scroll.current.scrollIntoView();
    //    }
    //}, [])
    return (
        <div className={clsx(classes.main, outputClass, enterClass)}>
            <CardFolder>
                <div className={classes.block__course}>
                    <div className={classes.course__name}>Курс повышения квалификации:</div>
                    <div className={classes.course__title}>Основы безопасности военной службы</div>
                </div>
                <div className={classes.block__course__lesson}>
                    <div className={classes.info__lesson}>
                        <div className={classes.course__name}>Статус занятия:</div>
                        <div className={classes.course__title}>Не началось</div>
                    </div>
                    <div className={classes.info__lesson}>
                        <div className={classes.course__name}>Дата занятия:</div>
                        <div className={classes.course__title}>02.06.2022</div>
                    </div>
                </div>
            </CardFolder>
            <div className={classes.title__wrapper}>Прогресс</div>
            {
                dataCourse.map((el) => {
                    const {id: idBlock, block, progress, sections} = el;
                    const panel = `block-${idBlock}`;
                    return (
                        <Accordion key={`block-${idBlock}`} defaultExpanded={activeBlock === panel}>
                            <AccordionSummary
                                expandIcon={el.sections.length !== 0 ? <ExpandMoreIcon /> : undefined}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.block__header}>
                                    <div className={classes.block__number__progress}>
                                        <p className={classes.block__number}>Блок {idBlock + 1}</p>
                                        <h2 className={classes.block__content__title}>
                                            {block.title}
                                        </h2>

                                    </div>
                                    <div>
                                        <p className={classes.block__progress}>Выполнено:</p>
                                        <p className={classes.progress__value}>{progress}%</p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {
                                        el.sections.map((item) => {
                                            const {id: idSection, title: titleSection, lessons} = item;
                                            //const asd = panel === 'block-0' ? scroll : null;
                                            //debugger
                                            return (
                                                <div key={`section-${idSection}`} className={classes.block__content}>
                                                    <div className={classes.section__header}>
                                                        <div className={classes.header__number}>Раздел {idSection + 1}</div>
                                                        <h3 className={classes.header__title}>{titleSection}</h3>
                                                        {/*<div className={classes.header__progress}>Выполнено:</div>
                                                        <div className={classes.header__value}>3/4</div>*/}
                                                    </div>

                                                    <div className={classes.section__main}>
                                                        <div className={classes.header__number}>Темы:</div>
                                                        <ol className={classes.main__list}>
                                                            {
                                                                lessons.map((lesson) => {
                                                                    const {title: titleLesson, description, id: idLesson} = lesson;
                                                                    return (
                                                                        <Link key={`lesson-${idLesson}`} to={`/dashboard/block-${idBlock}/section-${idSection}/lesson-${idLesson}`}>
                                                                            <li className={classes.listItem}><div>{titleLesson}</div>
                                                                                <span className={classes.item__icon}><DoneIcon /></span>
                                                                            </li>
                                                                        </Link>
                                                                    )
                                                                })
                                                            }
                                                        </ol>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </div>
    )
}

export default CardItem;
